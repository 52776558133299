<!--
xs (0, 480> example_480.jpg
sm (480, 768> example_768.jpg
md (768, 992> example_992.jpg
lg (992, 1200> example_1200.jpg
xl (1200, ...) example.jpg
-->

<template>
    <div :style="imgStyle">
        <div
            v-if="srcSet.length <= 0 && newSrcSet.length <= 0"
            :style="imgStyle"
        >
            <a
                v-if="linkToMaxImage"
                :href="newWindowLink"
                target="_blank"
                rel="noopener noreferrer"
            >
                <picture>
                    <template v-for="size in sizes">
                        <template v-for="format in formats">
                            <source
                                :key="`${baseUrl}/${code}_${size}.${format}`"
                                :media="`(max-width: ${size}px)`"
                                :srcset="`${baseUrl}/${code}_${size}.${format}`"
                                :type="`image/${format}`"
                            />
                        </template>
                    </template>
                    <template v-for="format in formats">
                        <source
                            v-if="sizes.length > 0"
                            :key="`${baseUrl}/${code}.${format}`"
                            :media="
                                `(min-width: ${Number(sizes[sizes.length - 1]) +
                                    1}px)`
                            "
                            :srcset="`${baseUrl}/${code}.${format}`"
                            :type="`image/${format}`"
                        />
                        <source
                            v-else
                            :key="`${baseUrl}/${code}.${format}`"
                            :srcset="`${baseUrl}/${code}.${format}`"
                            :type="`image/${format}`"
                        />
                    </template>
                    <img
                        :src="`${baseUrl}/${code}.${safetyFormat}`"
                        :alt="alt"
                        :title="title"
                        :class="type"
                        draggable="false"
                        loading="lazy"
                        @load="loaded"
                        @error="failed"
                    />
                    <div v-if="overlay" class="overlay" />
                    <div v-if="description" class="basic-image-description">
                        {{ description }}
                    </div>
                </picture>
            </a>
            <picture v-if="!linkToMaxImage">
                <template v-for="size in sizes">
                    <template v-for="format in formats">
                        <source
                            :key="`${baseUrl}/${code}_${size}.${format}`"
                            :media="`(max-width: ${size}px)`"
                            :srcset="`${baseUrl}/${code}_${size}.${format}`"
                            :type="`image/${format}`"
                        />
                    </template>
                </template>
                <template v-for="format in formats">
                    <source
                        v-if="sizes.length > 0"
                        :key="`${baseUrl}/${code}.${format}`"
                        :media="
                            `(min-width: ${Number(sizes[sizes.length - 1]) +
                                1}px)`
                        "
                        :srcset="`${baseUrl}/${code}.${format}`"
                        :type="`image/${format}`"
                    />
                    <source
                        v-if="sizes.length <= 0"
                        :key="`${baseUrl}/${code}.${format}`"
                        :srcset="`${baseUrl}/${code}.${format}`"
                        :type="`image/${format}`"
                    />
                </template>
                <img
                    :src="`${baseUrl}/${code}.${safetyFormat}`"
                    :alt="alt"
                    :title="title"
                    :class="type"
                    draggable="false"
                    @load="loaded"
                    @error="failed"
                />
                <div v-if="overlay" class="overlay" />
                <div v-if="description" class="basic-image-description">
                    {{ description }}
                </div>
            </picture>
        </div>

        <picture v-if="newSrcSet.length > 0">
            <template v-for="(img, index) in reverseItems">
                <source
                    v-if="img.size && img.format === 'webp'"
                    :key="`${index}${img.size}`"
                    :media="`(max-width: ${img.size}px)`"
                    :srcset="`${img.url}`"
                    format="image/webp"
                />
                <source
                    v-if="img.size && img.format !== 'webp'"
                    :key="`${index}${img.size}`"
                    :media="`(max-width: ${img.size}px)`"
                    :srcset="`${img.url}`"
                    :format="`image/${img.format}`"
                />
            </template>

            <img
                class="static-bg"
                :src="newSrcSet[0].url"
                alt="img"
                draggable="false"
                :class="type"
                @load="loaded"
                @error="failed"
            />
        </picture>

        <picture v-if="srcSet.length > 0">
            <template v-for="(img, index) in srcSet">
                <source
                    v-if="img.size !== undefined"
                    :key="`${index}/${img.baseUrl}`"
                    :media="`(max-width: ${img.size}px)`"
                    :srcset="`${img.url}`"
                    :type="`image/${img.format}`"
                />
                <source
                    v-if="srcSetSizes.length > 0 && img.size === undefined"
                    :key="`${index}/${img.baseUrl}`"
                    :media="
                        `(min-width: ${Number(
                            srcSetSizes[srcSetSizes.length - 1]
                        ) + 1}px)`
                    "
                    :srcset="`${img.url}`"
                    :type="`image/${img.format}`"
                />
                <source
                    v-if="srcSetSizes.length <= 0 && srcSet.size === undefined"
                    :key="`${img.format}/${img.url}`"
                    :srcset="`${img.url}`"
                    :type="`image/${img.format}`"
                />
            </template>

            <img
                id="myImage"
                :key="`${defaultImg.format}/${defaultImg.url}`"
                :src="`${defaultImg.url}`"
                :alt="alt"
                draggable="false"
                :loading="load"
                @load="loaded"
                @error="failed"
            />
        </picture>
    </div>
</template>

<script>
export default {
    name: 'ImageOptimized',
    props: {
        newSrcSet: {
            type: Array,
            default: () => [],
        },
        srcSet: {
            type: Array,
            default: () => [],
        },
        code: {
            type: String,
        },
        imgStyle: {
            type: String,
        },
        thumbFormats: {
            type: String,
            default: 'webp,jpg',
        },
        thumbSizes: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        alt: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
        linkToMaxImage: {
            type: Boolean,
            default: false,
        },
        overlay: {
            type: Boolean,
            default: false,
        },
        srcSetSizes: {
            type: Array,
            default: () => [],
        },
        defaultImg: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        reverseItems() {
            return this.newSrcSet.slice().reverse();
        },
        baseUrl() {
            if (this.url !== null) {
                return this.url.replace(/\\$/, '');
            } else {
                return '/static/images';
            }
        },

        formats() {
            return this.thumbFormats.split(',');
        },
        sizes() {
            if (this.thumbSizes) {
                return this.thumbSizes
                    .split(',')
                    .map(size => {
                        return parseInt(size);
                    })
                    .sort((a, b) => {
                        return a - b;
                    });
            } else {
                return [];
            }
        },
        newWindowLink() {
            let format = null;
            if (this.thumbFormats.includes('jpg')) {
                format = 'jpg';
            } else if (this.thumbFormats.includes('png')) {
                format = 'png';
            } else {
                format = this.formats[this.formats.length - 1];
            }
            return `${this.baseUrl}/${this.code}.${format}`;
        },
        safetyFormat() {
            if (this.formats.includes('jpg')) {
                return 'jpg';
            } else if (this.formats.includes('jpeg')) {
                return 'jpeg';
            } else if (this.formats.includes('JPG')) {
                return 'JPG';
            } else if (this.formats.includes('JPEG')) {
                return 'JPEG';
            } else {
                return this.formats[this.formats.length - 1];
            }
        },
    },
    methods: {
        loaded() {
            this.$emit('loaded');
        },
        failed() {
            this.$emit('failed');
        },
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

picture {
    max-width: 80vw;
    width: fit-content;
    height: fit-content;

    img {
        max-width: 100%;
        width: 100%;

        &.static-bg {
            min-width: 100vw;
            min-height: 100vh;
            width: auto;
            height: auto;
            max-width: none;
        }

        &.homepage-layer {
            position: absolute;
            max-width: none;

            &.bg {
                z-index: 1;
                height: auto;
                width: auto;
                min-height: 100vh;
                min-width: 100vw;
                bottom: 0;
                left: 0;
            }
        }

        &.homepage {
            display: block;
            max-width: 1920px;
            width: 100%;
            height: auto;
        }

        &.anniversary {
            position: relative;
            min-height: 100%;
            min-width: 100%;
            object-fit: cover;
        }

        &.aboutpage {
            display: block;
            max-width: 1920px;
            width: 100%;
            height: auto;
        }

        &.popup-founder {
            display: block;
            width: calc(100% - 100px);
            margin: 20px 50px;
        }

        &.hidden {
            display: none;
        }

        &.nintendo-header {
            width: auto;
            max-width: none;
            filter: brightness(0.9);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0%);
            @media (min-width: 769px) {
                display: none;
            }
            @media (max-width: $screen-sm) {
                height: 1002px;
            }
            @media (max-width: $screen-xs) {
                height: 690px;
            }
        }

        &.screenshot-image,
        .feature-image {
            width: calc(39.2vw - 28px);

            @media (max-width: 1300px) {
                width: 77vw;
            }

            @media (min-width: 1920px) {
                width: 723px;
            }
        }

        &.full-width {
            max-width: 80vw;

            @media (max-width: 1000px) {
                max-width: 80vw;
            }

            @media (min-width: 1920px) {
                width: 684px;
            }
        }

        &.partnership-type {
            width: 100vw;
            max-width: 200vw;
            height: auto;

            &.left,
            &.right {
                width: 200vw;
            }

            &.left {
                margin-left: -10vw;
            }

            &.right {
                margin-left: -100vw;
            }

            @media (min-width: 1920px) {
                width: 1920px;
            }
        }

        &.partnership-form {
            height: 100%;
            max-width: 100vw;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 5px);
        width: 100%;
        max-width: 80vw;
        z-index: 3;
        background: linear-gradient(transparent 0%, black 180%);
    }

    @media (min-width: 1920px) {
        max-width: 1920px;
    }
}
</style>
