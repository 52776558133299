'use strict';
import { ConfigResource } from '@/services/config';
import Vue from 'vue';
import * as types from '../mutation-types';

const state = {
    data: null,
    webpSupport: false,
    apple: false,
    edge: false,
};

const getters = {
    clientId: state => {
        return state.data.clientId;
    },
    webpSupport: state => {
        return state.webpSupport;
    },
    apple: state => {
        return state.apple;
    },
    edge: state => {
        return state.edge;
    },
    settings: state => {
        let result = state.data;
        if (state.data) {
            result.webpSupport = state.webpSupport;
            result.apple = state.apple;
            result.edge = state.edge;
        }
        return result;
    },
};

const actions = {
    loadSettings({ commit }) {
        return ConfigResource.get()
            .then(response => {
                commit(types.RECEIVE_SETTINGS, response.data);
            })
            .catch(err => {
                commit(types.RECEIVE_SETTINGS, {});
                console.log(err);
            });
    },
};

const mutations = {
    [types.RECEIVE_SETTINGS](state, val) {
        Vue.set(state, 'data', val);
    },
    webpSupport(state, val) {
        state.webpSupport = val;
    },
    apple(state, val) {
        state.apple = val;
    },
    edge(state, val) {
        state.edge = val;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
