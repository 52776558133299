//Misc
export const LOADING = 'LOADING';

//User
export const RECEIVE_USER = 'RECEIVE_USER';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';

//Settings
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS';

//Homepage
export const RECEIVE_HOMEPAGE = 'RECEIVE_HOMEPAGE';

//Updates
export const RECEIVE_UPDATES = 'RECEIVE_UPDATES';

//Articles
export const RECEIVE_ARTICLES = 'RECEIVE_ARTICLES';

//Faqs
export const RECEIVE_FAQS = 'RECEIVE_FAQS';

//Presskits
export const RECEIVE_PRESSKITS = 'RECEIVE_PRESSKITS';

//Homepage items
export const RECEIVE_HOMEITEMS = 'RECEIVE_HOMEITEMS';

//Partners
export const RECEIVE_PARTNERS = 'RECEIVE_PARTNERS';

//Changelogs
export const RECEIVE_CHANGELOGS = 'RECEIVE_CHANGELOGS';

//Partnership
export const RECEIVE_PARTNERSHIP = 'RECEIVE_PARTNERSHIP';
