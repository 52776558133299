<template>
    <span v-if="outside || event">
        <a
            v-if="outside"
            target="_blank"
            rel="noopener noreferrer"
            :href="link"
            class="button-wrapper no-underline"
            :class="wrapperType"
        >
            <span class="button" :class="type">
                <span class="button-text" :style="textStyle">
                    <span
                        v-if="icon"
                        class="icon"
                        :class="icon"
                        draggable="false"
                    />
                    {{ text }}
                </span>
            </span>
            <span v-if="note" class="note">{{ note }}</span>
        </a>
        <a
            v-else
            class="button-wrapper no-underline"
            :class="wrapperType"
            @click="clickMethod"
        >
            <span class="button" :class="type">
                <span class="button-text" :style="textStyle">
                    <span
                        v-if="icon"
                        class="icon"
                        :class="icon"
                        draggable="false"
                    />
                    {{ text }}
                </span>
            </span>
            <span v-if="note" class="note">{{ note }}</span>
        </a>
    </span>
    <span v-else-if="download">
        <a
            :href="link"
            target="_blank"
            rel="noopener noreferrer"
            download
            class="button-wrapper no-underline"
            :class="wrapperType"
        >
            <span class="button" :class="type">
                <span class="button-text" :style="textStyle">
                    <span
                        v-if="icon"
                        class="icon"
                        :class="icon"
                        draggable="false"
                    />
                    {{ text }}
                </span>
            </span>
            <span v-if="note" class="note">{{ note }}</span>
        </a>
    </span>
    <span v-else-if="form">
        <span class="button-wrapper no-underline" :class="wrapperType">
            <button
                v-if="disabled"
                :id="id"
                type="submit"
                class="button disabled"
                :class="type"
                disabled
            >
                <span class="button-text" :style="textStyle">
                    <span
                        v-if="icon"
                        class="icon"
                        :class="icon"
                        draggable="false"
                    />
                    {{ text }}
                </span>
            </button>
            <button
                v-else
                :id="id"
                type="submit"
                class="button"
                :class="type"
                @click="clickMethod"
            >
                <span class="button-text" :style="textStyle">
                    <span
                        v-if="icon"
                        class="icon"
                        :class="icon"
                        draggable="false"
                    />
                    {{ text }}
                </span>
            </button>
            <span v-if="note" class="note">{{ note }}</span>
        </span>
    </span>
    <span v-else class="button-wrapper" :class="wrapperType">
        <router-link
            v-if="params"
            exact
            :to="{ name: link, params: params }"
            class="no-underline"
        >
            <span class="button" :class="type">
                <span class="button-text" :style="textStyle">
                    <span
                        v-if="icon"
                        class="icon"
                        :class="icon"
                        draggable="false"
                    />
                    {{ text }}
                </span>
            </span>
        </router-link>
        <router-link v-else exact :to="{ name: link }" class="no-underline">
            <span class="button" :class="type">
                <span class="button-text" :style="textStyle">
                    <span
                        v-if="icon"
                        class="icon"
                        :class="icon"
                        draggable="false"
                    />
                    {{ text }}
                </span>
            </span>
        </router-link>
        <span v-if="note" class="note">{{ note }}</span>
    </span>
</template>

<script>
export default {
    name: 'LinkButton',
    components: {},
    props: {
        text: {
            type: String,
        },
        link: {
            type: String,
        },
        type: {
            type: String,
            default: 'red',
        },
        wrapperType: {
            type: String,
            default: null,
        },
        outside: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Boolean,
            default: false,
        },
        params: {
            type: Object,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        download: {
            type: Boolean,
            default: false,
        },
        form: {
            type: Boolean,
            default: false,
        },
        note: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        padding: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        textStyle() {
            return this.window.w >= 300
                ? { padding: '0 ' + this.padding + 'px' }
                : {};
        },
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        clickMethod() {
            this.$emit('eventMethod');
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
        },
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

.button-wrapper {
    padding: 2px;
    display: inline-block;
    cursor: pointer;
    max-width: 100vw;
    position: relative;

    .button {
        padding: 15px 26px;
        margin: 0 13px;
        // display: inline-block;
        vertical-align: middle;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.15px;
        max-width: calc(80vw - 26px);
        cursor: pointer;
        display: flex;
        justify-content: center;

        &.community-links {
            color: #ffffff;
            font-family: 'PT Sans';
            font-size: 36px;
            font-weight: 700;
            width: 430px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (min-width: 914px) and (max-width: 1144px) {
                width: 320px;
                height: 90px;
            }

            @media (max-width: 913px) {
                height: 80px;
                width: 426px;
            }
        }

        .button-text {
            display: inline-block;
            border: none;
            font-size: 20px;

            @media (max-width: 480px) {
                font-size: 20px;
            }
        }

        .icon {
            height: 19px;
            width: 19px;
            display: block;
            float: left;
            border: none;
            margin-right: 10px;

            &.newsletter {
                background-image: url('/static/images/home/newsletter.svg');
            }

            &.play-icon {
                mask: url('/static/images/home/play_icon.svg');
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: 15px;

                &.light {
                    background-color: $light;
                }
                &.dark {
                    background-color: black;
                }
            }

            &.community-icon {
                height: 50px;
                width: 50px;
                margin: auto 10px auto 0;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.inst-w {
                background-image: url('/static/images/social/inst-w.svg');
            }

            &.fb-w {
                background-image: url('/static/images/social/fb-w.svg');
            }

            &.tw-w {
                background-image: url('/static/images/social/tw-w.svg');
            }

            &.forums {
                background-image: url('/static/images/social/forums.svg');
                height: 50px;
                width: 70px;
            }

            &.tiktok-w {
                background-image: url('/static/images/social/tiktok-w.svg');
            }

            &.yt-w {
                background-image: url('/static/images/social/yt-w.svg');
            }

            &.twitch-w {
                background-image: url('/static/images/social/twitch-w.svg');
            }

            &.mixer-w {
                background-image: url('/static/images/social/mixer-w.svg');
            }

            &.reddit-w {
                background-image: url('/static/images/social/reddit-w.svg');
            }

            &.discord-w {
                background-image: url('/static/images/social/discord-w.svg');
            }

            &.change-icon {
                mask: url('/static/images/updates/change.png') no-repeat 50% 50%;
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: 15px;

                &.light {
                    background-color: $light;
                }
                &.dark {
                    background-color: black;
                }
            }
        }

        &:hover {
            .icon {
                &.newsletter {
                    background-image: url('/static/images/home/newsletter_white.svg');
                }
            }
        }

        &.light {
            .icon {
                &.newsletter {
                    background-image: url('/static/images/home/newsletter_white.svg');
                }
            }

            &:hover {
                .icon {
                    &.newsletter {
                        background-image: url('/static/images/home/newsletter.svg');
                    }
                }
            }
        }

        &.dark {
            border: 1px solid $dark;
            background-color: transparent;
            color: $dark;

            &:hover {
                color: $light;
                animation-name: back-pulse-dark;
                animation-duration: 2s;
                animation-delay: 1s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                background-color: $dark;
                border-color: $dark;
            }

            @keyframes back-pulse-dark {
                0% {
                    background-color: $dark;
                    border-color: $dark;
                }
                50% {
                    background-color: $dark-lighter;
                    border-color: $dark-lighter;
                }
                100% {
                    background-color: $dark;
                    border-color: $dark;
                }
            }

            &.disabled {
                border: 1px solid $light;
                background-color: transparent;
                color: $light;
                opacity: 0.8;
            }
        }

        &.light {
            border: 1px solid $light;
            background-color: transparent;
            color: $light;

            &:hover {
                color: $dark;
                animation-name: back-pulse-light;
                animation-duration: 2s;
                animation-delay: 1s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                background-color: $light;
                border-color: $light;
            }

            @keyframes back-pulse-light {
                0% {
                    background-color: $light;
                    border-color: $light;
                }
                50% {
                    background-color: $light-darker;
                    border-color: $light-darker;
                }
                100% {
                    background-color: $light;
                    border-color: $light;
                }
            }

            &.disabled {
                border: 1px solid $light;
                background-color: transparent;
                color: $light;
                opacity: 0.8;
            }
        }

        &.dark-red {
            border: 1px solid $red;
            background-color: transparent;
            color: $light;

            &:hover {
                color: $light;
                animation-name: back-pulse;
                animation-duration: 2s;
                animation-delay: 1s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                background-color: $red-darker;
                border-color: $red-darker;
            }

            @keyframes back-pulse {
                0% {
                    background-color: $red-darker;
                    border-color: $red-darker;
                }
                50% {
                    background-color: $red;
                    border-color: $red;
                }
                100% {
                    background-color: $red-darker;
                    border-color: $red-darker;
                }
            }

            &.disabled {
                border: 1px solid $red;
                background-color: $red;
                color: $light;
                opacity: 0.8;
            }
        }

        &.red {
            border: 1px solid $red;
            background-color: $red;
            color: $light;

            &:hover {
                color: $light;
                animation-name: back-pulse;
                animation-duration: 2s;
                animation-delay: 1s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                background-color: $red-darker;
                border-color: $red-darker;
            }

            @keyframes back-pulse {
                0% {
                    background-color: $red-darker;
                    border-color: $red-darker;
                }
                50% {
                    background-color: $red;
                    border-color: $red;
                }
                100% {
                    background-color: $red-darker;
                    border-color: $red-darker;
                }
            }

            &.disabled {
                border: 1px solid $red;
                background-color: $red;
                color: $light;
                opacity: 0.8;
            }

            &:hover {
                .icon {
                    &.newsletter {
                        background-image: url('/static/images/home/newsletter_white.svg');
                    }
                }
            }
        }

        &.footer-link {
            width: 163px;
            margin: 18px 5px 0 5px;
        }

        &.centered {
            text-align: center;
        }

        &.minWidth {
            min-width: 100px;
            text-align: center;
        }

        &.alignLeft {
            margin: 0 40px 0 0;
        }
        &.fullWidth {
            // margin: 0px 0;
            // padding: 10px 20px;
            min-width: 300px;
            margin: 0px 0;
            padding: 10px 20px;
        }

        &.expand {
            margin: 3px 0;
            padding: 8px;
            padding-left: 40px;
            background-image: url('/static/images/updates/plus.png');
            background-position: 10px center;
            background-repeat: no-repeat;

            padding: 8px;
            padding-left: 40px;
            padding: 8px 15px 8px 47px;
            background-position: 17px center;
        }

        &.change {
            margin: 3px 0;
            padding: 8px;
            padding-left: 40px;
            background-image: url('/static/images/updates/change.png');
            background-position: 10px center;
            background-repeat: no-repeat;

            padding: 8px;
            padding-left: 40px;
            padding: 8px 15px 8px 47px;
            background-position: 17px center;
        }

        &.news {
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.12px;
            padding: 10px 20px;
            margin: 0;
        }

        &.dummy {
            opacity: 0.5;
            pointer-events: none;
        }

        &.thin {
            height: 19px;
            padding: 8px 16px;
            margin: 0;

            @media (max-width: 300px) {
                height: auto;
            }
        }

        &.extra-thin {
            padding: 6px 36px;
            margin: 0;
        }

        &.disabled {
            cursor: not-allowed;
        }

        @media (max-width: 300px) {
            margin: 0;
        }
    }

    &.noPadding {
        padding: 0;

        .button {
            margin: 0;
        }
    }

    .note {
        position: absolute;
        top: 51px;
        left: 0;
        font-weight: 600;
        font-size: 14px;
        padding: 8px;
        color: $dark;
        text-transform: uppercase;
    }
}
</style>
