<template>
    <div
        id="popup-layer"
        class="basic-content"
        :class="{ show: show, hide: !show }"
        @click="closeMethod"
        @mousemove="block"
    >
        <div
            id="popup-window"
            :class="{ webp: settings.webpSupport }"
            @click="stopPropagation"
        >
            <form
                action="https://subscribe.vigorgame.com/vigor-newsletter"
                method="post"
                target="_blank"
            >
                <div class="popup-content">
                    <div class="popup-close" @click="closeMethod"></div>
                    <div class="popup-title">Newsletter</div>
                    <div class="popup-text">
                        Register for updates and news about Vigor!<br />
                        <input
                            ref="checkbox"
                            v-model="terms"
                            class="checkbox"
                            type="checkbox"
                        />
                        I have read and agree to
                        <a
                            class="a-text"
                            href="https://vigorgame.com/eula"
                            target="_blank"
                            rel="noopener noreferrer"
                            >the terms of use</a
                        >.
                        <input
                            id="mce-EMAIL"
                            type="email"
                            :value="
                                user && user.emails && user.emails.length > 0
                                    ? user.emails[0].value
                                    : ''
                            "
                            name="EMAIL"
                            class="text hidden"
                        />
                    </div>
                    <div class="popup-button" :class="{ showAlert: !terms }">
                        <LinkButton
                            v-if="terms"
                            text="Sign up"
                            type="red minWidth"
                            :form="true"
                            @eventMethod="submitMethod"
                        />
                        <LinkButton
                            v-else
                            text="Sign up"
                            type="red minWidth"
                            :form="true"
                            :disabled="true"
                        />
                        <div class="alert">
                            You need to agree to the terms of use.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import LinkButton from '@/components/partials/LinkButton';
import { mapGetters } from 'vuex';

export default {
    name: 'Popup',
    components: { LinkButton },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
        },
    },
    data() {
        return {
            pages,
            terms: false,
        };
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        alert() {
            return !this.$refs.checkbox.checked;
        },
    },
    methods: {
        closeMethod() {
            this.$emit('closeMethod');
        },
        submitMethod() {
            this.$emit('submitMethod');
        },
        block(event) {
            event.preventDefault();
            event.stopPropagation();
        },
        stopPropagation(event) {
            event.stopPropagation();
        },
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';
@import '../../../styles/popups.scss';

.hidden {
    display: none;
}

.text {
    background-color: transparent;
    color: $light !important;
    font-size: 16px;
    font-family: inherit;
    width: calc(100% - 160px);
    height: 25px;
    margin: 5px 45px;
    padding: 0 45px;
    border: 1px solid $light;
    resize: none;
    opacity: 1;
    text-align: center;
    line-height: 50px;
}
</style>
