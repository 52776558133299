export const HOME_PAGE_NAME = 'home';
export const STRESS_TEST_PAGE_NAME = 'stress-test';
export const STRESS_TEST_CANCEL_PAGE_NAME = 'stress-test-cancel';
export const ABOUT_PAGE_NAME = 'about';
export const PARTNERSHIP_PAGE_NAME = 'partnership';
export const PARTNERS_PAGE_NAME = 'partners';
export const UPDATES_PAGE_NAME = 'updates';
export const UPDATES_DETAIL_PAGE_NAME = 'updates-detail';
export const NEWS_PAGE_NAME = 'news';
export const NEWS_DETAIL_PAGE_NAME = 'news-detail';
export const NEWS_DETAIL_FIRST_PAGE_NAME = 'news-detail-first';
export const PRESSKIT_PAGE_NAME = 'press';
// export const PRESSKIT_DETAIL_PAGE_NAME = 'press-detail';
export const FAQ_PAGE_NAME = 'faq';
export const EULA_PAGE_NAME = 'eula';
export const EULA_WORKSHOP_PAGE_NAME = 'eula-workshop';
export const EULA_PURCHASES_PAGE_NAME = 'eula-purchases';
export const EULA_PLAYSTATION_PAGE_NAME = 'eula-playstation';
export const NOT_FOUND_PAGE_NAME = 'not-found';
export const CONTACT_PAGE_NAME = 'contact';
export const NEWSLETTER_PAGE_NAME = 'newsletter';
export const NEWSLETTER_CANCEL_PAGE_NAME = 'newsletter-cancel';
export const PROMO_DETAIL_PAGE_NAME = 'promo-detail';
export const PROMO_OTHER_DETAIL_PAGE_NAME = 'promo-other-detail';
export const CHANGELOG_DETAIL_PAGE_NAME = 'changelog-detail';
export const NINTENDOSWITCH_PAGE_NAME = 'nintendo-switch';
export const ANNIVERSARY_PAGE_NAME = 'anniversary';
export const COMMUNITY = 'community';
