export const faq_pc_left = [
    {
        title: 'Why is Vigor coming to PC?',
        description:
            'Bringing Vigor to PC was one of the things the community requested the most over the course of the game. Having spent the last few years working on new features and major improvements (improved onboarding, improved gameplay mechanics, new Leaderboards, etc.), we feel like now is the time for PC players to experience the atmosphere and thrills that Vigor has to offer.',
    },

    {
        title: 'What am I buying with the Vigor - Reinforcements Pack?',
        description:
            'The Vigor - Reinforcements Pack contains an exclusive uniform and a unique title that you can wear as a badge of honor for being one of the very first to play Vigor on PC. The Pack also contains a set of weapons and consumables from all rarities that will help you kickstart your journey as you become the ultimate Outlander in perilous Norway. ',
    },

    {
        title: 'Will there be a wipe?',
        description:
            'Yes. Once the Early Access is over, all progress will be wiped for all players on PC. The only thing that will be bound to their account are the contents of the Vigor - Reinforcements Pack.',
    },

    {
        title: "What's the difference between the console and the PC version?",
        description:
            'When Vigor launches on Early Access on Steam, players will be able to play through Season 18, a.k.a. Vigor Chronicles: Isolation. This Season was already released for consoles a few months ago. While PC players will get to relive Season 18 with the exclusive Weapon Overhaul for testing and feedback, console players will be able to play through the new Season 19.',
    },

    {
        title: 'Will there be cross-progression?',
        description: "Right now, we don't have plans to add cross-progression.",
    },

    {
        title: 'How will the PC port affect the console version of Vigor?',
        description:
            "Our team has made continuous efforts to support the console version, ensuring the delivery of regular content. We have worked diligently to ensure that PC development doesn't disrupt our current workflow. Only Season 19 will be slightly affected, in terms of content, due to the development of the PC version of Vigor. Thanks to the help from an external studio, our team had enough time to plan an exciting 2024 for you with much-requested features.",
    },
];

export const faq_pc_right = [
    {
        title: 'How can I play Vigor during Early Access?',
        description:
            "You will be able to play Vigor in Early Access for $19.99. It's essentially the price of the exclusive Vigor - Reinforcements Pack, which will allow Outlanders to play Vigor during Early Access whilst also becoming part of Vigor's history as the first players on PC! The Pack contains an exclusive uniform, a unique title, a set of weapons, and consumables. After the end of Early Access, the game will become free-to-play.",
    },
    {
        title: 'Why is Vigor being released in Early Access?',
        description:
            'Early Access will allow us, as well as you, to help shape the PC version of Vigor into a more refined and overall better PC experience. You will be able to provide us with valuable feedback for the duration of Early Access, which our team will evaluate and try to implement before the full release of the game.',
    },
    {
        title: 'When can we expect the free-to-play release?',
        description: 'The game will be fully released later this year.',
    },
    {
        title: 'Will there be cross-play?',
        description:
            'Cross-play is something we intend to explore after the full release of the game.',
    },
    {
        title: 'Does Vigor run on Steam Deck?',
        description:
            'We will be looking into full Steam Deck support in the upcoming updates.',
    },
    {
        title: 'Other PC stores except Steam?',
        description:
            "We're not looking into the possibility of having Vigor on other stores at the moment, but we might consider it in the future.",
    },
];
