<template>
    <div
        id="social"
        :class="{
            section: true,
            webp: settings.webpSupport,
        }"
    >
        <div class="section-content">
            <div class="page-title">Follow Vigor</div>
            <SocialLinks
                class="social-links"
                icon-style="biggest"
                :light="true"
            />
            <div class="page-title">Stay In Touch</div>
            <NewsletterButton
                class="newsletter-button"
                text="Sign Up For Updates"
                type="light centered red"
            />
        </div>
    </div>
</template>

<script>
import SocialLinks from '@/components/partials/SocialLinks';
import * as pages from '@/routes/pageCodes';
import NewsletterButton from '@/components/partials/NewsletterButton';
import { mapGetters } from 'vuex';

export default {
    name: 'FooterSocial',
    components: { SocialLinks, NewsletterButton },

    data() {
        return {
            pages,
        };
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../styles/colors.scss';

#social {
    position: relative;
    z-index: 100;
    background-color: #252525;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 45px;

    .section-content {
        .page-title {
            color: $red;
            font-size: 48px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.72px;
            margin-bottom: 20px;

            @media (max-width: 768px) {
                font-size: 36px;
            }
        }

        .social-links {
            margin-bottom: 50px;
        }
        .newsletter-button {
            margin-top: 15px;
        }
    }

    @media (max-width: 358px) {
        &.bg1,
        &.bg2 {
            height: auto;
        }

        .section-content {
            padding-top: 0;
        }
    }

    &:before {
        content: url('/static/images/placeholder/footer_bg_edge_top.png');
        position: absolute;
        top: -80px;
        width: 100%;
        z-index: 101;
    }
    &:after {
        content: url('/static/images/placeholder/footer_bg_edge_top.png');
        transform: rotate(-180deg);
        position: absolute;
        bottom: -80px;
        width: 100%;
        z-index: 101;
    }
}
</style>
