'use strict';

class webp {
    constructor() {
        this.webpSupport = null;
    }

    isSupported() {
        if (this.webpSupport !== null) {
            return Promise.resolve(this.webpSupport);
        }
        return new Promise(function(resolve) {
            let image = new Image();
            image.onerror = function() {
                this.webpSupport = false;
                resolve(false);
            };
            image.onload = function() {
                this.webpSupport = true;
                resolve(true);
            };

            image.src =
                'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
        });
    }
}

/**
 * @singleton
 */
export default new webp();
