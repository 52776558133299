import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import {
    settings,
    user,
    updates,
    articles,
    faqs,
    homepages,
    presskits,
    homeItems,
    partners,
    changelogs,
    partnerships,
} from './modules';

const store = new Vuex.Store({
    modules: {
        settings,
        user,
        updates,
        articles,
        faqs,
        homepages,
        presskits,
        homeItems,
        partners,
        changelogs,
        partnerships,
    },
});

export default store;
