<template>
    <div class="global-style">
        <div id="promo-detail" class="basic-page">
            <Loading :show="!dataLoaded || codeIsLoading" />
            <div v-show="dataLoaded" v-if="dataLoaded">
                <div
                    class="static-bg"
                    :class="{ hidden: settings && settings.apple }"
                >
                    <ImageOptimized
                        code="bg-promotions"
                        thumb-formats="webp,jpg"
                        thumb-sizes="480,768,992,1200"
                        type="static-bg"
                        @loaded="loaded.staticBg = true"
                        @failed="loaded.staticBg = true"
                    />
                </div>
                <div class="section promo-detail-content dark">
                    <div class="section-content">
                        <div class="upper-title">
                            Vigorous Outlander Partner
                        </div>
                        <div class="page-title">{{ promotion.name }}</div>
                        <div class="content">
                            <div class="promo-line-block">
                                <div class="title-image">
                                    <img
                                        :src="promotion.titleImage"
                                        draggable="false"
                                    />
                                </div>
                                <div class="promo-half-block">
                                    <div class="basic-page-section">
                                        <h2 class="capitals">
                                            About {{ promotion.name }}
                                        </h2>
                                        <p v-html="promotion.about"></p>
                                    </div>

                                    <div class="basic-page-section">
                                        <h2 class="capitals">
                                            {{ promotion.name }} Backpack
                                        </h2>
                                        Go to Xbox store and claim your new
                                        customization.<br /><br />
                                        <Card
                                            button="Claim"
                                            :img="promotion.previewImage"
                                            :padding="false"
                                            :event="true"
                                            :no-button="true"
                                            :disabled-text="
                                                codeAvailable &&
                                                !promotion.manualSoldOut
                                                    ? null
                                                    : 'Out of stock'
                                            "
                                            @eventMethod="requestCode"
                                        />
                                    </div>
                                </div>

                                <div class="promo-half-block">
                                    <div class="basic-page-section">
                                        <h2 class="capitals">Why Vigor?</h2>
                                        <p v-html="promotion.reason"></p>
                                    </div>

                                    <div
                                        class="basic-page-section social-links"
                                    >
                                        <h2 class="capitals">
                                            Follow {{ promotion.name }}
                                        </h2>
                                        <a
                                            v-for="link in links"
                                            v-if="promotion[link.param]"
                                            :key="link.name"
                                            class="icon-link no-underline"
                                            :href="promotion[link.param]"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img :src="imgPath + link.img" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- sign up -->
                    <Popup
                        :show="popupLogin"
                        title="Code"
                        text="You must log into your Bohemia account or create it to get the code!"
                        button-text="Log in"
                        @closeMethod="loginClose"
                        @submitMethod="loginSubmit"
                    />
                    <PopupCode
                        :show="popupCode"
                        :user="user"
                        :promotion="promotion"
                        @closeMethod="codeClose"
                        @submitMethod="codeSubmit"
                        @codeLoadingMethod="codeLoading"
                    />
                    <PopupCodeResult
                        v-if="claimResult"
                        :show="popupClaimed"
                        :result="claimResult"
                        @closeMethod="signedUpClose"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import router from '@/routes';
import { baseTitle } from '@/routes/index';
import Loading from '@/components/partials/Loading';
import SocialLinks from '@/components/partials/SocialLinks';
import Card from '@/components/partials/Card';
import { mapGetters } from 'vuex';
import ImageOptimized from '@/components/partials/ImageOptimized';
import urlParser from 'js-video-url-parser';
import { PromotionResource } from '@/services/promotion';
import { CodeResource } from '@/services/code';
import Popup from '@/components/partials/popups/Popup';
import PopupCode from '@/components/partials/popups/PopupCode';
import PopupCodeResult from '@/components/partials/popups/PopupCodeResult';

const imgPath = '/static/images/social/';

const links = [
    { img: 'fb.svg', name: 'Facebook', param: 'facebook' },
    { img: 'tw.svg', name: 'Twitter', param: 'twitter' },
    { img: 'yt.svg', name: 'YouTube', param: 'youtube' },
    { img: 'twitch.svg', name: 'Twitch', param: 'twitch' },
    { img: 'tiktok.svg', name: 'Tiktok', param: 'tiktok' },
];

export default {
    name: 'Promotion',
    components: {
        Loading,
        SocialLinks,
        Card,
        ImageOptimized,
        Popup,
        PopupCode,
        PopupCodeResult,
    },
    data() {
        return {
            pages,
            links,
            promotion: null,
            loaded: {
                staticBg: false,
            },
            imgPath,

            popupLogin: false,
            popupLoginCancel: false,
            popupClaimed: false,
            popupCode: false,

            claimResult: null,
            codeAvailable: false,
            window: { w: window.innerWidth, h: window.innerHeight },
            codeIsLoading: false,
        };
    },
    computed: {
        dataLoaded() {
            return this.promotion;
        },
        slug() {
            return this.$route.params.slug;
        },
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        ...mapGetters('user', {
            user: 'getUser',
        }),
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);

        PromotionResource.get({ id: this.slug })
            .then(async response => {
                this.promotion = response.body.promotion;

                //replaces with bigger image
                if (this.promotion.titleImage.includes('_')) {
                    let split = this.promotion.titleImage.split('.');
                    this.promotion.titleImage =
                        this.promotion.titleImage.split('_')[0] +
                        '.' +
                        split[split.length - 1];
                }

                if (!this.promotion) {
                    router.push({ name: pages.NOT_FOUND_PAGE_NAME });
                }
                this.checkCode();
            })
            .catch(e => {
                router.push({ name: pages.NOT_FOUND_PAGE_NAME });
            });
    },
    updated() {
        if (!this.promotion) return;

        if (this.promotion.name.length <= 16) {
            document.title =
                this.promotion.name + ' | Promotion | ' + baseTitle;
        } else {
            document.title =
                this.promotion.name.substring(0, 16) +
                '... | Promotion | ' +
                baseTitle;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        loginSubmit() {
            router.push({
                name: pages.PROMO_DETAIL_PAGE_NAME,
                params: { slug: this.promotion.slug },
            });
            window.location.href = '/api/auth/login';
        },
        loginCancelSubmit() {
            router.push({
                name: pages.PROMO_DETAIL_PAGE_NAME,
                params: { slug: this.promotion.slug },
            });
            window.location.href = '/api/auth/login';
        },

        loginClose() {
            this.popupLogin = false;
        },
        loginCancelClose() {
            this.popupLoginCancel = false;
        },

        signedUpClose() {
            this.popupClaimed = false;
            window.location.reload();
        },

        requestCode() {
            if (this.user) {
                this.popupCode = true;
            } else {
                this.popupLogin = true;
            }

            this.claimResult = null;
        },
        codeClose() {
            this.popupCode = false;
        },
        codeLoading() {
            this.codeIsLoading = true;
        },
        codeSubmit(result) {
            this.popupCode = false;
            this.codeIsLoading = false;
            this.popupClaimed = true;
            this.claimResult = result;
        },
        checkCode() {
            CodeResource.check({ id: this.promotion.id })
                .then(response => {
                    this.codeAvailable = response.body.result == 'available';
                })
                .catch(err => {
                    console.log(err);
                });
        },
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/bg-promotions.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';
@import '../../../styles/promo.scss';
</style>
