<template>
    <div id="faq" class="basic-page">
        <Loading :show="!imgsLoaded" />
        <div v-show="imgsLoaded">
            <div
                class="static-bg"
                :class="{ hidden: settings && settings.apple }"
            >
                <ImageOptimized
                    code="bg-faq"
                    thumb-formats="webp,jpg"
                    thumb-sizes="480,768,992,1200"
                    type="static-bg"
                    @loaded="loaded.staticBg = true"
                    @failed="loaded.staticBg = true"
                />
            </div>
            <div id="faq-content" class="section">
                <div v-if="faqs && faqs.rows" class="section-content">
                    <div class="faq__title">FAQ</div>
                    <div class="page-title">Vigor on pc</div>
                    <div class="content">
                        <div class="faq-block">
                            <div
                                v-for="faq in faq_pc_left"
                                :key="faq.title"
                                class="basic-page-section"
                            >
                                <h2 class="capitals">{{ faq.title }}</h2>
                                <p>{{ faq.description }}</p>
                            </div>
                        </div>
                        <div class="faq-block">
                            <div
                                v-for="faq in faq_pc_right"
                                :key="faq.order"
                                class="basic-page-section"
                            >
                                <h2 class="capitals">{{ faq.title }}</h2>
                                <p>{{ faq.description }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="page-title">Vigor on Consoles</div>
                    <div class="content">
                        <div class="faq-block">
                            <div
                                v-for="faq in faqs.rows"
                                v-if="
                                    faq.order <= Math.ceil(faqs.rows.length / 2)
                                "
                                :key="faq.order"
                                class="basic-page-section"
                            >
                                <h2 class="capitals">{{ faq.question }}</h2>
                                <p v-html="faq.answer"></p>
                            </div>
                        </div>
                        <div class="faq-block">
                            <div
                                v-for="faq in faqs.rows"
                                v-if="
                                    faq.order > Math.ceil(faqs.rows.length / 2)
                                "
                                :key="faq.order"
                                class="basic-page-section"
                            >
                                <h2 class="capitals">{{ faq.question }}</h2>
                                <p v-html="faq.answer"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from '@/components/partials/Loading';
import ImageOptimized from '@/components/partials/ImageOptimized';
import { faq_pc_left, faq_pc_right } from '../../utils/faq-pc';
import { mapGetters } from 'vuex';

export default {
    name: 'Faq',
    components: {
        Loading,
        ImageOptimized,
    },
    data() {
        return {
            loaded: {
                staticBg: false,
            },
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        imgsLoaded() {
            return this.loaded.staticBg && this.faqs && this.faqs.rows;
        },
        ...mapGetters('faqs', {
            faqs: 'getFaqs',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        faq_pc_right() {
            return faq_pc_right;
        },
        faq_pc_left() {
            return faq_pc_left;
        },
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/bg-faq.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import './../../styles/colors.scss';
@import './../../styles/global.scss';

.faq__title {
    font-weight: bold;
    font-size: 23px;
}

#faq {
    #faq-content {
        margin-bottom: 70px;

        .section-content {
            padding-top: 192px;

            .content {
                margin-top: 50px;
                margin-right: -190px;

                .faq-block {
                    display: inline-block;
                    width: 590px;
                    max-width: 80vw;
                    vertical-align: top;
                    margin-right: 190px;
                }
            }
        }
    }
}
</style>
