<template>
    <div id="footer" class="section">
        <div class="section-content">
            <div class="section-content__links">
                <LinkButton
                    text="PARTNERSHIP"
                    type="dark-red footer-link noPadding"
                    :link="pages.PARTNERSHIP_PAGE_NAME"
                />
                <LinkButton
                    text="CONTACT"
                    type="dark-red footer-link noPadding"
                    :link="pages.CONTACT_PAGE_NAME"
                />
                <LinkButton
                    text="FAQ"
                    type="dark-red footer-link noPadding"
                    :link="pages.FAQ_PAGE_NAME"
                />
                <LinkButton
                    text="PRESS KIT"
                    type="dark-red footer-link noPadding"
                    :link="pages.PRESSKIT_PAGE_NAME"
                />
            </div>
            <div class="section-content__logos">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://bistudio.com/"
                    class="logo-link no-underline"
                    ><BohemiaIcon fill="white" width="100%" height="60px" />
                </a>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://store.steampowered.com/"
                    class="logo-link no-underline"
                >
                    <SteamLogoIcon />
                </a>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.xbox.com/"
                    class="logo-link no-underline"
                    ><XboxIcon fill="white" height="55"
                /></a>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.nintendo.com/"
                    class="logo-link no-underline"
                    ><NintendoIcon fill="white" width="60"
                /></a>

                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.playstation.com/"
                    class="logo-link no-underline"
                    ><PlaystationIcon width="60"
                /></a>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://pegi.info/"
                    class="logo-link no-underline"
                    ><PegiIcon width="60"
                /></a>
            </div>

            <div class="section-content__legal">
                <div class="legal__top">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.bohemia.net/cookie-policy"
                        class="no-underline legal__top-text"
                        >COOKIES</a
                    >

                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.bohemia.net/privacy-policy"
                        class="no-underline legal__top-text"
                        >PRIVACY POLICY</a
                    >

                    <router-link
                        exact
                        :to="{ name: pages.EULA_PAGE_NAME }"
                        class="no-underline legal__top-text"
                        >EULA & TERMS</router-link
                    >
                </div>
                <div class="text legal__bottom">
                    &#169; {{ new Date().getFullYear() }} BOHEMIA INTERACTIVE
                    a.s. VIGOR® and BOHEMIA INTERACTIVE® are trademarks or
                    registered trademarks of BOHEMIA INTERACTIVE a.s. All rights
                    reserved. Nintendo Switch™ is a trademark of Nintendo. All
                    other trademarks and copyrights are the property of their
                    respective owners.
                </div>
            </div>
            <div
                v-if="scrollTopButton"
                class="scrolltop-button"
                @click="scrollTop"
            >
                <div class="img" />
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';

import LinkButton from '@/components/partials/LinkButton';

import BohemiaIcon from 'Images/footer/bohemia-logo-white.svg?inline';
import XboxIcon from 'Images/footer/xboxlogo.svg?inline';
import NintendoIcon from 'Images/footer/switchlogo.svg?inline';
import PegiIcon from 'Images/footer/pegi2.svg?inline';
import PlaystationIcon from 'Images/footer/playstation-logo.svg?inline';
import SteamLogoIcon from 'Images/footer/steam-logo-white.svg?inline';

export default {
    name: 'FooterBasic',
    components: {
        LinkButton,
        BohemiaIcon,
        XboxIcon,
        NintendoIcon,
        PegiIcon,
        PlaystationIcon,
        SteamLogoIcon,
    },
    props: {
        scrollTopButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            pages,
        };
    },
    methods: {
        scrollTop() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../styles/colors.scss';

#footer {
    min-height: 270px;
    padding-top: 180px;
    position: relative;
    z-index: 10;
    background-color: $dark;

    .section-content {
        text-align: center;
        padding: 0px 20px 50px;

        .section-content__links {
            margin-bottom: 50px;
        }

        .section-content__logos {
            position: relative;
            display: block;
            margin: 0 auto;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
            justify-content: center;
            align-content: center;
            grid-gap: 10px;

            .logo-link {
                padding: 10px 0;
                margin: 0 auto;
                svg {
                    opacity: 1;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }

        .section-content__legal {
            padding-top: 80px;
            .legal__top {
                max-width: 455px;
                width: 100%;
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
                display: flex;
                justify-content: space-between;

                .legal__top-text {
                    color: $light;
                    font-size: 14px;
                    font-weight: 700;
                    text-decoration: none;
                    padding: 10px;

                    &:hover {
                        color: $red;
                    }
                }
            }
        }

        .text {
            font-size: 12px;
            font-weight: 400;
            padding: 10px;
            color: $light;

            a {
                color: $light;
                text-decoration: none;

                &:hover {
                    color: $red;
                }
            }
        }

        .scrolltop-button {
            position: absolute;
            top: 100px;
            right: 0;
            margin: 40px;
            border: 1px solid $light;
            cursor: pointer;

            .img {
                height: 25px;
                width: 20px;
                margin: 10px 12px 6px 12px;
                background-image: url('/static/images/footer/scrolltotop.svg');
                background-repeat: no-repeat;
            }

            &:hover {
                background-color: $light;

                .img {
                    background-image: url('/static/images/footer/scrolltotop-black.svg');
                }
            }
        }
    }
}
</style>
