<template>
    <div id="partners" class="basic-page">
        <Loading :show="!dataLoaded" />
        <div v-show="dataLoaded">
            <div
                class="static-bg"
                :class="{ hidden: settings && settings.apple }"
            >
                <ImageOptimized
                    code="bg-promotions"
                    thumb-formats="webp,jpg"
                    thumb-sizes="480,768,992,1200"
                    type="static-bg"
                    @loaded="loaded.staticBg = true"
                    @failed="loaded.staticBg = true"
                />
            </div>
            <div class="section dark">
                <div class="section-content">
                    <div class="upper-title">The Most Vigorous Outlanders</div>
                    <div class="page-title">Vigor Partners</div>
                    <div class="content">
                        <div
                            v-if="partners && partners.rows"
                            class="partners-list"
                        >
                            <router-link
                                class="a-text no-underline dark"
                                exact
                                :to="{ name: pages.PARTNERSHIP_PAGE_NAME }"
                            >
                                <div class="back"><< BACK TO PARTNERSHIP</div>
                            </router-link>
                            <template v-for="(partner, index) in partners.rows">
                                <MiniCard :key="index" :partner="partner" />
                            </template>
                            <router-link
                                class="a-text no-underline dark"
                                exact
                                :to="{ name: pages.PARTNERSHIP_PAGE_NAME }"
                            >
                                <div class="back"><< BACK TO PARTNERSHIP</div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MiniCard from '@/components/partials/MiniCard';
import Loading from '@/components/partials/Loading';
import { mapGetters } from 'vuex';
import ImageOptimized from '@/components/partials/ImageOptimized';
import * as pages from '@/routes/pageCodes';

export default {
    name: 'Partners',
    components: {
        MiniCard,
        Loading,
        ImageOptimized,
    },
    data() {
        return {
            pages,
            loaded: {
                staticBg: false,
            },
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        dataLoaded() {
            return this.loaded.staticBg && this.partners && this.partners.rows;
        },
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        ...mapGetters('partners', {
            partners: 'getPartners',
        }),
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/bg-promotions.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import './../../styles/colors.scss';
@import './../../styles/global.scss';

#partners {
    .section-content {
        padding-top: 192px;
        padding-bottom: 192px;

        .a-text {
            margin-bottom: 20px;
            display: block;
        }
    }
}
</style>
