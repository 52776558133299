import { render, staticRenderFns } from "./SocialLinks.vue?vue&type=template&id=d5fccd22&scoped=true"
import script from "./SocialLinks.vue?vue&type=script&lang=js"
export * from "./SocialLinks.vue?vue&type=script&lang=js"
import style0 from "./SocialLinks.vue?vue&type=style&index=0&id=d5fccd22&prod&scoped=true&lang=scss&media=print"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5fccd22",
  null
  
)

export default component.exports