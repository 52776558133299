<template>
    <div id="press" class="basic-page">
        <Loading :show="!dataLoaded" />
        <div v-show="dataLoaded">
            <div
                class="static-bg"
                :class="{ hidden: settings && settings.apple }"
            >
                <ImageOptimized
                    code="presskit/bg-presskit"
                    thumb-formats="webp,jpg"
                    thumb-sizes="768,992,1200"
                    type="static-bg"
                    @loaded="loaded.staticBg = true"
                    @failed="loaded.staticBg = true"
                />
            </div>
            <div id="press-content" class="section">
                <div class="section-content">
                    <div
                        v-for="presskit in presskits.rows"
                        :key="presskit.id"
                        class="content"
                    >
                        <div class="page-title">{{ presskit.name }}</div>

                        <div v-if="presskit.tags" class="event-tags">
                            <div v-for="(tag, id) in shownTags" :key="id">
                                <img :src="tag" class="entry-tag" />
                            </div>
                        </div>
                        <div class="press-line-block">
                            <div class="press-half-block">
                                <div
                                    v-if="presskit.generalInfo"
                                    class="basic-page-section"
                                >
                                    <h2 class="capitals">General Info</h2>
                                    <p
                                        v-html="
                                            processText(presskit.generalInfo)
                                        "
                                    ></p>
                                </div>
                                <div class="basic-page-section genInfo">
                                    <h2 class="capitals">Description</h2>

                                    <p
                                        v-html="
                                            processText(presskit.description)
                                        "
                                    ></p>
                                </div>
                            </div>

                            <div class="press-half-block">
                                <div class="basic-page-section">
                                    <h2 class="capitals">Key Features</h2>
                                    <v-runtime-template
                                        :template="
                                            `<div>${processList(
                                                presskit.keyFeatures
                                            )}</div>`
                                        "
                                    />
                                </div>

                                <div class="basic-page-section">
                                    <h2 class="capitals">Press Contact</h2>
                                    <p>
                                        <a
                                            class="a-text"
                                            href="mailto:marketing@bohemia.net"
                                            target="_top"
                                            rel="noopener noreferrer"
                                            >press.vigor@bistudio.com</a
                                        >
                                    </p>
                                </div>

                                <div class="basic-page-section">
                                    <h2 class="capitals">Social</h2>
                                    <p>
                                        <SocialLinks :simple="true" />
                                    </p>
                                </div>

                                <div class="basic-page-section">
                                    <h2 class="capitals">Newsletter</h2>

                                    <NewsletterButton
                                        text="Sign Up For Updates"
                                        type="light thin centered red"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="presskit.video"
                            class="embed-responsive embed-responsive-16by9 press-line-block video"
                        >
                            <iframe
                                class="embed-responsive-item behind"
                                :src="videoUrl"
                                frameborder="0"
                                height="100%"
                                width="100%"
                                allowfullscreen
                            >
                                <video
                                    :src="videoUrl"
                                    width="640"
                                    height="360"
                                    style="width: 640px; height: 360px"
                                    autoplay="0"
                                ></video>
                            </iframe>
                        </div>

                        <div
                            v-if="presskit.screenshots.includes('app-picture')"
                            class="press-line-block"
                        >
                            <div class="basic-page-section">
                                <h2 class="capitals">Screenshots</h2>
                                <v-runtime-template
                                    :template="
                                        `<div class='screenshots'>${processScreenshots(
                                            presskit.screenshots
                                        )}</div>`
                                    "
                                />
                            </div>
                        </div>

                        <h2
                            v-if="presskit.videoPackage || presskit.artPackage"
                            class="capitals"
                        >
                            Downloads
                        </h2>
                        <div class="press-line-block">
                            <Card
                                v-if="presskit.artPackage"
                                class="downloadImgClass"
                                title="Artwork & Screenshots"
                                button="Download"
                                :img="presskit.previewArtImage"
                                :download="true"
                                :link-name="presskit.artPackage"
                            />
                            <Card
                                v-if="presskit.videoPackage"
                                class="downloadImgClass"
                                title="Videos"
                                button="Download"
                                :img="presskit.previewVideoImage"
                                :download="true"
                                :link-name="presskit.videoPackage"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import Loading from '@/components/partials/Loading';
import SocialLinks from '@/components/partials/SocialLinks';
import Card from '@/components/partials/Card';
import { mapGetters } from 'vuex';
import ImageOptimized from '@/components/partials/ImageOptimized';
import VRuntimeTemplate from 'v-runtime-template';
import AppPicture from '@/components/common/widgets/AppPicture';
import urlParser from 'js-video-url-parser';

//SVGs
import XboxTagIcon from 'Images/tags/xbox_tag.svg';
import SwitchTagIcon from 'Images/tags/switch_tag.svg';
import PlaystationTagIcon from 'Images/tags/playstation_tag.svg';

export default {
    name: 'Press',
    components: {
        Loading,
        SocialLinks,
        Card,
        LinkButton: () => import('@/components/partials/LinkButton'),
        NewsletterButton: () =>
            import('@/components/partials/NewsletterButton'),
        ImageOptimized,
        'v-runtime-template': VRuntimeTemplate,
        AppPicture,
    },
    data() {
        return {
            pages,
            loaded: {
                staticBg: false,
            },
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        shownTags() {
            let shownTags = [];
            let tags = [];

            if (this.presskits.rows[0].tags) {
                tags = JSON.parse(this.presskits.rows[0].tags);
            }

            if (tags.length > 0 && this.presskits.rows[0].tags) {
                tags.forEach(element => {
                    if (element === 'Xbox') {
                        shownTags.push(XboxTagIcon);
                    } else if (element === 'Switch') {
                        shownTags.push(SwitchTagIcon);
                    } else {
                        shownTags.push(PlaystationTagIcon);
                    }
                });
                return shownTags;
            } else {
                return '';
            }
        },
        videoUrl() {
            let data = urlParser.parse(this.presskits.rows[0].video);
            return (
                urlParser.create({ videoInfo: data, format: 'embed' }) +
                '?autoplay=1&amp;enablejsapi=1&amp;autohide=1&amp;showinfo=0&amp;rel=0'
            );
        },
        dataLoaded() {
            return (
                this.loaded.staticBg && this.presskits && this.presskits.rows
            );
        },
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        ...mapGetters('presskits', {
            presskits: 'getPresskitsGeneral',
        }),
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        processScreenshots(text) {
            return text.replace(
                /<app-picture /g,
                '<app-picture linkToMaxImage="true" '
            );
        },
        processList(text) {
            return text
                .replace(
                    /<p>/g,
                    '<div class="presskit-list" style="margin: 8px 0">'
                )
                .replace(/<\/p>/g, '</div>');
        },
        processText(text) {
            return text
                .replace(/<a /g, '<a class="a-text dark" ')
                .replace(/<li>/g, '<div class="content-item">')
                .replace(/<\/li>/g, '</div>')
                .replace(/<ul>/g, '')
                .replace(/<\/ul>/g, '');
        },
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/presskit/bg-presskit.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

#press {
    #press-content {
        margin-bottom: 50px;

        .section-content {
            padding-top: 192px;
            padding: 0 10vw;

            .content {
                margin-top: 100px;
                margin-right: -190px;
                width: 100%;

                @media (max-width: 950px) {
                    margin-right: 0px;
                }
                .event-tags {
                    display: flex;
                    align-items: center;
                    margin: 10px 0;

                    .entry-tag {
                        max-width: 26px;
                        width: 100%;
                        height: auto;
                        padding-right: 20px;
                    }
                }
                .press-line-block {
                    margin-bottom: 20px;

                    .downloadImgClass {
                        /deep/ img {
                            left: 0;
                        }

                        /deep/.gradient {
                            left: 0;
                        }
                    }

                    .screenshots {
                        width: 100%;
                    }
                    &--update {
                        display: grid;
                        grid-template-columns: repeat(
                            auto-fill,
                            minmax(450px, 1fr)
                        );
                        grid-gap: 10px;
                    }

                    .press-half-block {
                        display: inline-block;
                        width: 590px;
                        max-width: calc(50% - 200px);
                        vertical-align: top;
                        margin-right: 190px;

                        @media (max-width: 950px) {
                            width: 100%;
                            display: block;
                            max-width: 100%;
                        }
                    }

                    .genInfo /deep/ a {
                        text-decoration: none;
                        color: white;
                        font-weight: 600;
                        display: inline-block;
                        border-bottom: 1px solid white;
                        margin: 0;
                    }

                    .genInfo /deep/ a:hover {
                        color: red;
                    }

                    &.video {
                        height: 41.5vw;
                        max-height: 790px;
                        //width: 77vw;
                        //max-width: 1478px;
                        margin-bottom: 55px;
                    }
                }
            }
        }
    }
}

.basic-page .screenshots /deep/ .basic-image {
    max-width: 889px;
    width: 100%;
    height: auto;
}
.basic-page /deep/ .grid-vertical {
    display: block;
    width: calc(100% + 28px);

    .grid-item {
        display: inline-flex;
    }
}
.basic-page /deep/ .grid-horizontal {
    display: inline-flex;
    width: 100%;

    .grid-item {
        display: block;
        margin-right: 28px;
        margin-bottom: 28px;
    }

    &:last-child {
        width: calc(100% + 28px);
    }

    @media (max-width: 950px) {
        display: block;
    }
}
</style>
