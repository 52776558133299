'use strict';

class browser {
    constructor() {}

    isApple() {
        return (
            !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
        );
    }

    isSafari() {
        return (
            navigator.vendor &&
            navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1
        );
    }

    isEdge() {
        return (
            navigator.userAgent.indexOf('Edge/') > -1 ||
            navigator.userAgent.indexOf('MSIE ') > -1 ||
            navigator.userAgent.indexOf('Trident/') > -1
        );
    }
}

/**
 * @singleton
 */
export default new browser();
