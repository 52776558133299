<!-- this file has a duplicate in vigor-admin-nodejs-vuejs -->
<template>
    <div style="width: 100%;">
        <div style="width: 500px; margin: auto; max-width: 80vw;">
            <tweet :id="id" :options="options"></tweet>
        </div>
    </div>
</template>
<script>
import { Tweet } from 'vue-tweet-embed';

export default {
    name: 'EditorTweet',
    components: { Tweet },
    props: {
        id: {
            type: String,
            default: null,
        },
        showcard: {
            type: String,
            default: null,
        },
        darktheme: {
            type: String,
            default: null,
        },
    },
    computed: {
        options() {
            let options = {};
            if (this.showcard !== 'true') options.cards = 'hidden';
            if (this.darktheme == 'true') options.theme = 'dark';
            return options;
        },
    },
};
</script>
