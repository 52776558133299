<template>
    <div id="eula" class="basic-page">
        <div class="static-bg" :class="{ hidden: settings && settings.apple }">
            <ImageOptimized
                code="bg-eula"
                thumb-formats="webp,jpg"
                thumb-sizes="768,992,1200"
                type="static-bg"
            />
        </div>
        <div id="eula-content" class="section">
            <div class="section-content">
                <div class="upper-title">End-user License Agreement</div>
                <div class="page-title">Vigor EULA</div>
                <div class="text">
                    <h1>General Provisions</h1>

                    This is the license agreement ("license") between
                    <b>You</b> and <b>Us</b>, the developers of
                    <b>VIGOR</b> ("game"), BOHEMIA INTERACTIVE a.s., Stribrna
                    Lhota 747, 252 10, Mnisek pod Brdy, Czech Republic. All use
                    of the game is limited by this license. If You do not agree
                    with it, do not buy, download or play the game. This
                    license, if accepted, allows You to use Our game and play it
                    on Your computer or device, but You do not own the game. We
                    remain the owners of Our game and Your only rights to it are
                    specified in this license.

                    <h1>Your Rights</h1>
                    You may play and use the game for non-commercial purposes
                    only.<br /><br />
                    You may create footage (screenshots and videos) of the game
                    and share it online (e.g. on YouTube or Twitch). We reserve
                    the right to take down such footage in case it shows or
                    promotes hacking, cheating or any other distasteful, obscene
                    or unlawful behavior. We give You permission to make money
                    (e.g. advertisement revenues) from this footage, but only if
                    You add enough of Your own content (for example commentary)
                    to the footage which would justify it.

                    <h1>You May Not</h1>
                    You must not redistribute or resell the game, any of its
                    parts or any hacked version of the game.<br /><br />
                    Do not use the game or any of its parts commercially without
                    Our permission.<br /><br />
                    You are not allowed to hack, modify or reverse engineer the
                    game or any game files, its data or network Services. You
                    are also not allowed to cheat, create cheats or promote
                    cheats in the game.<br /><br />
                    Do not post any information in the game or in connection
                    with the game that contains nudity, excessive violence,
                    abuse, harassment, or offensive subject matter or that
                    contains a link to such content.

                    <h1>User-created Content</h1>
                    If You create any content ("content") using the game or make
                    it available through the game, You may do so, but there are
                    rules.<br /><br />
                    This content must not infringe anyone's copyrights or author
                    rights, it must not be offensive to people or illegal in any
                    other way.<br /><br />
                    For the benefit of the entire User community, You give us an
                    irrevocable permission to use, copy, modify and adapt
                    anything You create using Our game and share with other
                    Users of Our game in multiplayer or as an online download
                    (including but not limited to in-game objects or terrain
                    composition). You also agree that We allow other people to
                    use, copy, modify and adapt Your content under the terms of
                    this license.<br /><br />
                    If You do not want to accept these rules, do not create and
                    share any content using Our game.

                    <h1>Online Interactions</h1>
                    As in any other online application, be extremely careful
                    when interacting with other people online in the game: never
                    share Your real life identity (including email or other
                    personal details) with strangers unless You are entirely
                    sure it is safe. We do not know who You can encounter in the
                    game and what their intentions could be.<br /><br />
                    If the game contains in-game purchases or similar
                    functionalities, the rules regulating such in-game purchases
                    or similar functionalities may be found
                    <router-link
                        class="a-text"
                        exact
                        :to="{ name: pages.EULA_PURCHASES_PAGE_NAME }"
                        >here</router-link
                    >.<br /><br />
                    If the game or associated Services enables You to use game
                    workshop, the rules regulating the use of game workshop may
                    be found
                    <router-link
                        class="a-text"
                        exact
                        :to="{ name: pages.EULA_WORKSHOP_PAGE_NAME }"
                        >here</router-link
                    >.

                    <h1>Specifications for PlayStation®4</h1>
                    If the game or associated Services are used on PlayStation®4,
                    the specified text related to Sony Interactive Entertainment
                    America and Sony Interactive Entertainment Network Europe
                    Limited may be found
                    <router-link
                        class="a-text"
                        exact
                        :to="{ name: pages.EULA_PLAYSTATION_PAGE_NAME }"
                        >here</router-link
                    >.

                    <h1>Warranty, Liabilities and Termination</h1>
                    The game is provided 'as is', which means that We give You
                    absolutely no warranties of any kind and We expressly refuse
                    to take any liability for any damage which may result from
                    use or inability to use the game.<br /><br />
                    We hope to update and support the game for long time but You
                    are not entitled to any updates and We have no obligation to
                    develop and provide You with any updates.<br /><br />
                    You can only agree with the license if You have fully
                    understood it and if You are capable of accepting it.
                    Otherwise Your legal guardian (usually Your parent) may
                    agree on Your behalf.<br /><br />
                    We terminate the license if You are in breach of it. Then
                    You will no longer be allowed to download, use or play the
                    game.<br /><br />
                    You can terminate the license at any time, by uninstalling
                    the game from all Your devices and deleting all files of the
                    game. This will mean that You will also no longer be allowed
                    to play or use the game and We do not offer any refund. The
                    paragraph "Warranty, liabilities, termination and others”
                    remains in force even after the license is terminated.

                    <h1>More Information</h1>
                    As the world of laws and games may change quickly, We
                    reserve the right to make changes to the license from time
                    to time. In such case, You may need to accept a new license
                    in order to use any future updates of the game or use any
                    online Services associated with the game.<br /><br />
                    All parts of the license are valid to the maximum extent
                    permitted by law and nothing in it limits any of Your rights
                    set by the applicable law. The license is governed by the
                    law of the Czech Republic, and any dispute arising here
                    under shall be resolved by Czech courts.<br /><br />
                    While this license fully represents rules and Your rights to
                    the game, You may find more details and explanation of what
                    We consider acceptable and what We do not at
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        class="a-text"
                        href="https://www.bistudio.com/community/game-content-usage-rules"
                    >
                        https://www.bistudio.com/community/game-content-usage-rules </a
                    >. We take Your privacy seriously and this is the policy in
                    place
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        class="a-text"
                        href="https://www.bohemia.net/privacy-policy"
                    >
                        https://www.bohemia.net/privacy-policy
                    </a>
                    to protect it. Your use of our online Services is subject to
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        class="a-text"
                        href="https://account.bistudio.com/terms-and-conditions"
                    >
                        https://account.bistudio.com/terms-and-conditions </a
                    >.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import ImageOptimized from '@/components/partials/ImageOptimized';
import { mapGetters } from 'vuex';

export default {
    name: 'Eula',
    components: { ImageOptimized },
    data() {
        return {
            pages,
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/bg-eula.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

#eula {
    #eula-content {
        padding-bottom: 100px;
        .section-content {
            padding-top: 192px;
        }
    }
}
</style>
