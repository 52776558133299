import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import {
    Home,
    About,
    Partnership,
    Partners,
    Updates,
    UpdatesDetail,
    News,
    NewsDetail,
    Presskit,
    FAQ,
    Contact,
    PromotionDetail,
    PromotionOtherDetail,
    Eula,
    EulaWorkshop,
    EulaPurchases,
    EulaPlaystation,
    NotFound,
    ChangelogDetail,
    NintendoSwitch,
    Anniversary,
    Community,
} from '../components/pages';

import {
    HOME_PAGE_NAME,
    STRESS_TEST_CANCEL_PAGE_NAME,
    ABOUT_PAGE_NAME,
    PARTNERSHIP_PAGE_NAME,
    PARTNERS_PAGE_NAME,
    UPDATES_PAGE_NAME,
    UPDATES_DETAIL_PAGE_NAME,
    NEWS_PAGE_NAME,
    NEWS_DETAIL_PAGE_NAME,
    NEWS_DETAIL_FIRST_PAGE_NAME,
    PRESSKIT_PAGE_NAME,
    // PRESSKIT_DETAIL_PAGE_NAME,
    FAQ_PAGE_NAME,
    CONTACT_PAGE_NAME,
    EULA_PAGE_NAME,
    EULA_WORKSHOP_PAGE_NAME,
    EULA_PURCHASES_PAGE_NAME,
    EULA_PLAYSTATION_PAGE_NAME,
    NOT_FOUND_PAGE_NAME,
    NEWSLETTER_PAGE_NAME,
    NEWSLETTER_CANCEL_PAGE_NAME,
    PROMO_DETAIL_PAGE_NAME,
    PROMO_OTHER_DETAIL_PAGE_NAME,
    CHANGELOG_DETAIL_PAGE_NAME,
    NINTENDOSWITCH_PAGE_NAME,
    ANNIVERSARY_PAGE_NAME,
    COMMUNITY,
} from './pageCodes';

export const baseTitle = 'Vigor';
export const baseTitleBI = " | Free-To-Play Shoot 'n' Loot Game";

const routes = [
    {
        path: '/',
        name: HOME_PAGE_NAME,
        component: Home,
        meta: { title: baseTitle + baseTitleBI },
    },
    {
        path: '/closedPreviewCancel',
        name: STRESS_TEST_CANCEL_PAGE_NAME,
        component: Home,
        meta: { title: baseTitle + baseTitleBI },
    },
    {
        path: '/newsletter',
        name: NEWSLETTER_PAGE_NAME,
        component: Home,
        meta: { title: baseTitle + baseTitleBI },
    },
    {
        path: '/newsletterCancel',
        name: NEWSLETTER_CANCEL_PAGE_NAME,
        component: Home,
        meta: { title: baseTitle + baseTitleBI },
    },
    {
        path: '/about',
        name: ABOUT_PAGE_NAME,
        component: About,
        meta: { title: 'About | ' + baseTitle },
    },
    {
        path: '/partnership',
        name: PARTNERSHIP_PAGE_NAME,
        component: Partnership,
        meta: { title: 'Partnership | ' + baseTitle },
    },
    // {
    //     path: '/switch',
    //     name: NINTENDOSWITCH_PAGE_NAME,
    //     component: NintendoSwitch,
    //     meta: { title: 'Nintendo Switch | ' + baseTitle },
    // },
    {
        path: '/anniversary',
        name: ANNIVERSARY_PAGE_NAME,
        component: Anniversary,
        meta: { title: 'Anniversary | ' + baseTitle },
    },
    {
        path: '/partners',
        name: PARTNERS_PAGE_NAME,
        component: Partners,
        meta: { title: 'Partners | ' + baseTitle },
    },
    {
        path: '/updates',
        name: UPDATES_PAGE_NAME,
        component: Updates,
        meta: { title: 'Updates | ' + baseTitle },
    },
    {
        path: '/updates/:slug',
        name: UPDATES_DETAIL_PAGE_NAME,
        exact: false,
        component: UpdatesDetail,
        meta: { title: 'Updates | ' + baseTitle },
    },
    {
        path: '/news',
        name: NEWS_PAGE_NAME,
        component: News,
        exact: true,
        meta: { title: 'News | ' + baseTitle },
    },
    {
        path: '/news/:slug',
        name: NEWS_DETAIL_PAGE_NAME,
        component: NewsDetail,
        exact: false,
        meta: { title: 'News | ' + baseTitle },
    },
    {
        path: '/changelog/:slug',
        name: CHANGELOG_DETAIL_PAGE_NAME,
        component: ChangelogDetail,
        exact: false,
        meta: { title: 'Changelog | ' + baseTitle },
    },
    {
        //this is here just so that the deprecated routing method works for an already aired old url
        path: '/firstLook',
        name: NEWS_DETAIL_FIRST_PAGE_NAME,
        component: NewsDetail,
        exact: true,
        meta: { title: 'News | ' + baseTitle },
    },
    {
        path: '/press',
        name: PRESSKIT_PAGE_NAME,
        component: Presskit,
        meta: { title: 'Press Kit | ' + baseTitle },
    },
    // {
    //     path: '/press/:slug',
    //     name: PRESSKIT_DETAIL_PAGE_NAME,
    //     component: PresskitDetail,
    //     exact: false,
    //     meta: { title: 'Press Kit | ' + baseTitle },
    // },
    // {
    //     path: '/press-:slug',
    //     name: PRESSKIT_DETAIL_PAGE_NAME + 'old',
    //     component: PresskitDetail,
    //     exact: false,
    //     meta: { title: 'Press Kit | ' + baseTitle },
    // },
    {
        path: '/faq',
        name: FAQ_PAGE_NAME,
        component: FAQ,
        meta: { title: 'FAQ | ' + baseTitle },
    },
    {
        path: '/contact',
        name: CONTACT_PAGE_NAME,
        component: Contact,
        meta: { title: 'Contact | ' + baseTitle },
    },
    {
        path: '/community',
        name: COMMUNITY,
        component: Community,
        meta: { title: 'Community | ' + baseTitle },
    },
    {
        path: '/promo-partner/:slug',
        name: PROMO_DETAIL_PAGE_NAME,
        component: PromotionDetail,
        exact: false,
        meta: { title: 'Promotion | ' + baseTitle },
    },
    {
        path: '/promo/:slug',
        name: PROMO_OTHER_DETAIL_PAGE_NAME,
        component: PromotionOtherDetail,
        exact: false,
        meta: { title: 'Promotion | ' + baseTitle },
    },
    {
        path: '/eula',
        name: EULA_PAGE_NAME,
        component: Eula,
        meta: { title: 'Eula | ' + baseTitle },
    },
    {
        path: '/eula-workshop',
        name: EULA_WORKSHOP_PAGE_NAME,
        component: EulaWorkshop,
        meta: { title: 'Eula | ' + baseTitle },
    },
    {
        path: '/eula-purchases',
        name: EULA_PURCHASES_PAGE_NAME,
        component: EulaPurchases,
        meta: { title: 'Eula | ' + baseTitle },
    },
    {
        path: '/eula-playstation',
        name: EULA_PLAYSTATION_PAGE_NAME,
        component: EulaPlaystation,
        meta: { title: 'Playstation Eula | ' + baseTitle },
    },
    {
        path: '/404',
        name: NOT_FOUND_PAGE_NAME,
        component: NotFound,
        meta: { title: '404 | ' + baseTitle },
    },
    {
        path: '*',
        redirect: { name: NOT_FOUND_PAGE_NAME },
    },
];

const scrollBehavior = (to, from, savedPosition) => {
    if (to.name !== from.name) {
        return { x: 0, y: 0 };
    }
    if (savedPosition) {
        return { x: 0, y: 0 };
    }
};

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
});

export default router;
