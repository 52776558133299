import Vue from './../init';

const uri = 'v1.0/codes';

var customActions = {
    request: { method: 'POST', url: uri },
    check: { method: 'POST', url: uri + '/check' },
};

export const CodeResource = Vue.resource(uri, {}, customActions);
