<template>
    <div id="about" class="basic-page">
        <div>
            <div class="loop-trailer">
                <video-background
                    class="video "
                    src="static/videos/vigor-first.mp4"
                >
                </video-background>
            </div>
            <div class="loop">
                <video-background
                    class="video "
                    src="static/videos/vigor-short-loop.mp4"
                >
                    <div class="video__overlay">
                        <div class="video__text " style="padding-top: 10px ;">
                            <div class="video__header__title ">About Vigor</div>
                            <div
                                style="margin-top: 10px; margin-bottom: 10px; "
                                class="header__text"
                            >
                                Survive the harsh conditions of post-war Norway
                                and outlive the apocalypse in this multiplayer
                                looter shooter.
                            </div>
                            <div style="padding-bottom: 40px;">
                                <div style="margin-top: 40px;">
                                    <a
                                        href="https://store.steampowered.com/app/2818260/Vigor/"
                                    >
                                        <LinkButton
                                            id="show-modal"
                                            text="PLAY FREE NOW"
                                            type="red  centered "
                                            :event="true"
                                        />
                                    </a>
                                </div>

                                <div
                                    class="icon-links"
                                    style="margin-top: 20px;"
                                >
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.microsoft.com/store/productid/bql8l17rv09z?cid=vigor-landing-page"
                                        class="logo-link no-underline"
                                    >
                                        <XbIcon class="tag-icon" />
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.nintendo.com/games/detail/vigor-switch/?utm_source=vigorgame.com&utm_medium=website&utm_campaign=vigor-landing-page"
                                        class="logo-link no-underline"
                                    >
                                        <NintendoIcon class="tag-icon" />
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://store.playstation.com/product/EP2601-CUSA13066_00-VIGORLEADSKU0001?utm_source=vigorgame.com&utm_medium=website&utm_campaign=vigor-landing-page"
                                        class="logo-link no-underline"
                                    >
                                        <PlaystationIcon class="tag-icon" />
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://store.steampowered.com/app/2818260/Vigor/"
                                        class="logo-link no-underline"
                                    >
                                        <SteamLogoIcon
                                            class="tag-icon"
                                            style="width: 35px;"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </video-background>
            </div>

            <div id="description-right">
                <div class="description__image-wrapper">
                    <img
                        class="image__overlay"
                        style="width: 100%; height: auto; "
                        src="static/images/about/nordic.jpg"
                        alt=""
                    />
                </div>
                <div ref="description-right" class="description__content ">
                    <h1 class="header__title" style="width: 100%;">
                        LOOT, SHOOT
                    </h1>
                    <div class="description__text">
                        Explore the Nordic scenery in Encounters, and choose
                        your playstyle - sneaky looter or deadly shooter.
                    </div>
                    <div class="description__buttons">
                        <LinkButton
                            icon="play-icon light"
                            class="button"
                            text="see the trailer"
                            type="light red alignLeft "
                            :link="
                                'https://www.youtube.com/watch?v=XeawFcFjpLM&ab_channel=Vigor'
                            "
                            :outside="true"
                        />
                    </div>
                </div>
            </div>
            <div id="description-left">
                <div class="description__image-wrapper">
                    <img
                        class="image__overlay"
                        style="width: 100%;height: auto; "
                        src="static/images/about/build.jpg"
                        alt=""
                    />
                </div>
                <div ref="description-left" class="description__content ">
                    <h1 class="header__title" style="width: 100%;">
                        BUILD
                    </h1>
                    <div class="description__text">
                        Upgrade your Shelter from resources acquired in
                        Encounters and unlock extra features
                    </div>
                </div>
            </div>
            <div id="description-right">
                <div class="description__image-wrapper">
                    <img
                        class="image__overlay"
                        style="width: 100%;height: auto; "
                        src="static/images/about/shootout.jpg"
                        alt=""
                    />
                </div>
                <div ref="description-right" class="description__content ">
                    <h1 class="header__title" style="width: 100%;">
                        Game Modes
                    </h1>
                    <div class="description__text">
                        Play Shootout, a fast-paced solo combat, or Elimination,
                        a 5v5 tactical, round based team fight, to challenge
                        other Outlanders.
                    </div>
                </div>
            </div>
            <!-- <div id="header">
                <div class="header__image-wrapper">
                    <ImageOptimized
                        code="about/about-last-sect"
                        thumb-formats="webp,jpg"
                        thumb-sizes="480,768,992,1200"
                        type="aboutpage"
                        @loaded="loaded.aboutBg = true"
                        @failed="loaded.aboutBg = true"
                    />
                </div>
                <div ref="header" class="header__content last-section">
                    <div class="header__title">
                        CHRONICLES:<br />
                        PERSEVERANCE
                    </div>
                    <div class="header__text">
                        Unfold the Chronicles and show <br />
                        perseverance in adversity! <br />
                        Listen to the tale of Freyja <br />
                        and dive deep into her search <br />
                        for a new home.<br />
                    </div>
                    <div class="button-group">
                        <LinkButton
                            class="button"
                            text="Read what's new"
                            type="red alignLeft"
                            :link="'https://vigorgame.com/updates/110'"
                            :outside="true"
                        />
                    </div>
                </div>
            </div> -->
        </div>

        <transition
            v-if="showModal"
            id="modal-template"
            name="modal"
            @close="showModal = false"
        >
            <div id="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div
                            class="modal-default-button"
                            @click="toggleModal()"
                        ></div>
                        <div class="modal-header">
                            <div class="modal-text">Play for free!</div>
                        </div>

                        <div class="modal-body">
                            <div name="body" class="button-group">
                                <div class="icon-links modal-links">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.microsoft.com/store/productid/bql8l17rv09z?cid=vigor-landing-page"
                                        class="logo-link modal-link no-underline one"
                                    >
                                        <XbCircle class="circle modal-circle" />
                                        <XbIcon class="tag-icon modal-icon" />
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.nintendo.com/games/detail/vigor-switch/?utm_source=vigorgame.com&utm_medium=website&utm_campaign=vigor-landing-page"
                                        class="logo-link modal-link no-underline two"
                                    >
                                        <NintendoCircle
                                            class="circle modal-circle"
                                        />
                                        <NintendoIcon
                                            class="tag-icon modal-icon"
                                        />
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://store.playstation.com/product/EP2601-CUSA13066_00-VIGORLEADSKU0001?utm_source=vigorgame.com&utm_medium=website&utm_campaign=vigor-landing-page"
                                        class="logo-link modal-link no-underline three"
                                    >
                                        <XbCircle class="circle modal-circle" />
                                        <PlaystationIcon
                                            class="tag-icon modal-icon"
                                        />
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://store.steampowered.com/app/2818260/Vigor/"
                                        class="logo-link modal-link no-underline four"
                                    >
                                        <XbCircle class="circle modal-circle" />
                                        <SteamLogoIcon
                                            class="tag-icon modal-icon"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import LinkButton from '@/components/partials/LinkButton';
import Loading from '@/components/partials/Loading';
import { mapGetters } from 'vuex';
import ImageOptimized from '@/components/partials/ImageOptimized';
import NintendoCircle from 'Images/about/circle_nintendo.svg?inline';
import XbCircle from 'Images/about/circle_xb.svg?inline';
import XbIcon from 'Images/about/xb.svg?inline';
import PlaystationIcon from 'Images/about/playstation.svg?inline';
import NintendoIcon from 'Images/about/nintendo.svg?inline';
import SteamLogoIcon from 'Images/footer/steam-logo-wo-bg.svg?inline';
import { event } from 'vue-analytics';

export default {
    name: 'About',
    components: {
        LinkButton,
        Loading,
        ImageOptimized,
        NintendoCircle,
        XbCircle,
        NintendoIcon,
        XbIcon,
        PlaystationIcon,
        SteamLogoIcon,
    },
    data() {
        return {
            showModal: false,
            pages,
            name: 'About',
            window: { w: window.innerWidth, h: window.innerHeight },
            loaded: {
                headerBg: false,
                descriptionBg: false,
            },
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'getUser',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        imgsLoaded() {
            return this.loaded.headerBg && this.loaded.descriptionBg;
        },
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);
        document.addEventListener('click', () => this.close(event));
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        close(e) {
            let ignoreClickOnMeElement = document.getElementById('modal-mask');
            let isClickInsideElement = ignoreClickOnMeElement.contains(
                e.target
            );
            let buttonOne = ignoreClickOnMeElement.querySelector('.one');
            let buttonTwo = ignoreClickOnMeElement.querySelector('.two');
            let buttonThree = ignoreClickOnMeElement.querySelector('.three');
            let buttonFour = ignoreClickOnMeElement.querySelector('.four');
            let isClickInsideButtonOne = buttonOne.contains(e.target);
            let isClickInsideButtonTwo = buttonTwo.contains(e.target);
            let isClickInsideButtonThree = buttonThree.contains(e.target);
            let isClickInsideButtonFour = buttonFour.contains(e.target);
            let close = ignoreClickOnMeElement.querySelector(
                '.modal-default-button'
            );
            let isClickInsideClose = close.contains(e.target);

            if (
                isClickInsideElement &&
                !isClickInsideButtonOne &&
                !isClickInsideButtonTwo &&
                !isClickInsideButtonThree &&
                !isClickInsideButtonFour
            ) {
                this.toggleModal();
            }
            if (isClickInsideClose) {
                this.toggleModal();
            }
        },
        toggleModal() {
            this.showModal = !this.showModal;
        },
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-color: black;
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 1000px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

.video__overlay {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        to top,
        #000000 0%,
        rgba(0, 0, 0, 0) 100%
    );
}
.loop-trailer {
    min-height: 75vh;
    @media (max-width: 1450px) {
        min-height: 90vh;
    }
    @media (min-width: 1450px) {
        min-height: 80vh;
        .video {
            min-height: 90vh;
        }
    }
    @media (min-width: 1920px) {
        min-height: 80vh;
    }
    @media (min-width: 3300px) {
        min-height: 40vh;
    }
    @media (max-width: 992px) {
        min-height: 70vh;
    }
    @media (max-width: 550px) {
        min-height: 40vh;
    }
}

.loop {
    min-height: 75vh;
    @media (max-width: 1450px) {
        min-height: 90vh;
    }
    @media (min-width: 1450px) {
        min-height: 70vh;
    }
    @media (min-width: 1920px) {
        min-height: 80vh;
    }
    @media (min-width: 3300px) {
        min-height: 40vh;
    }
    @media (max-width: 992px) {
        min-height: 70vh;
    }
    @media (max-width: 550px) {
        min-height: 40vh;
    }
    .video {
        min-height: 90vh;
    }
}

.video {
    min-height: 75vh;
    max-width: none;
    z-index: 0;
    max-height: 756px;
    text-align: center;
    .video__text {
        .video__header__title {
            padding-top: 20px;

            font-size: 72px;
            font-weight: 700;
            text-transform: uppercase;

            @media (max-width: 992px) {
                font-size: 42px;
            }
        }
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $light;
        font-size: 36px;
        font-weight: 400;
        text-align: center;
        @media (max-width: 992px) {
            font-size: 24px;
        }
        @media (max-width: 768px) {
            font-size: 18px;
        }
    }
    @media (max-width: 1450px) {
        min-height: 90vh;
    }
    @media (min-width: 1920px) {
        min-height: 70vh;
    }
    @media (min-width: 3300px) {
        min-height: 40vh;
    }
    @media (max-width: 992px) {
        min-height: 70vh;
    }
    @media (max-width: 550px) {
        min-height: 40vh;
    }
}

.icon-links {
    display: flex;
    justify-content: center;
    margin: auto;

    @media (max-width: 768px) {
        width: 255px;
    }

    @media (max-width: 400px) {
        width: 200px;
    }
}

.logo-link:hover > svg:first-child path {
    fill: $red-darker;
}

.logo-link {
    display: inline-block;
    position: relative;
    width: 77px;
    height: 77px;
    cursor: pointer;
    // @media (max-width: 768px) {
    //     width: 75.99px;
    // }

    // @media (max-width: 400px) {
    //     width: 60px;
    //     height: 60px;
    // }

    .tag-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    .circle {
        width: 100%;
        height: 100%;
        display: inline-block;
    }
}

.play__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    background-color: $red;
    color: $light;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background-color: $red-darker;
    }
}

.image__overlay {
    background-image: linear-gradient(
        to top,
        #000000 0%,
        rgba(0, 0, 0, 0) 100%
    );
}

#about {
    color: $light;
    .first-sect-about-header {
        padding-top: 48px;
    }
    #header {
        position: relative;

        @media (max-width: 992px) {
            padding-bottom: 200px;
        }
        @media (max-width: 768px) {
            padding-bottom: 300px;
        }
        @media (max-width: 480px) {
            padding-bottom: 400px;
        }

        .header__image-wrapper {
            position: relative;
            display: block;
            @media (max-width: 992px) {
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -1px; //weird bug with transparent line
                    background-image: linear-gradient(
                        to top,
                        #000000 0%,
                        rgba(0, 0, 0, 0) 100%
                    );
                    width: 100%;
                    height: 50%;
                }
            }
        }

        .header__content {
            position: absolute;
            left: 15%;
            padding-top: 135px;
            z-index: 5;

            @media (min-width: 993px) {
                top: 0;
            }
            @media (max-width: 992px) {
                left: 10%;
                bottom: 15%;
            }

            .header__title {
                font-size: 72px;
                font-weight: 700;
                text-transform: uppercase;

                @media (max-width: 992px) {
                    font-size: 42px;
                }
            }
            .header__text {
                font-size: 36px;
                font-weight: 300;
                margin: 11% 0 24px 0;

                @media (max-width: 1200px) {
                    margin-top: 15%;
                    font-size: 24px;
                }
                @media (max-width: 768px) {
                    margin: 24px 0 40px 0;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }

        .button-group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 40px;

            .button {
                padding-bottom: 40px;
                &.fullWidth {
                    width: 100%;
                }
            }
        }

        @media (min-width: 992px) {
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                background-image: linear-gradient(
                    to right,
                    #000000 0%,
                    rgba(0, 0, 0, 0) 100%
                );
                width: 75%;
                height: 100%;
            }
        }
    }

    .header__title {
        font-size: 72px;
        font-weight: 700;
        text-transform: uppercase;
        max-width: 500px;

        @media (max-width: 992px) {
            font-size: 42px;
            max-width: 300px;
        }
    }
    #description-left {
        z-index: 0;
        position: relative;

        @media (max-width: 992px) {
            padding-bottom: 100px;
        }

        @media (max-width: 768px) {
            padding-bottom: 200px;
        }
        @media (max-width: 480px) {
            padding-bottom: 300px;
        }

        .description__image-wrapper {
            position: relative;

            @media (max-width: 992px) {
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -1px; //weird bug with transparent line
                    background-image: linear-gradient(
                        to top,
                        #000000 0%,
                        rgba(0, 0, 0, 0) 100%
                    );
                    width: 100%;
                    height: 50%;
                }
            }
        }
        ul {
            list-style: none;
            padding-left: 0;

            li {
                display: flex;
                align-items: center;
            }

            li:before {
                content: '';
                background: url('/static/images/presskit/bullet.svg?inline');
                position: relative;
                margin: auto 0;
                height: 12px;
                width: 12px;
                display: inline-block;
                margin-right: 14px;

                @media (max-width: 400px) {
                    height: 8px;
                    width: 8px;
                    margin-right: 9px;
                }
            }
        }
        .description__content {
            position: absolute;
            bottom: 30%;
            left: 10%;
            max-width: 655px;
            z-index: 5;
            padding-top: 135px;

            @media (min-width: 993px) {
                top: 0;
            }
            @media (max-width: 992px) {
                right: 0;
                left: 2.5%;
                bottom: 15%;
            }

            .description__text {
                margin: 11% 0 24px 0;
                font-size: 36px;
                font-weight: 100;
                @media (max-width: 1200px) {
                    margin-top: 10%;
                    font-size: 24px;
                }
                @media (max-width: 768px) {
                    margin: 24px 0 40px 0;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }

        .description__content {
            position: absolute;
            bottom: 30%;
            left: 10%;
            max-width: 655px;
            z-index: 5;
            padding-top: 135px;

            @media (min-width: 993px) {
                top: 0;
            }
            @media (max-width: 992px) {
                right: 0;
                left: 2.5%;
                bottom: 15%;
            }

            .description__text {
                margin: 11% 0 24px 0;
                font-size: 36px;
                font-weight: 100;
                @media (max-width: 1200px) {
                    margin-top: 10%;
                    font-size: 24px;
                }
                @media (max-width: 768px) {
                    margin: 24px 0 40px 0;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }

        @media (min-width: 992px) {
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                background-image: linear-gradient(
                    to right,
                    #000000 0%,
                    rgba(0, 0, 0, 0) 100%
                );
                width: 75%;
                height: 100%;
            }
        }
    }

    #description-right {
        z-index: 0;
        position: relative;

        @media (max-width: 992px) {
            padding-bottom: 100px;
        }

        @media (max-width: 768px) {
            padding-bottom: 200px;
        }
        @media (max-width: 480px) {
            padding-bottom: 300px;
        }

        .description__image-wrapper {
            position: relative;

            @media (max-width: 992px) {
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -1px; //weird bug with transparent line
                    background-image: linear-gradient(
                        to top,
                        #000000 0%,
                        rgba(0, 0, 0, 0) 100%
                    );
                    width: 100%;
                    height: 50%;
                }
            }
        }
        ul {
            list-style: none;
            padding-left: 0;

            li {
                display: flex;
                align-items: center;
            }

            li:before {
                content: '';
                background: url('/static/images/presskit/bullet.svg?inline');
                position: relative;
                margin: auto 0;
                height: 12px;
                width: 12px;
                display: inline-block;
                margin-right: 14px;

                @media (max-width: 400px) {
                    height: 8px;
                    width: 8px;
                    margin-right: 9px;
                }
            }
        }
        .description__content {
            position: absolute;
            bottom: 30%;
            right: 10%;
            max-width: 655px;
            z-index: 5;
            padding-top: 135px;

            @media (min-width: 993px) {
                top: 0;
            }
            @media (max-width: 992px) {
                right: 0;
                left: 2.5%;
                bottom: 15%;
            }

            .description__text {
                margin: 11% 0 24px 0;
                font-size: 36px;
                font-weight: 100;
                @media (max-width: 1200px) {
                    margin-top: 10%;
                    font-size: 24px;
                }
                @media (max-width: 768px) {
                    margin: 24px 0 40px 0;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }

        .description__content {
            position: absolute;
            bottom: 30%;
            right: 10%;
            max-width: 655px;
            z-index: 5;
            padding-top: 135px;

            @media (min-width: 993px) {
                top: 0;
            }
            @media (max-width: 992px) {
                right: 0;
                left: 2.5%;
                bottom: 15%;
            }

            .description__text {
                margin: 11% 0 24px 0;
                font-size: 36px;
                font-weight: 100;
                @media (max-width: 1200px) {
                    margin-top: 10%;
                    font-size: 24px;
                }
                @media (max-width: 768px) {
                    margin: 24px 0 40px 0;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }

        @media (min-width: 992px) {
            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                background-image: linear-gradient(
                    to left,
                    #000000 0%,
                    rgba(0, 0, 0, 0) 100%
                );
                width: 75%;
                height: 100%;
            }
        }
    }
}
#modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    padding-right: 0;
}

.modal-default-button {
    position: relative;
    float: right;
    cursor: pointer;

    &:hover {
        &:before {
            opacity: 0.7;
        }
    }
}

.modal-default-button:before {
    content: 'x';
    position: absolute;
    font-size: 30px;
    font-weight: 700;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 1920px) {
        font-size: 50px;
    }

    @media (max-width: 400px) {
        font-size: 22px;
        right: -70px;
    }
}

.modal-text {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    margin: auto;
    @media (min-width: 1920px) {
        font-size: 50px;
    }
    @media (max-width: 1200px) {
        font-size: 24px;
    }
    @media (max-width: 768px) {
        font-size: 18px;
    }
}
.one {
    display: block;
    margin-bottom: 10px;
}

.two {
    display: block;
    margin-bottom: 10px;
}

.modal-body {
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: center;

    @media (max-width: 400px) {
        width: 80%;
    }
}
.modal-links {
    margin-top: 10%;

    @media (min-width: 1920px) {
        width: unset;
    }

    .modal-link {
        width: 100px;
        height: 100px;
        display: block;
        position: relative;
        margin: 0 10px;
        @media (min-width: 1920px) {
            width: 130px;
            height: 130px;
        }
        @media (max-width: 400px) {
            width: 65px;
            height: 58px;
        }
    }

    // .modal-link:nth-child(5) {
    //     margin: 0 20px;

    //     @media (max-width: 400px) {
    //         margin: 0 15px;
    //     }
    // }
}

.modal-link:hover > svg:first-child path {
    fill: $red-darker;
}

.logo-link.modal-link:hover + svg {
    fill: $red-darker;
}
.modal-container {
    width: 580px;
    margin: 0px auto;
    padding: 20px 30px;
    border-radius: 2px;
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;

    @media (min-width: 1920px) {
        width: 680px;
    }
    @media (max-width: 1440px) {
        width: 480px;
    }
    @media (max-width: 1024px) {
        width: 320px;
    }
    @media (max-width: 540px) {
        width: 320px;
        padding: 5px 10px;
    }
}
</style>
