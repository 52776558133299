<template>
    <div class="global-style">
        <div id="changelog-detail" class="basic-page">
            <Loading :show="!dataLoaded" />
            <div v-show="dataLoaded">
                <div
                    class="static-bg"
                    :class="{ hidden: settings && settings.apple }"
                >
                    <ImageOptimized
                        code="placeholder/news/bg-news"
                        thumb-formats="webp,jpg"
                        thumb-sizes="480,768,992,1200"
                        type="static-bg"
                        @loaded="loaded.staticBg = true"
                        @failed="loaded.staticBg = true"
                    />
                </div>
                <div
                    v-if="changelog"
                    id="changelog-detail-content"
                    ref="content"
                    class="section"
                >
                    <div class="section-content">
                        <div class="scrollable-content">
                            <router-link
                                class="a-text no-underline"
                                exact
                                :to="{ name: pages.UPDATES_PAGE_NAME }"
                            >
                                <div class="back"><< BACK TO UPDATES</div>
                            </router-link>
                            <div class="event-title">{{ changelog.name }}</div>
                            <div class="event-date">
                                {{
                                    dateInArticleFormat(changelog.published_at)
                                }}
                            </div>

                          
                            <img
                                class="title-image"
                                :src="changelog.titleImage"
                                draggable="false"
                            />
                              <div class="event-article-content">
                            <div class="event-text">
                                <v-runtime-template
                                    :template="
                                        `<div>${processText(
                                            changelog.content
                                        )}</div>`
                                    "
                                />
                            </div>
                            <img
                                v-if="changelog.partingImage"
                                class="postcard"
                                :src="changelog.partingImage"
                                draggable="false"
                            />
                            <ImageOptimized
                                v-else
                                code="news/postcard"
                                thumb-formats="webp,png"
                                type="postcard"
                            />
                            </div>
                            <br />
                            <router-link
                                class="a-text no-underline"
                                exact
                                :to="{ name: pages.UPDATES_PAGE_NAME }"
                            >
                                <div class="back"><< BACK TO UPDATES</div>
                            </router-link>

              
                        </div>
                        <Sidebar
                            v-if="changelogs.rows"
                            :class="{ webp: settings.webpSupport }"
                            :header-text="'RECENT UPDATES'"
                            :entries="changelogs.rows"
                            :link-name="pages.CHANGELOG_PAGE_NAME"
                            :show-tags="false"
                        />
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import router from '@/routes';
import { baseTitle } from '@/routes/index';
import Loading from '@/components/partials/Loading';
import SocialLinks from '@/components/partials/SocialLinks';
import { mapGetters } from 'vuex';
import formats from '@/formats';
import { format, parseISO } from 'date-fns';
import ImageOptimized from '@/components/partials/ImageOptimized';
import Sidebar from '@/components/partials/Sidebar';

import VRuntimeTemplate from 'v-runtime-template';
import AppYoutube from '@/components/common/widgets/AppYoutube';
import AppTweet from '@/components/common/widgets/AppTweet';
import AppPicture from '@/components/common/widgets/AppPicture';

export default {
    name: 'ChangelogDetail',
    components: {
        Loading,
        SocialLinks,
        ImageOptimized,
        AppYoutube,
        AppTweet,
        AppPicture,
        VRuntimeTemplate,
        Sidebar
    },
    data() {
        return {
            pages,
            changelog: null,
            shownEntries: 6,
            loaded: {
                staticBg: false,
            },
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        dataLoaded() {
            return this.loaded.staticBg && this.changelog;
        },
        slug() {
            if (this.$route.fullPath == '/firstLook') return 'first-look';
            //this is here just so that the deprecated routing method works for an already aired old url
            return this.$route.params.slug;
        },
        ...mapGetters('changelogs', {
            changelogs: 'getChangelogs',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            if (!this.changelog) this.findChangelog();
            this.handleBg();
        }, 500);
    },
    updated() {
        if (this.changelogs && !this.changelog) this.findChangelog();

        if (!this.changelog) return;

        if (this.changelog.name.length <= 20) {
            document.title =
                this.changelog.name + ' | Changelog | ' + baseTitle;
        } else {
            document.title =
                this.changelog.name.substring(0, 16) +
                '... | Changelog | ' +
                baseTitle;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        dateInArticleFormat(date) {
            return format(parseISO(date), formats.ARTICLE_FORMAT);
        },
        findChangelog() {
            let slug = this.slug;
            this.changelog = this.changelogs.rows.find(function(changelog) {
                return changelog.slug === slug;
            });
            if (!this.changelog) {
                router.push({ name: pages.NOT_FOUND_PAGE_NAME });
            }
        },
        processText(text) {
            return text
                .replace(/<a /g, '<a class="a-text" ')
                .replace(/<li>/g, '<div class="content-item">')
                .replace(/<\/li>/g, '</div>')
                .replace(/<ul>/g, '')
                .replace(/<\/ul>/g, '');
        },
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/updates/bg-updates.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

#changelog-detail {
    #changelog-detail-content {
        .section-content {
            display: flex;
            padding: 125px 35px 150px;
            justify-content: center;

            .news-list{
                @media (max-width: 1200px) {
                margin-top: -150px;
                margin-bottom: 50px;
               }
            }
 
            .scrollable-content {
                overflow: auto;
                display: block;
                padding-bottom: 50px;
                max-width: 889px;
                width: 100%;
                margin-right: 35px;

                @media (max-width: 768px) {
                  margin-right: 0;
               }
                .event-article-content{
                    background-color: #252525;
                    padding: 50px;
                }

                .event-text {
                    display: block;

                    /deep/ .content-item {
                        line-height: 25px;

                        .content-item {
                            margin-left: 20px;
                        }

                        p {
                            margin: 0;
                            display: inline;
                        }
                    }

                    /deep/ .content-item::before {
                        content: '';
                        background: url('/static/images/presskit/bullet.svg');
                        height: 12px;
                        width: 12px;
                        margin-right: 14px;
                        display: inline-block;
                    }
                }

                .event-title {
                    font-size: 48px;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 0.72px;
                }

                .event-date {
                    margin: 15px 0;             
                    color: $red;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                    margin: 15px 0 5px 0;
                
                }

         
            }

            .back {
                display: block;
            }

            @media (max-width: 1200px) {
               flex-direction: column;
               justify-content: center;
               align-items: center;
            }
        }
    }

    .event-date {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.12px;
        text-transform: none;
    }


    @media (max-width: 1200px) {
        #changelog-detail-content {
            .section-content {
                .scrollable-content {
                    width: 80vw;
                }
            }
        }
    }

    @media (min-width: 1920px) {
        #changelog-detail-content {
            width: 1920px;
            .section-content {
                .scrollable-content {
                    width: 884px;
                }
            }
        }

        .news-list {
            width: 400px;
        }
    }
}

.global-style /deep/ a {
    &.a-text {
        color: $light;
        font-weight: 600;
        display: inline-block;
        border-bottom: 1px solid $light;
        margin: 0;

        &:hover {
            color: $red-lighter;
        }

        &.no-underline {
            border: none;
        }

        &.dark {
            color: $dark;
            border-bottom: 1px solid $dark;

            &:hover {
                color: $red-lighter;
            }
        }
    }

    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
        span,
        span:hover {
            border: inherit;
        }
    }
}

.global-style /deep/ .basic-page,
.global-style /deep/ .basic-content {
    width: 100vw;
    max-width: 1920px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: auto;
    cursor: default;
    word-break: break-word;
    display: block;
    position: relative;
    min-height: 100vh;

    .title-image,
    .basic-image {
        width: 100%;
        height: auto;
    }

    .basic-image-description {
        text-align: center;
        font-style: italic;
        color: $light;
    }

    .postcard {
        width: 450px;
        max-width: 100%;
    }

    h2 {
        color: $red;
        margin-bottom: 10px;

        &.capitals {
            text-transform: uppercase;
        }

        &.light {
            color: $light;
        }
    }

    .strong-title {
        display: block;
        font-weight: 600;
    }

    .basic-page-section {
        margin-bottom: 40px;
    }
}
</style>
