<template>
    <div id="eula-purchases" class="basic-page">
        <div class="static-bg" :class="{ hidden: settings && settings.apple }">
            <ImageOptimized
                code="bg-eula"
                thumb-formats="webp,jpg"
                thumb-sizes="768,992,1200"
                type="static-bg"
            />
        </div>
        <div id="eula-purchases-content" class="section">
            <div class="section-content">
                <div class="upper-title">END-USER LICENSE AGREEMENT</div>
                <div class="page-title">Specifications for PlayStation®4</div>
                <div class="text">
                    <h1>English</h1>
                    <ul>
                        <li>
                            Purchase and use of items are subject to the Network
                            Terms of Service andUser Agreement. This online
                            service has been sublicensed to you by Sony
                            Interactive Entertainment America.
                        </li>
                        <li>
                            Any content purchased in an in-game store will be
                            purchased from Sony Interactive Entertainment
                            Network Europe Limited (“SIENE”) and be subject to
                            PlayStation™Network Terms of Service and User
                            Agreement which is available on the
                            PlayStation™Store. Please check usage rights for
                            each purchase as these may differ from item to item.
                            Unless otherwise shown, content available in any
                            in-game store has the same age rating as the game.
                        </li>
                    </ul>
                    <h1>Brazillian Portuguese</h1>
                    <ul>
                        <li>
                            A compra e o uso de itens estão sujeitos aos Termos
                            de serviço e Contrato do usuário da Network. Este
                            serviço online foi sublicenciado a você pela Sony
                            Interactive Entertainment America.
                        </li>
                    </ul>
                    <h1>French</h1>
                    <ul>
                        <li>
                            L'achat et l'utilisation des articles sont soumis
                            aux Conditions d'utilisation et à l'Accord
                            utilisateur de Network. Une sous-licence
                            d'utilisation de ce service en ligne vous a été
                            concédé par Sony Interactive Entertainment America.
                        </li>
                        <li>
                            Tout contenu acheté dans la boutique d'un jeu est
                            acheté à Sony Interactive Entertainment Network
                            Europe Limited ("SIENE") et est soumis aux
                            Conditions d'utilisation et Accord utilisateur de
                            PlayStation™Network disponibles sur
                            PlayStation™Store. Vérifiez les droits d’utilisation
                            lors de chaque achat car ceux-ci peuvent varier d’un
                            article à l’autre. Sauf indication contraire, la
                            classification du contenu disponible dans la
                            boutique d'un jeu est identique à celle du jeu
                            lui-même.
                        </li>
                    </ul>
                    <h1>Spanish</h1>
                    <ul>
                        <li>
                            La compra y uso de artículos están sujetos a los
                            Términos de Servicio y Acuerdo de Usuario de la
                            Network. Una sublicencia de este servicio online le
                            ha sido otorgada por Sony Interactive Entertainment
                            America.
                        </li>
                        <li>
                            Cualquier contenido adquirido en una tienda del
                            juego se le comprará a Sony Interactive
                            Entertainment Network Europe Limited (“SIENE”) y
                            estará sujeto a los Términos de servicio / Acuerdo
                            de usuario de PlayStation™Network que está
                            disponible en PlayStation™Store. Le rogamos que
                            compruebe los derechos de uso en cada compra, ya que
                            pueden variar según el producto. A menos que se
                            indique lo contrario, el contenido disponible en una
                            tienda del juego tiene la misma clasificación por
                            edades que el juego.
                        </li>
                    </ul>
                    <h1>Italian</h1>
                    <ul>
                        <li>
                            Tutti i contenuti acquistati in un negozio in-game
                            saranno acquistati da Sony Interactive Entertainment
                            Network Europe Limited ("SIENE") e saranno soggetti
                            ai Termini di servizio e alle Condizioni d'uso di
                            PlayStation™Network disponibili su
                            PlayStation™Store. Consulta i diritti d'uso per ogni
                            acquisto effettuato, poiché possono differire in
                            base al contenuto. Se non diversamente specificato,
                            i contenuti disponibili nei negozi in-game sono
                            rivolti ad utenti con la stessa età consigliata per
                            il gioco.
                        </li>
                    </ul>
                    <h1>German</h1>
                    <ul>
                        <li>
                            Alle Inhalte, die in In-Game-Shops käuflich erworben
                            werden, werden von Sony Interactive Entertainment
                            Network Europe Limited („SIENE“) erworben und
                            unterliegen den Nutzungsbedingungen und der
                            Endbenutzervereinbarung von PlayStation™Network, die
                            über PlayStation™Store eingesehen werden können. Wir
                            empfehlen, die Nutzungsrechte für jedes erworbene
                            Produkt zu überprüfen, da sie sich vonFall zu Fall
                            unterscheiden können. Sofern nicht anders angegeben,
                            gilt für sämtliche Inhalte, die in In-Game-Shops
                            erhältlich sind, dieselbe Altersfreigabe wie für das
                            entsprechende Spiel.
                        </li>
                    </ul>
                    <h1>Russian</h1>
                    <ul>
                        <li>
                            Любой товар, приобретенный во внутриигровом
                            магазине, будет являться покупкой у компании Sony
                            Interactive Entertainment Network Europe Limited
                            (“SIENE”) и подчиняться Условиям предоставления
                            услуг PlayStation™Network, с которыми можно
                            ознакомиться в PlayStation™Store. Пожалуйста,
                            ознакомьтесь с правами использования - они могут
                            быть различными для разных продуктов. Если не
                            указано иное, товар в любом внутриигровом магазине
                            имеет ту же возрастную категорию, что и игра.
                        </li>
                    </ul>
                    <h1>Dutch</h1>
                    <ul>
                        <li>
                            Elke content die in een winkel in het spel is
                            gekocht, wordt gekocht van Sony Interactive
                            Entertainment Network Europe Limited (“SIENE”) en is
                            onderworpen aan de servicevoorwaarden van
                            PlayStation™Network die bij PlayStation™Store
                            verkrijgbaar zijn. U dient de gebruiksrechten te
                            controleren omdat deze per item kunnen verschillen.
                            Tenzij anderszins vermeld, gelden dezelfde
                            leeftijdsbeperkingen voor content die in een winkel
                            in het spel beschikbaar is als voor het spel zelf.
                        </li>
                    </ul>
                    <h1>Portuguese</h1>
                    <ul>
                        <li>
                            Qualquer conteúdo comprado numa loja de jogo é
                            comprado à Sony Interactive Entertainment Network
                            Europe Limited (“SIENE”) e está sujeito aos Termos
                            de Serviço e Acordo de Utilizador da
                            PlayStation™Network, disponíveis na
                            PlayStation™Store. Verifique os direitos de
                            utilização relativos a cada compra, uma vez que
                            estes podem diferir de um artigo para outro. Salvo
                            indicação em contrário, o conteúdo disponível em
                            qualquer loja de jogo possui a mesma classificação
                            etária que o próprio jogo.
                        </li>
                    </ul>
                    <h1>Turkish</h1>
                    <ul>
                        <li>
                            Oyun içi mağaza içinden satın alınan tüm içerik Sony
                            Interactive Entertainment Network Europe Limited
                            ("SIENE") şirketinden satın alınacakolup,
                            PlayStation™Store'da bulunan PlayStation™Network
                            Hizmet Şartları'na tabidir. Öğeden öğeye farklılık
                            gösterebileceği için lütfen her satın almaya ilişkin
                            kullanım haklarını kontrol edin. Aksi belirtilmediği
                            müddetçe, oyun içi mağazadan satın alınan içerik,
                            oyunla aynı yaş sınıflandırmasına sahiptir.
                        </li>
                    </ul>
                    <h1>Polish</h1>
                    <ul>
                        <li>
                            Zawartość zakupiona za pośrednictwem sklepu
                            udostępnionego w grze oznacza, że została ona
                            zakupiona od firmy Sony Interactive
                            EntertainmentNetwork Europe Limited („SIENE”) i
                            podlega Warunkom świadczenia usług obowiązującym w
                            sieci PlayStation™Network, z którymi można zapoznać
                            się w sklepie PlayStation™Store. Prawa użytkowania
                            należy sprawdzać przy każdym zakupie, ponieważ mogą
                            one być różne w zależności od pozycji. Jeśli nie
                            zaznaczono inaczej, zawartość dostępna w dowolnym
                            sklepie udostępnionym w grze jest objęta tą samą
                            klasyfikacją co gra.
                        </li>
                    </ul>
                    <h1>Finnish</h1>
                    <ul>
                        <li>
                            Kaikki pelin sisäisestä kaupasta ostettu sisältö
                            ostetaan Sony Interactive Entertainment Network
                            Europe Limited (”SIENE”), ja se on
                            PlayStation™Network –palvelun käyttöehtojen alaista.
                            Nämä käyttöehdot saa PlayStation™Store-kaupasta.
                            Tarkista kunkin ostoksen käyttöoikeudet, sillä ne
                            voivat vaihdella kohteesta toiseen. Ellei toisin
                            ilmoiteta, pelin sisäisessä kaupassa myytävien
                            sisältöjen ikäluokitus on sama kuin pelin.
                        </li>
                    </ul>
                    <h1>Danish</h1>
                    <ul>
                        <li>
                            Alt indhold, der købes fra en butik i et spil, købes
                            gennem Sony Interactive Entertainment Network Europe
                            Limited (“SIENE”) og er underlagt
                            PlayStation™Network Betingelser for tjenesteydelse,
                            som findes på PlayStation™Store. Kontroller
                            rettighederne til brug for hvert enkelt køb, da de
                            kan være forskellige fra vare til vare. Medmindre
                            andet er vist, har indhold, som er tilgængeligt i en
                            butik i et spil, den samme aldersklassifikation som
                            spillet.
                        </li>
                    </ul>
                    <h1>Norwegian</h1>
                    <ul>
                        <li>
                            Alt innhold som blir kjøpt i en butikk i spillet,
                            kjøpes fra Sony Interactive Entertainment Network
                            Europe Limited (“SIENE”) og er underlagt
                            tjenestevilkårene til PlayStation™Network som kan
                            leses på PlayStation™Store. Kontroller
                            bruksrettighetene for hvert kjøp, da de kan variere
                            for ulike gjenstander. Innholdet som er tilgjengelig
                            i butikken har samme aldersgrense som spillet, med
                            mindre noe annet er angitt.
                        </li>
                    </ul>
                    <h1>Swedish</h1>
                    <ul>
                        <li>
                            Innehåll som köps inuti spel köps från Sony
                            Interactive Entertainment Network Europe Limited
                            ("SIENE") och är föremål för tjänstevillkoren och
                            användaravtalet för PlayStation™Network som finns
                            tillgängligt i PlayStation™Store. Var vänlig
                            kontrollera användningsrättigheter för varje köp,
                            eftersom dessa kan variera från föremål till
                            föremål. Innehåll som tillhandahålls inuti spel har
                            samma åldersgräns som spelet, om inte annat anges.
                        </li>
                    </ul>
                    <h1>Arabic</h1>
                    <ul style="direction:rtl">
                        <li>
                            سيتم شراء أي محتوى تم شراؤه من متجر داخل اللعبة
                            منSony Interactive Entertainment Network Europe
                            Limited ("SIENE") وسيخضع لشروط الخدمة واتفاقية
                            المستخدم الخاصة بـPlayStation™Network والمتوفرة
                            علىPlayStation™Store. رجى التحقق من حقوق الاستخدام
                            لكل عملية شراء لأنها قد تختلف ُيمن عنصر لآخر. يكون
                            المحتوى المتوفر في أي متجر داخل اللعبة بنفس تقييم
                            السن الخاص باللعبة، إلا إذا تم عرض غير ذلك.
                        </li>
                    </ul>
                    Go
                    <router-link
                        class="a-text"
                        exact
                        :to="{ name: pages.EULA_PAGE_NAME }"
                        >back to EULA & Terms</router-link
                    >.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import ImageOptimized from '@/components/partials/ImageOptimized';
import { mapGetters } from 'vuex';

export default {
    name: 'EulaPlaystation',
    components: { ImageOptimized },
    data() {
        return {
            pages,
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/bg-eula.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

#eula-purchases {
    #eula-purchases-content {
        padding-bottom: 100px;
        .section-content {
            padding-top: 192px;
        }
    }
}
</style>
