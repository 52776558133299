var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"basic-page",attrs:{"id":"anniversary"},on:{"click":_vm.closeAllSelections}},[_c('Loading',{attrs:{"show":!_vm.imgLoaded}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imgLoaded),expression:"imgLoaded"}]},[_c('div',{ref:"header",staticClass:"section",attrs:{"id":"header"}},[_c('ImageOptimized',{attrs:{"code":"anniversary/ac_bg","thumb-formats":"webp,jpg","type":"anniversary"},on:{"loaded":function($event){_vm.loaded.headerBg = true},"failed":function($event){_vm.loaded.headerBg = true}}}),_c('div',{staticClass:"section-content"},[_c('img',{staticClass:"vigor-switch-season-logo",attrs:{"src":_vm.settings.webpSupport
                            ? '/static/images/anniversary/ac_logo.webp'
                            : '/static/images/anniversary/ac_logo.png',"draggable":"false"}}),_c('div',{staticClass:"reverse-wrapper"},[_c('img',{staticClass:"vigor-switch-play-button",attrs:{"src":_vm.settings.webpSupport
                                ? '/static/images/anniversary/ac_join to win.webp'
                                : '/static/images/anniversary/ac_join to win.png',"draggable":"false"}}),_c('LinkButton',{staticClass:"button-signup",attrs:{"text":"Join the competition","wrapper-type":"noPadding","type":"red","event":true},on:{"eventMethod":_vm.scrollToForm}})],1),_c('div',{staticClass:"text"},[_vm._v("SUBMISSION DEADLINE: 2.9.2020")]),_c('LinkButton',{attrs:{"text":"CONTEST RULES","wrapper-type":"noPadding","type":"light","link":_vm.pages.NEWS_DETAIL_PAGE_NAME,"params":{ slug: 'anniversary-week' }}})],1)],1),_c('div',{ref:"form",staticClass:"section",attrs:{"id":"form"}},[_c('div',{staticClass:"section-content"},[_c('h2',[_vm._v(" To enter the competition please fill in the form below and submit your creation. ")]),_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"half"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Name / Nickname (We will be sharing this name on our social media)*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],class:{
                                alertRequired: _vm.alertRequired,
                                empty: !_vm.form.name,
                            },attrs:{"id":"name","name":"name","type":"text","maxlength":"255","placeholder":"Name / Nickname"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('label',{attrs:{"for":"email"}},[_vm._v("E-mail*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],class:{
                                alertRequired: _vm.alertRequired,
                                empty: !_vm.form.email,
                            },attrs:{"id":"email","name":"email","type":"email","maxlength":"255","placeholder":"Email*"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}})]),_c('div',{staticClass:"half"},[_c('label',{attrs:{"for":"creation"}},[_vm._v("Link to your creation (For ex: Dropbox, Google Drive, WeTransfer, OneCloud..)*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.creation),expression:"form.creation"}],class:{
                                alertRequired: _vm.alertRequired,
                                empty: !_vm.form.creation,
                            },attrs:{"id":"creation","name":"creation","type":"url","maxlength":"700","placeholder":"Creation URL*"},domProps:{"value":(_vm.form.creation)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "creation", $event.target.value)}}}),_c('label',{attrs:{"for":"platform"}},[_vm._v("Platform*")]),_c('div',{staticClass:"custom-select",class:{
                                empty: !_vm.form.platform && !_vm.selected.platform,
                                alertRequired: _vm.alertRequired,
                            },on:{"click":function($event){_vm.closeAllSelections();
                                _vm.openSelection(
                                    (_vm.selected.platform = true),
                                    $event
                                );}}},[_c('div',{staticClass:"custom-select-value"},[(!_vm.form.platform)?[_vm._v("Platform")]:[_vm._v(_vm._s(_vm.form.platform))]],2),(_vm.selected.platform && _vm.selecting)?_c('div',{staticClass:"custom-options"},_vm._l((_vm.platforms),function(platform,idx){return _c('div',{key:idx,staticClass:"custom-option",attrs:{"id":"platform","name":"platform"},on:{"click":function($event){return _vm.select({
                                            type: 'platform',
                                            use: platform,
                                        })}}},[_vm._v(" "+_vm._s(platform)+" ")])}),0):_vm._e()])]),_c('br'),_c('div',{staticClass:"form-end"},[_vm._v(" * required fields "),_c('div',[_c('input',{ref:"checkbox",staticClass:"checkbox",attrs:{"type":"checkbox"}}),_vm._m(0)]),_c('div',[_c('input',{ref:"checkbox",staticClass:"checkbox",attrs:{"type":"checkbox"}}),_vm._m(1)]),_c('div',{staticClass:"captcha"},[(_vm.recaptchaSettings)?_c('VueRecaptcha',{ref:"recaptcha",attrs:{"size":"invisible","badge":"inline","sitekey":_vm.recaptchaSettings.key},on:{"verify":_vm.onVerify,"expired":_vm.onExpired}}):_vm._e()],1),_c('div',{staticClass:"form-button",class:{
                                showAlert: _vm.alertTerms || _vm.alertRequired,
                            }},[_c('LinkButton',{attrs:{"text":"Apply","type":"red minWidth","form":true}}),(_vm.alertTerms && !_vm.alertRequired)?_c('div',{staticClass:"alert"},[_vm._v(" You need to agree to EULA and GDPR terms. ")]):_vm._e(),(_vm.alertRequired)?_c('div',{staticClass:"alert"},[_vm._v(" You need to fill in the required fields. ")]):_vm._e()],1)])])])])]),_c('Popup',{attrs:{"show":_vm.popupPartner,"title":"Thank you for your submission.","text":"We will let you know if your creation was picked via email after the submission period ends."},on:{"closeMethod":_vm.popupPartnerClose}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"checkbox-label"},[_vm._v(" I have read and agree to "),_c('a',{staticClass:"a-text",attrs:{"href":"https://vigorgame.com/eula","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("EULA")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"checkbox-label"},[_vm._v(" I understand how my "),_c('a',{staticClass:"a-text",attrs:{"href":"https://www.bohemia.net/privacy-policy","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("personal data is processed")]),_vm._v(". ")])
}]

export { render, staticRenderFns }