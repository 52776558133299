<template>
    <div
        id="popup-layer"
        class="basic-content"
        :class="{ show: showPopup, hide: !showPopup }"
        @click="closeMethod"
        @mousemove="block"
    >
        <div
            v-if="video"
            id="popup-window"
            class="one"
            :class="{ webp: settings.webpSupport }"
            @click="stopPropagation"
        >
            <div class="popup-content">
                <div class="popup-column-wrapper">
                    <div class="popup-column">
                        <div class="popup-video">
                            <iframe
                                frameborder="0"
                                :src="videoUrl"
                                width="100%"
                                height="100%"
                                allowfullscreen
                            >
                            </iframe>
                        </div>
                    </div>
                </div>
                <div class="popup-close" @click="closeMethod"></div>
            </div>
        </div>
        <div
            v-if="oneImage"
            id="popup-window"
            class="one"
            :class="{ webp: settings.webpSupport }"
            @click="stopPropagation"
        >
            <div class="popup-content">
                <div class="popup-column-wrapper">
                    <div class="popup-column">
                        <img
                            class="popup-image"
                            :src="column.A.image"
                            draggable="false"
                            :alt="column.A.text"
                        />
                    </div>
                </div>
                <div class="popup-close" @click="closeMethod"></div>
            </div>
        </div>
        <div
            v-if="!video && !oneImage"
            id="popup-window"
            :class="{ webp: settings.webpSupport }"
            @click="stopPropagation"
        >
            <div class="popup-content">
                <div
                    v-for="c in ['A', 'B', 'C']"
                    v-if="column[c] && !column[c].hidden"
                    :key="c.index"
                    class="popup-column-wrapper"
                >
                    <div class="popup-column">
                        <img
                            class="popup-image"
                            :src="column[c].image"
                            draggable="false"
                        />
                        <div class="popup-title">{{ column[c].title }}</div>
                        <div class="popup-text">{{ column[c].text }}</div>
                        <div class="popup-button">
                            <LinkButton
                                :text="column[c].buttonText"
                                type="red minWidth"
                                :link="column[c].link"
                                :outside="true"
                            />
                        </div>
                    </div>
                </div>
                <div class="popup-close" @click="closeMethod"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import LinkButton from '@/components/partials/LinkButton';
import { mapGetters } from 'vuex';
import ImageOptimized from '@/components/partials/ImageOptimized';

export default {
    name: 'PopupUniversal',
    components: { LinkButton, ImageOptimized },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        popupColumnA: {
            type: String,
            default: '',
        },
        popupColumnB: {
            type: String,
            default: '',
        },
        popupColumnC: {
            type: String,
            default: '',
        },
        oneImage: {
            type: Boolean,
            default: false,
        },
        video: { type: Boolean, default: false },
        videoUrl: { type: String, default: '' },
    },
    data() {
        return {
            pages,
            window: { w: window.innerWidth, h: window.innerHeight },
            brokenImage: false,
            column: { A: null, B: null, C: null },
            showPopup: false,
        };
    },
    methods: {
        closeMethod() {
            this.$emit('closeMethod');
        },
        block(event) {
            event.preventDefault();
            event.stopPropagation();
        },
        stopPropagation(event) {
            event.stopPropagation();
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
        },
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
    watch: {
        show() {
            if (this.video == false) {
                this.showPopup =
                    this.show &&
                    ((this.column.A && !this.column.A.hidden) ||
                        (this.column.B && !this.column.B.hidden) ||
                        (this.column.C && !this.column.C.hidden));
            } else if (this.video == true) {
                this.showPopup = this.show;
            }
        },
    },
    created() {
        window.addEventListener('resize', this.windowResize);

        ['A', 'B', 'C'].forEach(column => {
            if (
                this[`popupColumn${column}`] &&
                this[`popupColumn${column}`] != ''
            ) {
                this.column[column] = JSON.parse(this[`popupColumn${column}`]);
            }
        });
        console.log(this.popupColumnA);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../../styles/colors.scss';
@import '../../../styles/popups.scss';

#popup-layer {
    #popup-window {
        height: 760px;
        width: 1200px;
        top: calc(50% - 380px);
        left: calc(50% - 600px);
        background-image: url('/static/images/popup-higher.png');

        &.webp {
            background-image: url('/static/images/popup-higher.webp');
        }

        .popup-content {
            padding: 50px 25px;
            height: calc(100% - 100px);
            width: calc(100% - 50px);

            .popup-column-wrapper {
                display: inline-block;
                height: 100%;
                width: 33%;
                vertical-align: top;

                .popup-column {
                    display: block;
                    position: relative;
                    height: calc(100% - 40px);
                    width: calc(100% - 30px);
                    margin: 20px 15px;

                    .popup-image {
                        height: auto;
                        width: 100%;
                        max-height: 540px;
                        max-width: 360px;
                        margin: auto;
                    }
                    .popup-video {
                        position: relative;
                        padding-bottom: 56.25%; /* 16:9 */
                        padding-top: 25px;
                        height: 0;
                        & > iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .popup-title {
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 50px 58px;

                        @media (max-width: 1000px) {
                            margin: 50px 30px;
                        }

                        @media (max-width: 800px) {
                            margin: 50px 0;
                            width: 100%;
                        }
                    }

                    .popup-text {
                        min-height: 50px;
                        margin: 10px 55px;
                        opacity: 0.8;

                        @media (max-width: 800px) {
                            margin: 10px 0;
                        }
                    }

                    .popup-button {
                        margin: 10px 0;
                    }

                    .popup-image,
                    .popup-title,
                    .popup-text,
                    .popup-button {
                        display: block;
                    }
                }

                @media (max-width: 800px) {
                    display: block;
                    width: 100vw;
                }
            }
        }

        &.one {
            .popup-content {
                padding: 15px 25px;
                height: calc(100% - 30px);
                width: calc(100% - 50px);

                .popup-column-wrapper {
                    width: 100%;

                    .popup-column {
                        width: 100%;
                        .popup-image {
                            height: auto;
                            width: auto;
                            max-height: 100%;
                            max-width: 100%;
                            margin: auto;
                        }
                    }
                }
            }
        }

        @media (max-width: 1200px) {
            width: 100vw;
            height: initial;
            left: 0;
            top: 0;

            .popup-content {
                padding: 70px 0 20px 0;
            }
        }

        @media (max-width: 800px) {
            background-image: none;
            background-color: rgba(0, 0, 0, 0.8);

            &.webp {
                background-image: none;
            }
        }
    }
}
</style>
