<template>
    <div v-if="settings" id="content">
        <Navigation />
        <router-view :key="$route.path"></router-view>
        <div class="footer">
            <FooterSocial />
            <FooterBasic :scroll-top-button="true" />
        </div>
    </div>
    <div v-else id="content">
        Loading...
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Navigation from './components/partials/Navigation';
import FooterBasic from '@/components/partials/FooterBasic';
import FooterSocial from '@/components/partials/FooterSocial';

export default {
    name: 'App',
    components: { Navigation, FooterBasic, FooterSocial },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
    methods: {
        ...mapActions('settings', ['loadSettings']),
        ...mapActions('user', ['loadUser']),
        ...mapActions('updates', ['loadUpdates']),
        ...mapActions('articles', ['loadArticles']),
        ...mapActions('faqs', ['loadFaqs']),
        ...mapActions('presskits', ['loadPresskits']),
        ...mapActions('homepages', ['loadHomepages']),
        // ...mapActions('homeItems', ['loadHomeItems']),
        ...mapActions('partners', ['loadPartners']),
        ...mapActions('changelogs', ['loadChangelogs']),
        ...mapActions('partnerships', ['loadPartnerships']),
    },
    created: function() {
        this.loadSettings();
        this.loadUser();
        this.loadUpdates();
        this.loadArticles();
        this.loadFaqs();
        this.loadPresskits();
        this.loadHomepages();
        // this.loadHomeItems();
        this.loadPartners();
        this.loadChangelogs();
        this.loadPartnerships();
    },
};
</script>

<style scoped lang="scss" media="print">
#content {
    font-family: 'PT Sans';
    font-weight: 400;
    overflow: hidden;
    -ms-scroll-limit: 0 0 0 0;
    background-color: black;
    min-height: 100vh;
    margin-bottom: -20px;

    background-size: auto;
    background-repeat: repeat-y;
}

a:hover,
a:visited,
a:link,
a:active {
    text-decoration: none;
}
</style>
