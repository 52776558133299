var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"global-style"},[_c('div',{staticClass:"basic-page",attrs:{"id":"news-detail"}},[_c('Loading',{attrs:{"show":!_vm.dataLoaded}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoaded),expression:"dataLoaded"}]},[_c('div',{staticClass:"static-bg",class:{ hidden: _vm.settings && _vm.settings.apple }},[_c('ImageOptimized',{attrs:{"code":"placeholder/news/bg-news","thumb-formats":"webp,jpg","thumb-sizes":"480,768,992,1200","type":"static-bg"},on:{"loaded":function($event){_vm.loaded.staticBg = true},"failed":function($event){_vm.loaded.staticBg = true}}})],1),(_vm.update)?_c('div',{ref:"content",staticClass:"section",attrs:{"id":"news-detail-content"}},[_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"scrollable-content"},[_c('router-link',{staticClass:"a-text no-underline",attrs:{"exact":"","to":{ name: _vm.pages.UPDATES_PAGE_NAME }}},[_c('div',{staticClass:"back"},[_vm._v("<< BACK TO UPDATES")])]),_c('div',{staticClass:"event-title"},[_vm._v(_vm._s(_vm.update.name))]),_c('div',{staticClass:"event-date"},[_vm._v(" "+_vm._s(_vm.dateInArticleFormat(_vm.update.published_at))+" ")]),_c('img',{staticClass:"title-image",attrs:{"src":_vm.update.titleImage,"draggable":"false"}}),(_vm.update.text)?_c('div',{staticClass:"event-article-content"},[_c('h2',{staticClass:"capitals"},[_vm._v("Description")]),_c('div',{staticClass:"event-text"},[_c('v-runtime-template',{attrs:{"template":`<div>${_vm.processText(
                                            _vm.update.text
                                        )}</div>`}})],1),(_vm.update.youtubeVideo)?_c('div',{staticClass:"embed-responsive embed-responsive-16by9 event-video"},[_c('iframe',{staticClass:"embed-responsive-item behind",attrs:{"src":_vm.videoUrl,"frameborder":"0","height":"100%","width":"100%","allowfullscreen":""}},[_c('video',{staticStyle:{"width":"640px","height":"360px"},attrs:{"src":_vm.videoUrl,"width":"640","height":"360","autoplay":"0"}})])]):_vm._e(),(_vm.update.content)?_c('div',{staticClass:"event-update-content"},[_c('h2',{staticClass:"capitals"},[_vm._v("Content")]),_c('v-runtime-template',{attrs:{"template":`<div>${_vm.processList(
                                            _vm.update.content
                                        )}</div>`}})],1):_vm._e(),(
                                    _vm.update.screenshots &&
                                        _vm.update.screenshots.includes(
                                            'app-picture'
                                        )
                                )?_c('div',{staticClass:"event-update-content"},[_c('h2',{staticClass:"capitals"},[_vm._v("Screenshots")]),_c('v-runtime-template',{attrs:{"template":`<div class='screenshots'>${_vm.processScreenshots(
                                            _vm.update.screenshots
                                        )}</div>`}})],1):_vm._e(),_c('div',{staticClass:"event-changelog-button"},[(
                                        _vm.update.changelog &&
                                            _vm.update.changelog.isPublished
                                    )?_c('LinkButton',{attrs:{"text":`See changelog ${_vm.update.changelog.version}`,"icon":"change-icon light","type":"dark-red centered","link":_vm.pages.CHANGELOG_DETAIL_PAGE_NAME,"params":{
                                        slug: _vm.update.changelog.slug,
                                    }}}):_vm._e(),(_vm.update.package)?_c('LinkButton',{attrs:{"id":"downloadBtn","text":'Download Presskit',"type":"dark-red centered","download":true,"link":_vm.update.package,"outside":true}}):_vm._e()],1),(_vm.update.partingImage)?_c('img',{staticClass:"postcard",attrs:{"src":_vm.update.partingImage,"draggable":"false"}}):_c('ImageOptimized',{attrs:{"code":"news/postcard","thumb-formats":"webp,png","type":"postcard"}})],1):_vm._e(),_c('router-link',{staticClass:"a-text no-underline",attrs:{"exact":"","to":{ name: _vm.pages.UPDATES_PAGE_NAME }}},[_c('div',{staticClass:"back"},[_vm._v("<< BACK TO NEWS")])])],1),(_vm.updates.rows)?_c('Sidebar',{attrs:{"header-text":'RECENT UPDATES',"entries":_vm.updates.rows,"link-name":_vm.pages.UPDATES_DETAIL_PAGE_NAME,"show-tags":false}}):_vm._e()],1)]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }