<template>
    <div id="not-found" class="basic-page">
        <div class="static-bg" :class="{ hidden: settings && settings.apple }">
            <ImageOptimized
                code="bg-404"
                thumb-formats="webp,jpg"
                thumb-sizes="768,992,1200"
                type="static-bg"
            />
        </div>
        <div id="not-found-content" class="section">
            <div class="section-content">
                <div class="upper-title">Something went wrong</div>
                <div class="page-title">404</div>
                <div class="text">
                    Uh oh! Looks like you're lost, Outlander.<br />
                    Try going
                    <router-link
                        class="a-text"
                        exact
                        :to="{ name: pages.HOME_PAGE_NAME }"
                        >back to Home</router-link
                    >.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import ImageOptimized from '@/components/partials/ImageOptimized';
import { mapGetters } from 'vuex';

export default {
    name: 'NotFound',
    components: { ImageOptimized },
    data() {
        return {
            pages,
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/bg-404.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

#not-found {
    #not-found-content {
        .section-content {
            padding: 192px;
        }
    }
}
</style>
