<template>
    <div id="anniversary" class="basic-page" @click="closeAllSelections">
        <Loading :show="!imgLoaded" />
        <div v-show="imgLoaded">
            <div id="header" ref="header" class="section">
                <ImageOptimized
                    code="anniversary/ac_bg"
                    thumb-formats="webp,jpg"
                    type="anniversary"
                    @loaded="loaded.headerBg = true"
                    @failed="loaded.headerBg = true"
                />
                <div class="section-content">
                    <img
                        class="vigor-switch-season-logo"
                        :src="
                            settings.webpSupport
                                ? '/static/images/anniversary/ac_logo.webp'
                                : '/static/images/anniversary/ac_logo.png'
                        "
                        draggable="false"
                    />
                    <div class="reverse-wrapper">
                        <img
                            class="vigor-switch-play-button"
                            :src="
                                settings.webpSupport
                                    ? '/static/images/anniversary/ac_join to win.webp'
                                    : '/static/images/anniversary/ac_join to win.png'
                            "
                            draggable="false"
                        />
                        <LinkButton
                            class="button-signup"
                            text="Join the competition"
                            wrapper-type="noPadding"
                            type="red"
                            :event="true"
                            @eventMethod="scrollToForm"
                        />
                    </div>
                    <div class="text">SUBMISSION DEADLINE: 2.9.2020</div>
                    <LinkButton
                        text="CONTEST RULES"
                        wrapper-type="noPadding"
                        type="light"
                        :link="pages.NEWS_DETAIL_PAGE_NAME"
                        :params="{ slug: 'anniversary-week' }"
                    />
                </div>
            </div>
            <div id="form" ref="form" class="section">
                <div class="section-content">
                    <h2>
                        To enter the competition please fill in the form below
                        and submit your creation.
                    </h2>
                    <form autocomplete="off" @submit.prevent="handleSubmit">
                        <div class="half">
                            <label for="name"
                                >Name / Nickname (We will be sharing this name
                                on our social media)*</label
                            >
                            <input
                                id="name"
                                v-model="form.name"
                                name="name"
                                type="text"
                                maxlength="255"
                                placeholder="Name / Nickname"
                                :class="{
                                    alertRequired: alertRequired,
                                    empty: !form.name,
                                }"
                            />
                            <label for="email">E-mail*</label>
                            <input
                                id="email"
                                v-model="form.email"
                                name="email"
                                type="email"
                                maxlength="255"
                                placeholder="Email*"
                                :class="{
                                    alertRequired: alertRequired,
                                    empty: !form.email,
                                }"
                            />
                        </div>
                        <div class="half">
                            <label for="creation"
                                >Link to your creation (For ex: Dropbox, Google
                                Drive, WeTransfer, OneCloud..)*</label
                            >
                            <input
                                id="creation"
                                v-model="form.creation"
                                name="creation"
                                type="url"
                                maxlength="700"
                                placeholder="Creation URL*"
                                :class="{
                                    alertRequired: alertRequired,
                                    empty: !form.creation,
                                }"
                            />
                            <label for="platform">Platform*</label>
                            <div
                                class="custom-select"
                                :class="{
                                    empty: !form.platform && !selected.platform,
                                    alertRequired: alertRequired,
                                }"
                                @click="
                                    closeAllSelections();
                                    openSelection(
                                        (selected.platform = true),
                                        $event
                                    );
                                "
                            >
                                <div class="custom-select-value">
                                    <template v-if="!form.platform"
                                        >Platform</template
                                    >
                                    <template v-else>{{
                                        form.platform
                                    }}</template>
                                </div>
                                <div
                                    v-if="selected.platform && selecting"
                                    class="custom-options"
                                >
                                    <div
                                        v-for="(platform, idx) in platforms"
                                        id="platform"
                                        :key="idx"
                                        name="platform"
                                        class="custom-option"
                                        @click="
                                            select({
                                                type: 'platform',
                                                use: platform,
                                            })
                                        "
                                    >
                                        {{ platform }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="form-end">
                            * required fields
                            <div>
                                <input
                                    ref="checkbox"
                                    class="checkbox"
                                    type="checkbox"
                                />
                                <span class="checkbox-label">
                                    I have read and agree to
                                    <a
                                        class="a-text"
                                        href="https://vigorgame.com/eula"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        >EULA</a
                                    >.
                                </span>
                            </div>
                            <div>
                                <input
                                    ref="checkbox"
                                    class="checkbox"
                                    type="checkbox"
                                />
                                <span class="checkbox-label">
                                    I understand how my
                                    <a
                                        class="a-text"
                                        href="https://www.bohemia.net/privacy-policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        >personal data is processed</a
                                    >.
                                </span>
                            </div>

                            <div class="captcha">
                                <VueRecaptcha
                                    v-if="recaptchaSettings"
                                    ref="recaptcha"
                                    size="invisible"
                                    badge="inline"
                                    :sitekey="recaptchaSettings.key"
                                    @verify="onVerify"
                                    @expired="onExpired"
                                />
                            </div>

                            <div
                                class="form-button"
                                :class="{
                                    showAlert: alertTerms || alertRequired,
                                }"
                            >
                                <LinkButton
                                    text="Apply"
                                    type="red minWidth"
                                    :form="true"
                                />
                                <div
                                    v-if="alertTerms && !alertRequired"
                                    class="alert"
                                >
                                    You need to agree to EULA and GDPR terms.
                                </div>
                                <div v-if="alertRequired" class="alert">
                                    You need to fill in the required fields.
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <Popup
            :show="popupPartner"
            title="Thank you for your submission."
            text="We will let you know if your creation was picked via email after the submission period ends."
            @closeMethod="popupPartnerClose"
        />
    </div>
</template>

<script>
import Loading from '@/components/partials/Loading';
import VueRecaptcha from 'vue-recaptcha';
import { AnniversaryResource } from '@/services/anniversary';
import LinkButton from '@/components/partials/LinkButton';
import { getCaptcha } from '@/services/captcha';
import ImageOptimized from '@/components/partials/ImageOptimized';
import Popup from '@/components/partials/popups/Popup';
import { mapGetters } from 'vuex';
import * as pages from '@/routes/pageCodes';

export default {
    name: 'Anniversary',
    components: {
        Loading,
        VueRecaptcha,
        ImageOptimized,
        LinkButton,
        Popup,
    },
    data() {
        return {
            pages,
            loaded: {
                headerBg: false,
            },
            window: { w: window.innerWidth, h: window.innerHeight },
            form: {
                name: '',
                email: '',
                creation: '',
                platform: '',
            },

            selecting: false,
            selected: {
                platform: false,
            },
            platforms: { nintendo: 'Nintendo Switch', xbox: 'Xbox One' },

            popupPartner: false,
            alertTerms: false,
            alertRequired: false,

            captchaResponse: null,
            recaptchaSettings: null,
        };
    },
    methods: {
        scrollToForm() {
            const id = 'form';
            const yOffset = -60;
            const element = document.getElementById(id);
            const y =
                element.getBoundingClientRect().top +
                window.pageYOffset +
                yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        },
        openSelection(selected, event) {
            this.selecting = !this.selecting;
            event.stopPropagation();
        },
        closeAllSelections() {
            for (let key in this.selected) {
                if (Object.hasOwnProperty.call(this.selected, key)) {
                    this.selected[key] = false;
                }
            }
            this.selecting = false;
        },

        select(value) {
            switch (value.type) {
                case 'platform':
                    this.form.platform = value.use;
                    break;

                default:
                    this.closeAllSelections();
                    break;
            }
            this.closeAllSelections();
            event.stopPropagation();
        },
        onVerify(value) {
            this.captchaResponse = value;
            this.sendForm();
        },
        onExpired() {
            this.$refs.recaptcha.reset();
            this.captchaResponse = null;
        },
        popupPartnerClose() {
            this.popupPartner = false;
            location.reload();
        },
        onExecute() {
            if (!this.$refs.checkbox.checked) {
                this.alertTerms = true;
                return;
            }
            if (
                !(
                    this.form.email &&
                    this.form.name &&
                    this.form.creation &&
                    this.form.platform
                )
            ) {
                this.alertRequired = true;
                return;
            }

            this.alertTerms = false;
            this.alertRequired = false;
            this.$refs.recaptcha.execute();
        },
        handleSubmit() {
            this.onExecute();
        },
        sendForm() {
            AnniversaryResource.add({
                anniversary: this.form,
                'g-recaptcha-response': this.captchaResponse,
            })
                .then(response => {
                    this.popupPartner = true;

                    this.form = {
                        email: '',
                        name: '',
                        creation: '',
                        platform: '',
                    };
                    this.$refs.checkbox.checked = false;
                })
                .catch(err => {
                    console.log(err);
                });
        },
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        imgLoaded() {
            return this.loaded.headerBg;
        },
    },
    created() {
        getCaptcha().then(response => {
            this.recaptchaSettings = response;
        });
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';
#anniversary {
    font-size: 18px;
    color: $light;

    #header {
        z-index: 15;
        background-color: black;
        height: 100vh;
        min-height: 1000px;
        position: relative;

        .section-content {
            margin: 100px 0 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100vw;
            position: absolute;
            top: 50px;
            left: 30%;
            transform: translate(-50%, 0%);

            @media (max-width: $screen-md) {
                left: 50%;
            }
        }

        .vigor-switch-season-logo {
            width: 100%;
            max-width: 400px;
            height: auto;

            @media (max-width: $screen-sm) {
                max-width: 396px;
                margin-bottom: 36px;
            }
            @media (max-width: $screen-xs) {
                max-width: 292px;
                margin-bottom: 36px;
            }
        }
        .reverse-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            .vigor-switch-play-button {
                width: 100%;
                max-width: 400px;
                height: auto;
                margin: 50px 0;
                transition: all ease 0.8s;
                @media (max-width: $screen-sm) {
                    max-width: 250px;
                }
            }

            @media (max-width: $screen-sm) {
                flex-direction: column-reverse;
            }
        }
        .text {
            color: #ffffff;
            font-family: 'PT Sans';
            font-size: 25px;
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;

            @media (max-width: $screen-sm) {
                margin: 15px;
            }
        }
    }

    #form {
        height: auto;
        margin-bottom: 100px;
        margin-top: 80px;
        z-index: 10; //longer answers were under footer
        text-align: center;
        overflow: visible;

        h2 {
            margin: 25px 0;
        }

        .section-content {
            .form-end {
                text-align: center;
                margin: 50px 0;
            }
        }

        input,
        textarea,
        .custom-select {
            background-color: transparent;
            color: $light !important;
            font-size: 20px;
            font-family: inherit;
            width: calc(100% - 40px);
            max-height: 150px;
            margin: 15px;
            padding: 0 20px;
            display: block;
            border: 1px solid $light;
            resize: none;
            opacity: 1;
            text-align: left;
            line-height: 50px;

            @media (max-width: $screen-xs) {
                font-size: 16px;
            }
        }

        .custom-select-value {
            border: none;
            position: relative;
            width: 100%;
            cursor: pointer;

            .input-special {
                border: none !important;
                margin: 0;
                padding: 0;
                cursor: text;
            }
        }

        .custom-select-value:after {
            content: '';
            width: 30px;
            height: 30px;
            margin: 10px 0;
            bottom: 0;
            right: 0;
            position: absolute;
            display: inline-block;
            background-image: url('/static/images/partnership/dropdown_arrow.svg');
            background-position: center center;
            background-size: cover;
            cursor: pointer;
        }

        .custom-options {
            position: relative;
            display: block;
            background-color: $light;
            color: $dark;
            max-height: 400px;
            overflow-y: scroll;
            z-index: 5;

            margin-left: -21px;
            width: calc(100% + 40px);
            border: 1px solid $light;
            font-weight: 600;

            .custom-option {
                cursor: pointer;
                padding: 0 20px;
                &:hover {
                    background-color: #cccccc;
                }
            }
        }

        input,
        textarea,
        .custom-select {
            color: $light !important;

            ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $light;
                opacity: 1; /* Firefox */
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $light;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $light;
            }

            &.empty {
                color: rgba(255, 255, 255, 0.5) !important;
                border: 1px solid rgba(255, 255, 255, 0.5);

                &.alertRequired {
                    border: 1px solid $red;
                }

                ::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: rgba(255, 255, 255, 0.5);
                    opacity: 1; /* Firefox */
                }

                :-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: rgba(255, 255, 255, 0.5);
                }

                ::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: rgba(255, 255, 255, 0.5);
                }

                .custom-select-value:after {
                    opacity: 0.5;
                }
            }
        }

        span,
        .custom-select {
            //border: none;
            color: $light !important;
            &.empty {
                color: rgba(255, 255, 255, 0.5) !important;
            }
        }

        textarea {
            height: 200px;

            &.big {
                height: 285px;
            }
        }

        .checkbox {
            height: 20px;
            width: 20px;
            display: inline-block;
        }

        .checkbox-label {
            display: inline-block;
        }

        .form-button {
            margin: 20px 0;
            position: relative;

            .alert {
                color: $red;
                display: none;
                width: 100%;
                text-align: center;
                position: absolute;
                bottom: -30px;
                left: 0;
                font-weight: 600;

                @media (max-width: $screen-xs) {
                    bottom: -60px;
                }
            }

            &.showAlert {
                .alert {
                    display: block;
                }
            }
        }

        .captcha {
            display: flex;
            justify-content: center;
            padding: 20px 0;
        }

        @media (max-width: 1400px) {
            .half {
                display: block;
                width: 80vw;
                padding: 0;
            }

            input,
            textarea,
            .custom-select {
                width: calc(80vw - 30px);
                margin: 15px 0;

                &.checkbox {
                    margin: 15px;
                }
            }
        }
        @media (max-width: $screen-sm) {
            input,
            textarea,
            .custom-select {
                text-overflow: ellipsis;
            }
        }

        //AUTOFILL WONT CHANGE BG
        @-webkit-keyframes autofill {
            0%,
            100% {
                color: $light;
                background: transparent;
            }
        }

        input:-webkit-autofill {
            -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
            -webkit-animation-name: autofill;
            -webkit-animation-fill-mode: both;
        }

        //NUMBER SPINNERS DISABLED
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}
</style>
