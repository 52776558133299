<template>
    <div
        :id="homepages.rows[0].button.length > 2 ? 'home-plus' : 'home'"
        class="basic-page"
    >
        <Loading :show="!imgsLoaded" />

        <div v-show="imgsLoaded">
            <div
                v-for="homepage in homepages.rows"
                v-show="imgsLoaded"
                :key="homepage.id"
            >
                <ImageOptimized
                    :src-set="getSourceSet"
                    :src-set-sizes="srcSetSizes"
                    :default-img="defaultImg"
                    thumb-formats="webp,jpg"
                    type="homepage"
                    @loaded="loaded.bg = true"
                    @failed="loaded.bg = true"
                />
                <div id="home-central-content">
                    <img
                        class="vigor-switch-season-logo"
                        :src="
                            settings.webpSupport
                                ? '/static/images/home/logo.webp'
                                : '/static/images/home/logo.png'
                        "
                        draggable="false"
                        alt="Vigor Coming to Nintendo Switch"
                    />

                    <div class="title">{{ homepage.title }}</div>

                    <div class="button-group" :style="buttonGroup">
                        <div v-for="button in homepage.button" :key="button.id">
                            <LinkButton
                                v-if="button.type === 'Outside Link'"
                                :icon="button.icon"
                                :text="button.text"
                                :type="button.style"
                                :params="{ slug: `${button.params}` }"
                                :outside="true"
                                :link="button.link"
                            />
                            <LinkButton
                                v-if="button.type === 'Internal Link'"
                                :icon="button.icon"
                                :text="button.text"
                                :type="button.style"
                                :params="{ slug: `${button.params}` }"
                                :link="pages[button.link]"
                            />
                            <LinkButton
                                v-if="button.type === 'Download'"
                                :icon="button.icon"
                                :text="button.text"
                                :type="button.style"
                                :params="
                                    button.params
                                        ? { slug: `${button.params}` }
                                        : null
                                "
                                :download="true"
                                :link="button.link"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Popup
                :show="popupLoginCancel"
                title="Bohemia account"
                text="We need you to use an account in order to cancel your closed preview registration. Let's make sure we have the right e-mail."
                button-text="Log in"
                @closeMethod="loginCancelClose"
                @submitMethod="loginCancelSubmit"
            />
            <Popup
                :show="popupStressTestCancel"
                title="Closed preview"
                text="Do you really want to cancel your closed preview registration?"
                button-text="Cancel registration"
                @closeMethod="stressTestCancelClose"
                @submitMethod="stressTestCancelSubmit"
            />
            <Popup
                :show="popupSignedUpCancel"
                title="Your registration has been canceled"
                text="See you later!"
                @closeMethod="signedUpCancelClose"
            />
        </div>
    </div>
</template>

<script>
import constants from '@/constants';
import * as pages from '@/routes/pageCodes';
import { mapGetters } from 'vuex';
import LinkButton from '@/components/partials/LinkButton';
import { MailtrainResource } from '@/services/mailtrain';
import Loading from '@/components/partials/Loading';
import router from '@/routes';
import Popup from '@/components/partials/popups/Popup';
import PopupUniversal from '@/components/partials/popups/PopupUniversal';
import ImageOptimized from '@/components/partials/ImageOptimized';

const transformProperties = [
    'transform',
    'msTransform',
    'webkitTransform',
    'mozTransform',
    'oTransform',
];

const TYPE_LINK = 'TYPE_LINK';
const TYPE_POPUP = 'TYPE_POPUP';
const TYPE_POPUP_IMAGE = 'TYPE_POPUP_IMAGE';

export default {
    name: 'Home',
    components: {
        LinkButton,
        Loading,
        Popup,
        PopupUniversal,
        ImageOptimized,
    },
    data() {
        return {
            pages,
            popupLoginCancel: false,
            popupStressTestCancel: false,
            popupSignedUpCancel: false,
            popupUniversal: false,
            name: 'Home',
            window: { w: window.innerWidth, h: window.innerHeight },
            webp: document.body.classList.contains('webp'),
            loaded: {
                logo: false,
                bg: false,
            },
            TYPE_LINK,
            TYPE_POPUP,
            TYPE_POPUP_IMAGE,
        };
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        ...mapGetters('user', {
            user: 'getUser',
        }),
        ...mapGetters('homepages', {
            homepages: 'getHomepages',
        }),
        isStressTestCancelUrl() {
            return window.location.pathname === '/closedPreviewCancel';
        },
        imgsLoaded() {
            return this.loaded.bg;
        },
        desktopResolution() {
            return this.window.w >= 768;
        },
        videoUrl() {
            return '//www.youtube.com/embed/7aa03Wt-4SY?autoplay=1&amp;enablejsapi=1&amp;autohide=1&amp;showinfo=0&amp;rel=0';
        },
        getSourceSet() {
            let srcSet = this.homepages.rows[0].bgImage;
            //getting webp for single upload image
            let webpImg = srcSet.filter(
                img => img.format === 'webp' && !img.size
            );

            if (this.homepages.rows[0].bgImage.length > 2) {
                return srcSet;
            } else {
                return webpImg;
            }
        },
        srcSetSizes() {
            let sizes = [];
            let imgs = this.homepages.rows[0].bgImage;
            let sizedImgs = imgs.filter(obj => obj.size !== undefined);
            sizedImgs.forEach(img => {
                if (!sizes.includes(img.size)) {
                    sizes.push(img.size);
                }
            });
            return sizes;
        },
        defaultImg() {
            let imgs = this.homepages.rows[0].bgImage;
            let defaultImg;
            defaultImg = imgs.filter(img => img.format !== 'webp' && !img.size);
            defaultImg = defaultImg[0];

            return defaultImg;
        },
        buttonGroup() {
            let marginTop = this.homepages.rows[0].margin;
            if (this.window.w > 1500) {
                return { marginTop: marginTop };
            } else if (this.window.w > 480) {
                return { marginTop: '30px' };
            } else {
                return { marginTop: '0' };
            }
        },
    },
    watch: {
        user: function(val) {
            if (val) {
                this.maitranTest(val);
            }
        },
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        if (this.isStressTestCancelUrl) {
            if (this.user) {
                this.popupStressTestCancel = true;
            } else {
                this.popupLoginCancel = true;
            }
        }
        this.maitranTest(this.user);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        // popupUniversalShow(i) {
        //   this.homeItemsPopup = i;
        // },
        // popupUniversalClose() {
        //   this.homeItemsPopup = null;
        // },
        stressTestCancelClose() {
            this.popupStressTestCancel = false;
            if (this.isStressTestCancelUrl)
                router.push({ name: pages.HOME_PAGE_NAME });
        },
        stressTestCancelSubmit() {
            this.popupStressTestCancel = false;
            this.popupSignedUpCancel = true;
            MailtrainResource.unsubscribe({ email: this.user.emails[0].value });
        },
        signedUpCancelClose() {
            this.popupSignedUpCancel = false;
            if (this.isStressTestCancelUrl)
                router.push({ name: pages.HOME_PAGE_NAME });
        },
        loginCancelSubmit() {
            router.push({ name: pages.STRESS_TEST_CANCEL_PAGE_NAME });
            window.location.href = '/api/auth/login';
        },
        loginCancelClose() {
            this.popupLoginCancel = false;
        },

        maitranTest(val) {
            if (this.isStressTestCancelUrl) {
                if (val && val.emails) {
                    this.popupStressTestCancel = true;
                    this.popupLoginCancel = false;
                } else {
                    this.popupStressTestCancel = false;
                    this.popupLoginCancel = true;
                }
            }
            /*
                let thisComponent = this;
                MailtrainResource.test({ email: val.emails[0].value }).then(function(response) {
                    thisComponent.subscribedToStressTest = response.body;
                });
                */
        },

        //event listeners
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
        },
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

#home-plus {
    margin-top: 50px;
    height: auto;
    width: 100vw;
    position: relative;
    overflow: hidden;
    max-width: unset;

    @media (max-width: 669px) {
        height: 110vh;
    }

    .button-group {
        width: 100%;

        @media (max-width: 669px) {
            position: absolute;
            bottom: -230px;
        }

        @media (max-width: 480px) {
            position: absolute;
            bottom: -200px;
        }
    }

    #home-central-content {
        top: 0;
    }

    .vigor-switch-season-logo {
        width: 80%;
        margin-top: 190px;

        @media (min-width: 769px) and (max-width: 1500px) {
            margin-top: 100px;
        }

        @media (max-width: 1500px) {
            width: 50%;
            margin-top: 100px;
        }

        @media (max-width: 870px) {
            width: 50%;
            margin-top: 50px;
        }

        @media (max-width: 768px) {
            width: 40%;
            margin-top: 50px;
        }
        @media (max-width: 480px) {
            width: 40%;
            margin-top: 10%;
        }
    }
}
#home {
    margin-top: 50px;
    height: auto;
    width: 100vw;
    position: relative;
    overflow: hidden;
    max-width: unset;

    @media (max-width: 678px) {
        height: 70vh;
    }

    .button-group {
        width: 100%;

        @media (max-width: 600px) {
            position: absolute;
            bottom: -200px;
        }

        @media (max-width: 480px) {
            position: absolute;
            top: 60%;
        }
    }

    .vigor-switch-season-logo {
        width: 80%;
        margin-top: 60px;

        @media (min-width: 769px) and (max-width: 1500px) {
            margin-top: 40px;
        }

        @media (max-width: 1500px) {
            width: 50%;
        }

        @media (max-width: 768px) {
            width: 40%;
        }
        @media (max-width: 480px) {
            width: 40%;
            margin-top: 10%;
        }
    }
}

#home-central-content {
    width: 700px;
    max-width: 100vw;
    z-index: 20;
    text-align: center;
    position: absolute;
    left: 15%;
    transform: translate(-15%, 0);
    margin-bottom: 200px;
    top: 60px;

    @media (min-width: 1600px) {
        margin-bottom: 0;
        bottom: 50%;
        // top: 170px;
    }
    @media (max-width: 768px) {
        margin-bottom: 0;
        bottom: 50%;
        top: 0;
    }

    @media (max-width: 480px) {
        bottom: 10%;
    }
}

.title {
    font-size: clamp(1rem, 5vw, 3.5rem);
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'PT Sans', sans-serif;
    color: #ed1c24;

    @media (max-width: 480px) {
        font-size: 20px;
    }
}

.button-group > div {
    display: inline-block;
}
</style>
