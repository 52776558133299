<!-- this file has a duplicate in vigor-admin-nodejs-vuejs -->
<template>
    <div style="width: 100%; text-align: center; margin: 30px 0;">
        <template v-if="parsedUrl">
            <iframe
                v-if="fs"
                style="max-width: 100%; max-height: 45vw;"
                width="700px"
                height="394px"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
                :src="parsedUrl"
            ></iframe>
            <iframe
                v-else
                style="max-width: 100%; max-height: 45vw;"
                width="700px"
                height="394px"
                frameborder="0"
                allow="autoplay; encrypted-media"
                :src="parsedUrl"
            ></iframe>
        </template>
        <template v-if="!parsedUrl">
            no video url
        </template>
    </div>
</template>
<script>
import urlParser from 'js-video-url-parser';

const isNullOrUndefined = obj => {
    return (
        obj === null ||
        obj === undefined ||
        obj === 'null' ||
        obj === 'undefined'
    );
};

export default {
    name: 'EditorYoutube',
    props: {
        videourl: {
            type: String,
            default: null,
        },
        controls: {
            type: String,
            default: null,
        },
        fs: {
            type: String,
            default: null,
        },
        autoplay: {
            type: String,
            default: null,
        },
        showinfo: {
            type: String,
            default: null,
        },
        rel: {
            type: String,
            default: null,
        },
        start: {
            type: String,
            default: null,
        },
        end: {
            type: String,
            default: null,
        },
        loop: {
            type: String,
            default: null,
        },
        playlist: {
            type: String,
            default: null,
        },
        list: {
            type: String,
            default: null,
        },
        listtype: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            parsedUrl: null,
        };
    },
    mounted() {
        let data = urlParser.parse(this.videourl);
        if (data && data.mediaType === 'video') {
            this.parsedUrl = urlParser.create({
                videoInfo: data,
                format: 'embed',
            });

            const controls =
                this.controls == 'true' ? '?controls=1' : '?controls=0';
            const fs = this.fs == 'true' ? '&amp;fs=1' : '&amp;fs=0';
            const autoplay =
                this.autoplay == 'true' ? '&amp;autoplay=1' : '&amp;autoplay=0';
            const showinfo =
                this.showinfo == 'true' ? '&amp;showinfo=1' : '&amp;showinfo=0';
            const rel = this.rel == 'true' ? '&amp;rel=1' : '&amp;rel=0';
            const start = this.start ? `&amp;start=${this.start}` : '';
            const end = this.end ? `&amp;end=${this.end}` : '';
            const loop = this.loop ? '&amp;loop=1' : '&amp;loop=0';
            let playlist = '';
            if (!isNullOrUndefined(this.playlist)) {
                playlist = `&amp;playlist=${this.playlist}`;
            } else if (
                this.loop == 'true' &&
                isNullOrUndefined(this.playlist) &&
                !this.list
            ) {
                playlist = `&amp;playlist=${data.id}`;
            }
            const list = !isNullOrUndefined(this.list)
                ? `&amp;list=${this.list}`
                : '';
            const listtype = !isNullOrUndefined(this.listtype)
                ? `&amp;listType=${this.listtype}`
                : '';
            this.parsedUrl += `${controls}${fs}${autoplay}${showinfo}${rel}${start}${end}${loop}${playlist}${list}${listtype}`;
        } else {
            console.error('No video url recoginzed');
        }
    },
};
</script>
