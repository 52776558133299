<template>
    <div v-if="simple">
        <template v-for="link in links">
            <a
                :key="link.name"
                class="a-text"
                :class="{ dark: darkSimple }"
                :href="link.href"
                target="_blank"
                rel="noopener noreferrer"
            >
                {{ link.name }}
            </a>
            <br :key="link.name + 'br'" />
        </template>
    </div>
    <div v-else class="social-links">
        <a
            v-for="link in links"
            :key="link.name"
            class="icon-link no-underline"
            :class="iconStyle"
            :href="link.href"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                style="width: 30px; height: 30px; "
                :src="imgPath + (light ? link.imgW : link.img)"
            />
        </a>
    </div>
</template>

<script>
const imgPath = '/static/images/social/';
const links = [
    {
        img: 'steam.svg',
        imgW: 'steam-w.svg',
        href: 'https://feedback.bistudio.com/project/view/80/',
        name: 'Steam',
    },
    {
        img: 'xbox.svg',
        imgW: 'xbox-w.svg',
        href: 'https://feedback.bistudio.com/project/view/81/',
        name: 'Xbox',
    },
    {
        img: 'playstation.svg',
        imgW: 'playstation-w.svg',
        href: 'https://feedback.bistudio.com/project/view/82/',
        name: 'PlayStation',
    },

    {
        img: 'nintendo.svg',
        imgW: 'nintendo-w.svg',
        href: 'https://feedback.bistudio.com/project/view/83/',
        name: 'Nintendo Switch',
    },
];

export default {
    name: 'FeedbackLinks',
    props: {
        iconStyle: {
            type: String,
        },
        simple: {
            type: Boolean,
            default: false,
        },
        darkSimple: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            imgPath,
            links,
        };
    },
};
</script>

<style scoped lang="scss" media="print">
@import './../../styles/colors.scss';
@import './../../styles/global.scss';

.social-links {
    margin-left: -5px;

    .icon-link {
        margin-right: 15px;
        opacity: 1;

        img {
            height: 23px;
        }

        &:hover {
            opacity: 0.8;
        }

        &:last-child {
            margin: 0;
        }

        &.bigger {
            margin-right: 35px;

            img {
                height: 31px;
            }
        }

        &.biggest {
            margin-right: 18px;
            img {
                height: 35px;
            }
        }

        &.medium-margin {
            margin-right: 20px;
        }

        &.small-margin {
            margin-right: 15px;
        }

        &.tiny-margin {
            margin-right: 10px;
        }

        @media (max-width: 768px) {
            //tablet resolution
            margin-right: 30px;
            img {
                height: 44px;
            }
            &.bigger {
                margin-right: 20px;
            }
        }

        &.darker {
            opacity: 0.8;
        }
    }
    @media (max-width: 1150px) {
        margin-left: 10px;
    }
}
</style>
