<template>
    <div v-if="simple">
        <template v-for="link in links">
            <a
                :key="link.name"
                class="a-text"
                :class="{ dark: darkSimple }"
                :href="link.href"
                target="_blank"
                rel="noopener noreferrer"
            >
                {{ link.name }}
            </a>
            <br :key="link.name + 'br'" />
        </template>
    </div>
    <div v-else class="social-links">
        <a
            v-for="link in links"
            :key="link.name"
            class="icon-link no-underline"
            :class="iconStyle"
            :href="link.href"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img :src="imgPath + (light ? link.imgW : link.img)" />
        </a>
    </div>
</template>

<script>
const imgPath = '/static/images/social/';

const links = [
    {
        img: 'fb.svg',
        imgW: 'fb-w.svg',
        href: 'https://www.facebook.com/vigorthegame',
        name: 'Facebook',
    },
    {
        img: 'tw.svg',
        imgW: 'tw-w.svg',
        href: 'https://twitter.com/vigorthegame',
        name: 'Twitter',
    },
    {
        img: 'inst.svg',
        imgW: 'inst-w.svg',
        href: 'https://www.instagram.com/vigorthegame',
        name: 'Instagram',
    },
    {
        img: 'yt.svg',
        imgW: 'yt-w.svg',
        href: 'https://www.youtube.com/c/Vigorthegame',
        name: 'YouTube',
    },
    {
        img: 'twitch.svg',
        imgW: 'twitch-w.svg',
        href: 'https://www.twitch.tv/vigorthegame',
        name: 'Twitch',
    },
    {
        img: 'discord.svg',
        imgW: 'discord-w.svg',
        href: 'https://discord.gg/RBWJVsy',
        name: 'Discord',
    },
    {
        img: 'reddit.svg',
        imgW: 'reddit-w.svg',
        href: 'https://www.reddit.com/r/vigorgame/',
        name: 'Reddit',
    },
    {
        img: 'tiktok.svg',
        imgW: 'tiktok-w.svg',
        href: 'https://www.tiktok.com/@vigorthegame',
        name: 'Tiktok',
    },
];

export default {
    name: 'SocialLinks',
    props: {
        iconStyle: {
            type: String,
        },
        light: {
            type: Boolean,
            default: false,
        },
        simple: {
            type: Boolean,
            default: false,
        },
        darkSimple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            imgPath,
            links,
        };
    },
};
</script>

<style scoped lang="scss" media="print">
@import './../../styles/colors.scss';
@import './../../styles/global.scss';

.social-links {
    margin-left: -5px;

    .icon-link {
        margin-right: 15px;
        opacity: 1;

        img {
            height: 23px;
        }

        &:hover {
            opacity: 0.8;
        }

        &:last-child {
            margin: 0;
        }

        &.bigger {
            margin-right: 35px;

            img {
                height: 31px;
            }
        }

        &.biggest {
            margin-right: 18px;
            img {
                height: 35px;
            }
        }

        &.medium-margin {
            margin-right: 20px;
        }

        &.small-margin {
            margin-right: 15px;
        }

        &.tiny-margin {
            margin-right: 10px;
        }

        @media (max-width: 768px) {
            //tablet resolution
            margin-right: 30px;
            img {
                height: 44px;
            }
            &.bigger {
                margin-right: 20px;
            }
        }

        &.darker {
            opacity: 0.8;
        }
    }
    @media (max-width: 1150px) {
        margin-left: 10px;
    }
}
</style>
