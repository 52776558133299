<template>
    <div class="entry-wrapper">
        <div v-if="!sidebar" class="entry">
            <router-link exact :to="{ name: linkName, params: linkParams }">
                <div class="entry-content-wrapper">
                    <div class="entry-image">
                        <img v-if="img" :src="img" draggable="false" />
                    </div>
                    <div class="entry-content">
                        <div class="date">{{ date }}</div>
                        <div class="entry-title" :class="{ small: !featured }">
                            {{ title }}
                        </div>
                        <div
                            v-if="!sidebar"
                            class="entry-description"
                            v-html="excerpt"
                        ></div>
                        <div class="entry-title__bottom-row">
                            <div class="entry-tags">
                                <div v-for="(tag, id) in shownTags" :key="id">
                                    <img
                                        :src="tag"
                                        :class="{
                                            'entry-tag--sidebar': sidebar,
                                        }"
                                        class="entry-tag"
                                    />
                                </div>
                            </div>
                            <div class="button">
                                <LinkButton
                                    :text="button"
                                    type="dark-red extra-thin"
                                    :link="linkName"
                                    :params="linkParams"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
        <div v-else class="entry-sidebar">
            <router-link exact :to="{ name: linkName, params: linkParams }">
                <div class="entry-content-wrapper">
                    <div class="entry-image">
                        <img v-if="img" :src="img" draggable="false" />
                    </div>
                    <div class="entry-content">
                        <div class="entry-title">
                            {{ title }}
                        </div>
                        <div class="entry-title__bottom-row">
                            <div class="date">{{ date }}</div>
                            <div class="entry-tags">
                                <div v-for="(tag, id) in shownTags" :key="id">
                                    <img :src="tag" class="entry-tag" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import LinkButton from '@/components/partials/LinkButton';

//SVGs
import XboxTagIcon from 'Images/tags/xbox_tag.svg';
import SwitchTagIcon from 'Images/tags/switch_tag.svg';
import PlaystationTagIcon from 'Images/tags/playstation_tag.svg';

export default {
    name: 'Card',
    components: { LinkButton },
    props: {
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        button: {
            type: String,
            default: 'OK',
        },
        date: {
            type: String,
            default: null,
        },
        img: {
            type: String,
            default: null,
        },
        linkName: {
            type: String,
            default: null,
        },
        linkParams: {
            type: Object,
            default: null,
        },
        show: { type: Boolean, default: false },
        featured: { type: Boolean, default: false },
        sidebar: { type: Boolean, default: false },
        excerpt: { type: String, default: '' },
        tags: { type: String, default: '' },
    },
    computed: {
        xboxTagIcon() {
            return XboxTagIcon;
        },
        switchTagIcon() {
            return SwitchTagIcon;
        },
        PlaystationTagIcon() {
            return PlaystationTagIcon;
        },
        shownTags() {
            let shownTags = [];
            let tags = [];

            if (this.tags) {
                tags = JSON.parse(this.tags);
            }

            if (tags.length > 0 && this.tags) {
                tags.forEach(element => {
                    if (element === 'Xbox') {
                        shownTags.push(XboxTagIcon);
                    } else if (element === 'Switch') {
                        shownTags.push(SwitchTagIcon);
                    } else {
                        shownTags.push(PlaystationTagIcon);
                    }
                });

                return shownTags;
            } else {
                return '';
            }
        },
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

.entry {
    position: relative;
    max-width: 1365px;
    width: 100%;
    height: auto;

    background-color: #252525;

    a {
        width: 100%;
        height: 100%;
        color: $light;
    }

    .entry-content-wrapper {
        display: flex;
        flex-direction: row;
        position: relative;

        .entry-image {
            flex-shrink: 1;
            width: 100%;
            height: auto;
            object-fit: contain;
            position: relative;

            img {
                width: 100%;
                height: auto;
                vertical-align: middle;
            }
        }

        .entry-content {
            z-index: 2;
            position: relative;
            padding: 2%;
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;

            .date {
                color: $red;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
            }
            .entry-title {
                font-size: 36px;
                font-weight: 700;
                text-transform: uppercase;
                margin: 3% 0 5% 0;

                &.small {
                    font-size: 24px;
                }

                @media (max-width: 1200px) {
                    font-size: 24px;
                }
            }

            .entry-description {
                font-size: 18px;
                font-weight: 400;

                /deep/ p {
                    margin: 0px;
                }
            }

            .entry-title__bottom-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: auto;

                .entry-tags {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .entry-tag {
                        max-width: 26px;
                        width: 100%;
                        height: auto;
                        padding-right: 20px;
                        fill: #ffffff;
                    }
                }
            }
        }
    }
    @media (max-width: 1200px) {
        .entry-content-wrapper {
            .entry-content {
                padding: 17px 26px;
                display: flex;
                flex-direction: column;

                .entry-title__bottom-row {
                    margin-top: auto;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .entry-content-wrapper {
            .entry-content {
                position: absolute;
                bottom: 20px;
                left: 20px;
                right: 20px;
                padding: 0;
                width: auto;

                .date {
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                }

                .entry-title {
                    font-size: 24px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin: 5px 0 10px 0;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .entry-description {
                    font-size: 18px;
                    font-weight: 400;
                    margin: 0;
                }
            }
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                background-image: linear-gradient(
                    to top,
                    #252525 25%,
                    transparent 100%
                );
                height: 75%;
                width: 100%;
            }
        }
    }

    @media (max-width: 768px) {
        .entry-content {
            .entry-description {
                display: none;
            }
        }
    }

    @media (max-width: 480px) {
        .entry-content {
            .entry-title {
                font-size: 24px;
                font-weight: 700;
                text-transform: uppercase;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
.entry-sidebar {
    position: relative;
    max-width: 439px;
    width: 100%;
    height: auto;

    background-color: #252525;

    a {
        width: 100%;
        height: 100%;
        color: $light;
    }
    .entry-content-wrapper {
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 23px 12px 21px 23px;

        .entry-image {
            width: 100%;
            height: auto;
            object-fit: contain;
            position: relative;

            img {
                max-width: 161px;
                width: 100%;
                height: auto;
            }
        }

        .entry-content {
            z-index: 2;
            position: relative;
            width: 100%;
            height: auto;
            padding-left: 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .entry-title {
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 23px;
            }

            .entry-title__bottom-row {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .date {
                    color: $red;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                }

                .entry-tags {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .entry-tag {
                        max-width: 20px;
                        width: 100%;
                        height: auto;
                        padding-left: 10px;
                        fill: #ffffff;
                    }
                }
            }
        }
    }
}
</style>
