'use strict';
import { PartnershipResource } from '@/services/partnership';
import Vue from 'vue';
import * as types from '../mutation-types';

const state = {
    data: null,
};

const getters = {
    getPartnerships: state => {
        return state.data;
    },
};

const actions = {
    loadPartnerships({ commit }) {
        return PartnershipResource.get()
            .then(response => {
                commit(types.RECEIVE_PARTNERSHIP, response.data.rows[0]);
            })
            .catch(err => {
                commit(types.RECEIVE_PARTNERSHIP, {});
                console.log(err);
            });
    },
};

const mutations = {
    [types.RECEIVE_PARTNERSHIP](state, val) {
        Vue.set(state, 'data', val);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
