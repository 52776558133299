<template>
    <div
        v-show="show"
        class="entry"
        :class="{ padding: padding, disabled: disabledText }"
    >
        <a
            v-if="download"
            :href="linkName"
            target="_blank"
            rel="noopener noreferrer"
            download
        >
            <img :src="img" draggable="false" />
            <div class="gradient"></div>
            <div v-if="!disabledText" class="entry-content">
                <div class="entry-title">{{ title }}</div>
                <div class="button">
                    <LinkButton
                        :text="button"
                        type="red news"
                        :link="linkName"
                        :outside="true"
                    />
                </div>
            </div>
            <div class="date">{{ date }}</div>
        </a>
        <a
            v-else-if="event"
            :href="linkName"
            target="_blank"
            rel="noopener noreferrer"
            download
        >
            <img :src="img" draggable="false" />
            <div class="gradient"></div>
            <div v-if="!disabledText" class="entry-content">
                <div class="entry-title">{{ title }}</div>
                <div class="button">
                    <LinkButton
                        :text="button"
                        type="red news"
                        :link="linkName"
                        :event="true"
                        @eventMethod="clickMethod"
                    />
                </div>
            </div>
            <div class="date">{{ date }}</div>
        </a>

        <router-link v-else exact :to="{ name: linkName, params: linkParams }">
            <img :src="img" draggable="false" />
            <div class="gradient"></div>
            <div v-if="!disabledText" class="entry-content">
                <div class="entry-title">{{ title }}</div>
                <div class="button">
                    <LinkButton
                        :text="button"
                        type="red news"
                        :link="linkName"
                        :params="linkParams"
                    />
                </div>
            </div>
            <div class="date">{{ date }}</div>
        </router-link>

        <div v-if="disabledText" class="disabled-text">
            {{ disabledText }}
        </div>
    </div>
</template>

<script>
import LinkButton from '@/components/partials/LinkButton';

export default {
    name: 'Card',
    components: { LinkButton },
    props: {
        title: {
            type: String,
            default: null,
        },
        button: {
            type: String,
            default: 'OK',
        },
        date: {
            type: String,
            default: null,
        },
        img: {
            type: String,
            default: null,
        },
        show: {
            type: Boolean,
            default: true,
        },

        download: {
            type: Boolean,
            default: false,
        },
        linkName: {
            type: String,
            default: null,
        },
        linkParams: {
            type: Object,
            default: null,
        },
        padding: {
            type: Boolean,
            default: true,
        },
        event: {
            type: Boolean,
            default: false,
        },
        disabledText: {
            type: String,
            default: null,
        },
    },
    methods: {
        clickMethod() {
            this.$emit('eventMethod');
        },
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

.entry {
    width: 450px;
    max-width: 77vw;
    height: 270px;
    max-height: 50vw;
    padding: 0;
    display: block;
    position: relative;
    cursor: pointer;

    a {
        width: 100%;
        height: 100%;
        color: $light;
    }

    img,
    .gradient,
    .entry-content {
        position: absolute;
        display: block;
        width: inherit;
        max-width: inherit;
        max-height: inherit;
    }

    img {
        left: 0;
        bottom: 0;
        max-height: 48vw;
        height: inherit;
        transition: filter 0.25s linear;
    }

    .gradient {
        background: linear-gradient(transparent 50%, black 100%);
        height: inherit;
        left: 0;
        bottom: 0;
    }

    &.disabled {
        pointer-events: none;
        display: table;
        position: relative;

        .gradient {
            background: rgba(0, 0, 0, 0.33);
        }

        .disabled-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 100%;
            text-align: center;
            vertical-align: middle;
            display: table-cell;
            color: white;
            font-size: 40px;
            text-transform: uppercase;
            position: relative;
        }
    }

    .entry-content {
        display: block;
        padding: 15px;
        //height: initial;
        width: calc(100% - 28px - 30px);
        left: 0;
        bottom: 0;

        .entry-title {
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.2px;
            margin: 15px 0;
        }
    }

    .date {
        position: absolute;
        right: 15px;
        bottom: 15px;
        padding: 15px;
        margin: 15px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.12px;
    }

    &.padding {
        @media (min-width: 600px) {
            padding: 15px;

            a {
                width: calc(100% - 30px);
                height: calc(100% - 30px);
            }

            img {
                left: 15px;
                bottom: 15px;
            }

            .entry-content {
                left: 15px;
                bottom: 15px;
            }

            .gradient {
                left: 15px;
                bottom: 15px;
            }
        }
    }

    &:hover {
        img {
            filter: brightness(60%);
        }
    }

    @media (max-width: 600px) {
        img {
            filter: brightness(60%);
        }

        padding: 15px;
        width: calc(77vw - 30px);
        max-width: calc(77vw - 30px);
        height: calc(50vw - 30px);
        max-height: calc(50vw - 30px);
    }

    @media (max-width: 350px) {
        display: block;
        padding: 15px;
        width: calc(77vw - 30px);
        max-width: calc(77vw - 30px);
        height: calc(50vw - 30px);
        max-height: calc(50vw - 30px);

        .entry-content {
            display: block;
            position: absolute;
            //padding: 15px;
            //height: initial;
            padding: 10px;
            margin: 0;
            bottom: 20px;
            width: calc(100% - 20px);

            .entry-title {
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.2px;
                margin: 5px 0;
                padding: 15px 0 0 0;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .date {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px;
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.12px;
        }
    }
}
</style>
