<template>
    <div
        id="popup-layer"
        class="basic-content"
        :class="{ show: show, hide: !show }"
        @click="closeMethod"
        @mousemove="block"
    >
        <div
            id="popup-window"
            :class="{ webp: settings.webpSupport }"
            @click="stopPropagation"
        >
            <div class="popup-content">
                <div class="popup-title">{{ title }}</div>
                <div class="popup-text">{{ text }}</div>
                <div v-if="buttonText" class="popup-button">
                    <LinkButton
                        :text="buttonText"
                        type="red minWidth"
                        :event="true"
                        @eventMethod="submitMethod"
                    />
                </div>
                <div class="popup-close" @click="closeMethod"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import LinkButton from '@/components/partials/LinkButton';
import { mapGetters } from 'vuex';

export default {
    name: 'Popup',
    components: { LinkButton },
    props: {
        title: {
            type: String,
        },
        text: {
            type: String,
        },
        buttonText: {
            type: String,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            pages,
        };
    },
    methods: {
        closeMethod() {
            this.$emit('closeMethod');
        },
        submitMethod() {
            this.$emit('submitMethod');
        },
        block(event) {
            event.preventDefault();
            event.stopPropagation();
        },
        stopPropagation(event) {
            event.stopPropagation();
        },
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../../styles/colors.scss';
@import '../../../styles/popups.scss';
</style>
