<template>
    <div id="loading" :class="{ show: show, hide: !show }">
        <img src="/static/images/timer.png" draggable="false" />
    </div>
</template>

<script>
export default {
    name: 'Loading',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
#loading {
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 400;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;

    &.hide {
        transition: visibility 0s linear 0.25s, opacity 0.25s linear;
        visibility: hidden;
        opacity: 0;
    }

    &.show {
        transition: visibility 0s, opacity 0.25s linear;
        visibility: visible;
        opacity: 1;
    }

    img {
        animation-name: spin;
        animation-duration: 1500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        position: fixed;
        top: calc(50vh - 55px);
        left: calc(50vw - 55px);
        z-index: 401;

        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }
}
</style>
