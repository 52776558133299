<template>
    <div>
        <LinkButton
            v-if="!subscribedToNewsletter"
            :text="text"
            :type="type"
            wrapper-type="noPadding"
            icon="newsletter"
            :event="true"
            :padding="padding"
            @eventMethod="subscribeNewsletter"
        />

        <!-- sign up -->
        <PopupNewsletter
            :show="popupNewsletter"
            :user="user"
            @closeMethod="newsletterClose"
            @submitMethod="newsletterSubmit"
        />
    </div>
</template>

<script>
import LinkButton from '@/components/partials/LinkButton';
import PopupNewsletter from '@/components/partials/popups/PopupNewsletter';
import router from '../../routes';
import { mapGetters } from 'vuex';
import * as pages from '@/routes/pageCodes';

export default {
    name: 'NewsletterButton',
    components: { LinkButton, PopupNewsletter },
    props: {
        text: {
            type: String,
        },
        type: {
            type: String,
            default: 'red',
        },
        padding: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            subscribedToNewsletter: false,
            popupNewsletter: false,
            popupNewsletterCancel: false,
            name: pages.HOME_PAGE_NAME,
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'getUser',
        }),
        isNewsletterUrl() {
            return window.location.pathname === '/newsletter';
        },
    },
    mounted() {
        this.name = this.$route.name;
        if (this.name == pages.NEWSLETTER_PAGE_NAME) {
            this.name = pages.HOME_PAGE_NAME;
        }
    },
    methods: {
        subscribeNewsletter() {
            this.popupNewsletter = true;
        },
        newsletterClose() {
            this.popupNewsletter = false;
            if (this.isNewsletterUrl) router.push({ name: this.name });
        },
        newsletterSubmit() {
            this.popupNewsletter = false;
        },
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

span {
    display: block;
    margin: 0 0 8px 0;
    padding: 0;
}
</style>
