import { render, staticRenderFns } from "./FooterBasic.vue?vue&type=template&id=2f610744&scoped=true"
import script from "./FooterBasic.vue?vue&type=script&lang=js"
export * from "./FooterBasic.vue?vue&type=script&lang=js"
import style0 from "./FooterBasic.vue?vue&type=style&index=0&id=2f610744&prod&scoped=true&lang=scss&media=print"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f610744",
  null
  
)

export default component.exports