var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"mc_embed_signup"}},[_c('form',{staticClass:"validate",attrs:{"id":"mc-embedded-subscribe-form","action":"https://vigorgame.us13.list-manage.com/subscribe/post?u=002ddcf49421d23278cc3bef4&id=188dfc17e6","method":"post","name":"mc-embedded-subscribe-form","target":"_blank","novalidate":""}},[_c('div',{attrs:{"id":"mc_embed_signup_scroll"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"clear form-button"},[_c('LinkButton',{attrs:{"text":"Subscribe","type":"red minWidth","form":true,"value":"Subscribe","name":"subscribe"}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"indicates-required"},[_c('span',{staticClass:"asterisk"},[_vm._v("*")]),_vm._v(" indicates required ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mc-field-group"},[_c('label',{attrs:{"for":"mce-EMAIL"}},[_vm._v("Email Address "),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]),_c('input',{staticClass:"required email",attrs:{"id":"mce-EMAIL","type":"email","value":"","name":"EMAIL"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mc-field-group input-group"},[_c('h2',{staticClass:"capitals"},[_vm._v("Platforms")]),_c('ul',[_c('li',[_c('input',{attrs:{"id":"mce-group[17317]-17317-0","type":"checkbox","value":"1","name":"group[17317][1]"}}),_c('label',{attrs:{"for":"mce-group[17317]-17317-0"}},[_vm._v("Nintendo Switch")])]),_c('li',[_c('input',{attrs:{"id":"mce-group[17317]-17317-1","type":"checkbox","value":"2","name":"group[17317][2]"}}),_c('label',{attrs:{"for":"mce-group[17317]-17317-1"}},[_vm._v("Xbox")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mergeRow gdpr-mergeRow content__gdprBlock mc-field-group",attrs:{"id":"mergeRow-gdpr"}},[_c('div',{staticClass:"content__gdpr"},[_c('h2',{staticClass:"capitals"},[_vm._v("Marketing Permissions")]),_c('p',{staticClass:"text"},[_vm._v(" Please select all the ways you would like to hear from Bohemia Interactive a.s. and Vigor Team: ")]),_c('fieldset',{staticClass:"mc_fieldset gdprRequired mc-field-group",attrs:{"name":"interestgroup_field"}},[_c('label',{staticClass:"checkbox subfield",attrs:{"for":"gdpr_28773"}},[_c('input',{staticClass:"av-checkbox gdpr",attrs:{"id":"gdpr_28773","type":"checkbox","name":"gdpr[28773]","value":"Y"}}),_c('span',{staticClass:"checkbox-label"},[_vm._v("Update News, Dev News, Community Spotlight, Sales, Events etc.")])])]),_c('p',{staticClass:"text"},[_vm._v(" You can unsubscribe at any time by clicking the link in the footer of our emails. For information about our privacy practices, please visit our website. ")])]),_c('div',{staticClass:"content__gdprLegal"},[_c('p',{staticClass:"text"},[_vm._v(" We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. "),_c('br'),_c('br'),_c('a',{staticClass:"a-text",attrs:{"href":"https://mailchimp.com/legal/","target":"_blank"}},[_vm._v("Learn more about Mailchimp's privacy practices here.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clear",attrs:{"id":"mce-responses"}},[_c('div',{staticClass:"response",staticStyle:{"display":"none"},attrs:{"id":"mce-error-response"}}),_c('div',{staticClass:"response",staticStyle:{"display":"none"},attrs:{"id":"mce-success-response"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"absolute","left":"-5000px"},attrs:{"aria-hidden":"true"}},[_c('input',{attrs:{"type":"text","name":"b_002ddcf49421d23278cc3bef4_188dfc17e6","tabindex":"-1","value":""}})])
}]

export { render, staticRenderFns }