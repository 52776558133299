<template>
    <div id="community" class="basic-page">
        <Loading :show="!dataLoaded" />
        <div v-show="dataLoaded" class="all-content">
            <div
                class="static-bg"
                :class="{ hidden: settings && settings.apple }"
            >
                <ImageOptimized
                    code="placeholder/news/bg-news"
                    thumb-formats="webp,jpg"
                    thumb-sizes="480,768,992,1200"
                    type="static-bg"
                    @loaded="loaded.staticBg = true"
                    @failed="loaded.staticBg = true"
                />
            </div>
            <div id="community-content" class="section">
                <div class="page-title">COMMUNITY</div>
                <p class="section-text">
                    Hei Outlander!
                    <br />
                    You, as a member of our community, are the key aspect of
                    Vigor.
                    <br />
                    Take part in the discussion, help us through constructive
                    feedback and share your creations!
                    <br />
                    <br />
                    <b>Join us in the apocalypse on your favourite platform!</b>
                </p>
                <div class="all-link-buttons">
                    <div
                        v-for="link in links"
                        :key="link.text"
                        class="link-button"
                    >
                        <LinkButton
                            :img="true"
                            :text="link.text"
                            type="dark-red noPadding community-links"
                            :outside="true"
                            :icon="link.icon"
                            :link="link.link"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import LinkButton from '@/components/partials/LinkButton';
import Loading from '@/components/partials/Loading';
import { mapGetters } from 'vuex';
import ImageOptimized from '@/components/partials/ImageOptimized';

const links = [
    {
        icon: 'community-icon forums',
        link: 'https://forums.bohemia.net/forums/forum/251-vigor/',
        text: 'Forums',
    },
    {
        icon: 'community-icon fb-w',
        link: 'https://www.facebook.com/vigorthegame',
        text: 'Facebook',
    },
    {
        icon: 'community-icon tw-w',
        link: 'https://twitter.com/vigorthegame',
        text: 'Twitter',
    },
    {
        icon: 'community-icon inst-w',
        link: 'https://www.instagram.com/vigorthegame',
        text: 'Instagram',
    },
    {
        icon: 'community-icon yt-w',
        link: 'https://www.youtube.com/c/Vigorthegame',
        text: 'YouTube',
    },
    {
        icon: 'community-icon twitch-w',
        link: 'https://www.twitch.tv/vigorthegame',
        text: 'Twitch',
    },
    {
        icon: 'community-icon tiktok-w',
        link: 'https://www.tiktok.com/@vigorthegame',
        text: 'Tiktok',
    },
    {
        icon: 'community-icon reddit-w',
        link: 'https://www.reddit.com/r/vigorgame/',
        text: 'Reddit',
    },
    {
        icon: 'community-icon discord-w',
        link: 'https://discord.gg/RBWJVsy',
        text: 'Discord',
    },
];
export default {
    name: 'Community',
    components: {
        LinkButton,
        Loading,
        ImageOptimized,
    },
    data() {
        return {
            pages,
            loaded: {
                staticBg: false,
            },
            window: { w: window.innerWidth, h: window.innerHeight },
            links,
        };
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        dataLoaded() {
            return this.loaded.staticBg;
        },
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/placeholder/news/bg-news.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

#community {
    position: relative;
    width: 100vw;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .all-content {
        margin: auto;
        padding-left: 5vw;

        @media (max-width: 1200px) {
            padding-left: 0;
        }

        #community-content {
            padding-bottom: 100px;
            padding-top: 125px;

            @media (max-width: 700px) and (max-width: 1200px) {
                padding-top: 90px;
            }

            .page-title {
                padding: 15px 35px;
            }

            .section-text {
                color: #ffffff;
                font-family: 'PT Sans';
                font-size: 18px;
                text-align: left;
                font-family: 'PT Sans - Regular';
                font-weight: 400;
                padding: 15px 35px;

                @media (min-width: 993px) and (max-width: 1200px) {
                    width: 970px;
                }
                @media (min-width: 800px) and (max-width: 992px) {
                    width: 700px;
                }
            }

            .all-link-buttons {
                display: flex;
                flex-flow: row wrap;

                .link-button {
                    display: inline-block;
                    margin: 15px;

                    @media (max-width: 1100px) {
                        margin-bottom: 10px;
                    }

                    @media (max-width: 360px) {
                        margin: 0 0 10px 0;
                    }
                }
            }
        }
    }
}
</style>
