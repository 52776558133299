<template>
    <div id="news" class="basic-page">
        <Loading :show="!dataLoaded" />
        <div v-show="dataLoaded">
            <div
                class="static-bg"
                :class="{ hidden: settings && settings.apple }"
            >
                <ImageOptimized
                    code="placeholder/news/bg-news"
                    thumb-formats="webp,jpg"
                    thumb-sizes="480,768,992,1200"
                    type="static-bg"
                    @loaded="loaded.staticBg = true"
                    @failed="loaded.staticBg = true"
                />
            </div>
            <div id="news-content" class="section">
                <div class="section-content">
                    <div class="page-title">LATEST NEWS</div>
                    <div
                        v-if="articles && articles.rows"
                        class="content-wrapper"
                    >
                        <CardV2
                            v-if="featuredNews"
                            :key="featuredNews.id"
                            :title="featuredNews.name"
                            :date="
                                dateInArticleFormat(featuredNews.published_at)
                            "
                            button="Read more"
                            :img="featuredNews.previewImage"
                            :link-name="pages.NEWS_DETAIL_PAGE_NAME"
                            :link-params="{ slug: featuredNews.slug }"
                            :excerpt="featuredNews.excerpt"
                            :tags="featuredNews.tags"
                            :featured="true"
                        />
                        <div class="separator"></div>
                        <div class="content">
                            <template v-for="(entry, index) in getArticles">
                                <CardV2
                                    v-if="index < shownEntries"
                                    :key="entry.index"
                                    :title="entry.name"
                                    :date="
                                        dateInArticleFormat(entry.published_at)
                                    "
                                    button="Read more"
                                    :img="entry.previewImage"
                                    :link-name="pages.NEWS_DETAIL_PAGE_NAME"
                                    :link-params="{ slug: entry.slug }"
                                    :tags="entry.tags"
                                    :excerpt="entry.excerpt"
                                    class="entry"
                                />
                            </template>
                            <div
                                v-if="shownEntries < articles.rows.length"
                                class="more-button"
                            >
                                <LinkButton
                                    text="LOAD MORE"
                                    type="red"
                                    :event="true"
                                    @eventMethod="moreNews"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import LinkButton from '@/components/partials/LinkButton';
import Loading from '@/components/partials/Loading';
import CardV2 from '@/components/partials/CardV2';
import { mapGetters } from 'vuex';
import formats from '@/formats';
import { format, parseISO } from 'date-fns';
import ImageOptimized from '@/components/partials/ImageOptimized';

export default {
    name: 'News',
    components: {
        LinkButton,
        Loading,
        ImageOptimized,
        CardV2,
    },
    data() {
        return {
            pages,
            shownEntries: 6,
            loaded: {
                staticBg: false,
            },
            window: { w: window.innerWidth, h: window.innerHeight },
            // articles: []
        };
    },
    computed: {
        ...mapGetters('articles', {
            articles: 'getArticles',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        dataLoaded() {
            return this.loaded.staticBg && this.articles && this.articles.rows;
        },
        featuredNews() {
            let featured;

            if (this.articles) {
                featured = this.articles.rows.find(
                    obj => obj.featured === true
                );
                return featured;
            } else {
                return {};
            }
        },
        getArticles() {
            let articlesNoFeature = [];

            if (this.articles && this.articles.rows[0].featured === true) {
                articlesNoFeature = this.articles.rows.filter(
                    obj => obj.featured === false
                );
                return articlesNoFeature;
            } else if (this.articles) {
                return this.articles.rows;
            } else return {};
        },
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        moreNews() {
            this.shownEntries += 3;
        },
        dateInArticleFormat(date) {
            return format(parseISO(date), formats.ARTICLE_FORMAT);
        },
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/placeholder/news/bg-news.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

#news {
    position: relative;
    width: 100vw;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #news-content {
        padding-bottom: 100px;

        .section-content {
            padding-top: 125px;

            .separator {
                position: relative;
                width: 100%;
                height: 5vh;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    border-top: 2px solid #ed2024;
                    height: 50%;
                    max-width: 385px;
                    width: 100%;
                }
            }
            .content-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    max-width: 978px;
                    width: 100%;
                    height: auto;
                    .entry {
                        margin-bottom: 20px;
                    }

                    .more-button {
                        text-align: center;
                        margin: 45px;
                    }
                }
            }
        }
    }
}
</style>
