<template>
    <div id="nintendo" class="basic-page">
        <Loading :show="!imgLoaded" />
        <div v-show="imgLoaded">
            <div id="header" ref="header" class="section">
                <div class="video-wrapper">
                    <video
                        autoplay
                        loop
                        muted
                        playsinline
                        preload="auto"
                        type="video/mp4"
                    >
                        <source
                            src="/static/images/nintendo/switch_header.mp4"
                            type="video/mp4"
                        />
                        <source
                            src="/static/images/nintendo/switch_header.webm"
                            type="video/webm"
                        />
                    </video>
                </div>

                <ImageOptimized
                    code="nintendo/switch_header_bg"
                    thumb-formats="webp,jpg"
                    thumb-sizes="480,768"
                    type="nintendo-header"
                    @loaded="loaded.headerBg = true"
                    @failed="loaded.headerBg = true"
                />
                <div class="section-content">
                    <img
                        class="vigor-switch-season-logo"
                        :src="
                            settings.webpSupport
                                ? '/static/images/nintendo/vigor_logo_switch.webp'
                                : '/static/images/nintendo/vigor_logo_switch.png'
                        "
                        draggable="false"
                        alt="Vigor Coming to Nintendo Switch"
                    />
                    <div class="reverse-wrapper">
                        <img
                            class="vigor-switch-play-button"
                            :src="
                                settings.webpSupport
                                    ? '/static/images/nintendo/vigor_switch_playbutton.webp'
                                    : '/static/images/nintendo/vigor_switch_playbutton.png'
                            "
                            draggable="false"
                            alt="Vigor Nintendo Switch Play Button"
                            @click="popupVideoShow"
                        />
                        <LinkButton
                            class="button-signup"
                            text="Subscribe to our Newsletter"
                            wrapper-type="noPadding"
                            type="red"
                            :event="true"
                            @eventMethod="scrollToForm"
                        />
                    </div>
                    <div class="text">Registration has been closed</div>
                    <LinkButton
                        text="ABOUT THE GAME"
                        wrapper-type="noPadding"
                        type="light"
                        :link="pages.ABOUT_PAGE_NAME"
                        :outside="true"
                    />
                    <img
                        draggable="false"
                        class="nintendo-switch-logo"
                        src="/static/images/nintendo/switch_logo.svg"
                        alt="Nintendo Switch Logo"
                    />
                </div>
            </div>
            <div id="form" ref="form" class="section">
                <div class="section-content">
                    <h2>
                        Don't miss the latest news. Subscribe to our newsletter!
                    </h2>
                    <MailchimpNindentoForm />
                </div>
            </div>
        </div>

        <PopupUniversal
            :show="popupVideo"
            :video="true"
            :video-url="videoUrl"
            @closeMethod="popupVideoClose"
        />
    </div>
</template>

<script>
import Loading from '@/components/partials/Loading';
import VueRecaptcha from 'vue-recaptcha';
import { NintendoResource } from '@/services/nintendo';
import LinkButton from '@/components/partials/LinkButton';
import { getCaptcha } from '@/services/captcha';
import ImageOptimized from '@/components/partials/ImageOptimized';
import Popup from '@/components/partials/popups/Popup';
import { mapGetters } from 'vuex';
import * as pages from '@/routes/pageCodes';
import PopupUniversal from '@/components/partials/popups/PopupUniversal';

import MailchimpNindentoForm from '@/components/widgets/MailchimpNintendoForm';

export default {
    name: 'NintendoSwitch',
    components: {
        Loading,
        VueRecaptcha,
        ImageOptimized,
        LinkButton,
        Popup,
        PopupUniversal,
        MailchimpNindentoForm,
    },
    data() {
        return {
            pages,
            loaded: {
                headerBg: false,
            },
            window: { w: window.innerWidth, h: window.innerHeight },

            videoUrl: '',

            popupVideo: false,
        };
    },
    methods: {
        popupVideoShow() {
            this.videoUrl =
                '//www.youtube.com/embed/GeC-7V0qQoM?autoplay=0&amp;enablejsapi=1&amp;autohide=1&amp;showinfo=0&amp;rel=0';
            this.popupVideo = true;
        },
        popupVideoClose() {
            this.videoUrl = '';
            this.popupVideo = false;
        },
        scrollToForm() {
            const id = 'form';
            const yOffset = -60;
            const element = document.getElementById(id);
            const y =
                element.getBoundingClientRect().top +
                window.pageYOffset +
                yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        },
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        imgLoaded() {
            return this.loaded.headerBg;
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';
#nintendo {
    font-size: 18px;
    color: $light;

    #header {
        z-index: 15;
        overflow: visible;
        background-color: black;
        height: 100vh;
        min-height: 1000px;
        margin-bottom: 5vw;
        position: relative;

        .video-wrapper {
            video {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-height: 100%;
                min-width: 100%;
                object-fit: cover;
            }
            @media (max-width: $screen-sm) {
                display: none;
            }
        }

        .section-content {
            margin: 100px 0 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100vw;
            position: absolute;
            top: 50px;
            left: 50%;
            transform: translate(-50%, 0%);

            @media (max-width: $screen-sm) {
                margin: 50px 0 0 0;
            }
        }

        .vigor-switch-season-logo {
            width: 100%;
            max-width: 400px;
            height: auto;

            @media (max-width: $screen-sm) {
                max-width: 396px;
                margin-bottom: 36px;
            }
            @media (max-width: $screen-xs) {
                max-width: 292px;
                margin-bottom: 36px;
            }
        }
        .reverse-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            .button-signup {
            }

            .vigor-switch-play-button {
                width: 100%;
                max-width: 100px;
                height: auto;
                margin: 100px 0;
                opacity: 0.7;
                cursor: pointer;
                transition: all ease 0.8s;
                &:hover {
                    opacity: 1;
                }
                @media (max-width: $screen-sm) {
                    max-width: 100px;
                    margin: 225px 0 115px 0;
                }
                @media (max-width: $screen-xs) {
                    max-width: 80px;
                    margin: 120px 0 65px 0;
                }
            }

            @media (max-width: $screen-sm) {
                flex-direction: column-reverse;
            }
        }
        .text {
            color: #ffffff;
            font-family: 'PT Sans';
            font-size: 25px;
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;

            @media (max-width: $screen-sm) {
                margin: 15px;
            }
        }
        .nintendo-switch-logo {
            width: 100%;
            max-width: 150px;
            height: auto;
            margin-top: 50px;

            @media (max-width: $screen-sm) {
                max-width: 105px;
                margin-top: 45px;
            }
        }

        @media (max-width: $screen-sm) {
            min-height: 1024px;
            height: 1024px;
        }
        @media (max-width: $screen-xs) {
            min-height: 812px;
            height: 812px;
        }
    }

    #form {
        height: 850px;
        text-align: center;
        overflow: visible;
        h2 {
            @media (max-width: $screen-sm) {
                margin: 15px 20px;
            }
        }

        .section-content {
            width: 100vw;
            padding: 0;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }

        @media (max-width: $screen-sm) {
            min-height: 900px;
        }
        @media (max-width: $screen-xs) {
            min-height: 1100px;
        }
    }
}
</style>
