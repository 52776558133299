<template>
    <div class="global-style">
        <div id="news-detail" class="basic-page">
            <Loading :show="!dataLoaded" />
            <div v-show="dataLoaded">
                <div
                    class="static-bg"
                    :class="{ hidden: settings && settings.apple }"
                >
                    <ImageOptimized
                        code="placeholder/news/bg-news"
                        thumb-formats="webp,jpg"
                        thumb-sizes="480,768,992,1200"
                        type="static-bg"
                        @loaded="loaded.staticBg = true"
                        @failed="loaded.staticBg = true"
                    />
                </div>
                <div
                    v-if="update"
                    id="news-detail-content"
                    ref="content"
                    class="section"
                >
                    <div class="section-content">
                        <div class="scrollable-content">
                            <router-link
                                class="a-text no-underline"
                                exact
                                :to="{ name: pages.UPDATES_PAGE_NAME }"
                            >
                                <div class="back"><< BACK TO UPDATES</div>
                            </router-link>
                            <div class="event-title">{{ update.name }}</div>
                            <div class="event-date">
                                {{ dateInArticleFormat(update.published_at) }}
                            </div>

                            <img
                                class="title-image"
                                :src="update.titleImage"
                                draggable="false"
                            />
                            <div
                                v-if="update.text"
                                class="event-article-content"
                            >
                                <h2 class="capitals">Description</h2>
                                <div class="event-text">
                                    <v-runtime-template
                                        :template="
                                            `<div>${processText(
                                                update.text
                                            )}</div>`
                                        "
                                    />
                                </div>
                                <div
                                    v-if="update.youtubeVideo"
                                    class="embed-responsive embed-responsive-16by9 event-video"
                                >
                                    <iframe
                                        class="embed-responsive-item behind"
                                        :src="videoUrl"
                                        frameborder="0"
                                        height="100%"
                                        width="100%"
                                        allowfullscreen
                                    >
                                        <video
                                            :src="videoUrl"
                                            width="640"
                                            height="360"
                                            style="width:640px;height:360px;"
                                            autoplay="0"
                                        ></video>
                                    </iframe>
                                </div>
                                <div
                                    v-if="update.content"
                                    class="event-update-content"
                                >
                                    <h2 class="capitals">Content</h2>
                                    <v-runtime-template
                                        :template="
                                            `<div>${processList(
                                                update.content
                                            )}</div>`
                                        "
                                    />
                                </div>

                                <div
                                    v-if="
                                        update.screenshots &&
                                            update.screenshots.includes(
                                                'app-picture'
                                            )
                                    "
                                    class="event-update-content"
                                >
                                    <h2 class="capitals">Screenshots</h2>
                                    <v-runtime-template
                                        :template="
                                            `<div class='screenshots'>${processScreenshots(
                                                update.screenshots
                                            )}</div>`
                                        "
                                    />
                                </div>
                                <div class="event-changelog-button">
                                    <LinkButton
                                        v-if="
                                            update.changelog &&
                                                update.changelog.isPublished
                                        "
                                        :text="
                                            `See changelog ${update.changelog.version}`
                                        "
                                        icon="change-icon light"
                                        type="dark-red centered"
                                        :link="pages.CHANGELOG_DETAIL_PAGE_NAME"
                                        :params="{
                                            slug: update.changelog.slug,
                                        }"
                                    />
                                    <LinkButton
                                        v-if="update.package"
                                        id="downloadBtn"
                                        :text="'Download Presskit'"
                                        type="dark-red centered"
                                        :download="true"
                                        :link="update.package"
                                        :outside="true"
                                    />
                                </div>
                                <img
                                    v-if="update.partingImage"
                                    class="postcard"
                                    :src="update.partingImage"
                                    draggable="false"
                                />
                                <ImageOptimized
                                    v-else
                                    code="news/postcard"
                                    thumb-formats="webp,png"
                                    type="postcard"
                                />
                            </div>

                            <router-link
                                class="a-text no-underline"
                                exact
                                :to="{ name: pages.UPDATES_PAGE_NAME }"
                            >
                                <div class="back"><< BACK TO NEWS</div>
                            </router-link>
                        </div>
                        <Sidebar
                            v-if="updates.rows"
                            :header-text="'RECENT UPDATES'"
                            :entries="updates.rows"
                            :link-name="pages.UPDATES_DETAIL_PAGE_NAME"
                            :show-tags="false"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import router from '@/routes';
import { baseTitle } from '@/routes/index';
import Loading from '@/components/partials/Loading';
import { mapGetters } from 'vuex';
import formats from '@/formats';
import { format, parseISO } from 'date-fns';
import ImageOptimized from '@/components/partials/ImageOptimized';

import VRuntimeTemplate from 'v-runtime-template';
import AppYoutube from '@/components/common/widgets/AppYoutube';
import AppTweet from '@/components/common/widgets/AppTweet';
import AppPicture from '@/components/common/widgets/AppPicture';
import urlParser from 'js-video-url-parser';

import CardV2 from '@/components/partials/CardV2';
import Sidebar from '@/components/partials/Sidebar';
import LinkButton from '@/components/partials/LinkButton';

export default {
    name: 'UpdatesDetail',
    components: {
        Loading,
        CardV2,
        LinkButton,
        ImageOptimized,
        AppYoutube,
        AppTweet,
        AppPicture,
        VRuntimeTemplate,
        Sidebar,
    },
    data() {
        return {
            pages,
            update: null,
            shownEntries: 6,
            loaded: {
                staticBg: false,
            },
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        ...mapGetters('updates', {
            updates: 'getUpdates',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        dataLoaded() {
            return this.loaded.staticBg && this.update;
        },
        slug() {
            return this.$route.params.slug;
        },

        videoUrl() {
            let data = urlParser.parse(this.update.youtubeVideo);
            return (
                urlParser.create({ videoInfo: data, format: 'embed' }) +
                '?autoplay=0&amp;enablejsapi=1&amp;autohide=1&amp;showinfo=0&amp;rel=0'
            );
        },
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            if (!this.update) this.findUpdate();
            this.handleBg();
        }, 500);
    },
    updated() {
        if (this.updates && !this.update) this.findUpdate();

        if (!this.update) return;

        if (this.update.name.length <= 20) {
            document.title = this.update.name + ' | News | ' + baseTitle;
        } else {
            document.title =
                this.update.name.substring(0, 16) + '... | News | ' + baseTitle;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        processScreenshots(text) {
            return text.replace(
                /<app-picture /g,
                '<app-picture linkToMaxImage="true" '
            );
        },
        dateInArticleFormat(date) {
            return format(parseISO(date), formats.ARTICLE_FORMAT);
        },
        findUpdate() {
            let slug = this.slug;
            this.update = this.updates.rows.find(function(update) {
                return update.slug === slug;
            });
            if (!this.update) {
                router.push({ name: pages.NOT_FOUND_PAGE_NAME });
            }
        },
        processText(text) {
            return text
                .replace(/<a /g, '<a class="a-text" ')
                .replace(/<li>/g, '<div class="content-item">')
                .replace(/<\/li>/g, '</div>')
                .replace(/<ul>/g, '')
                .replace(/<\/ul>/g, '');
        },
        processList(text) {
            return text
                .replace(/<p>/g, '<p class="content-update">')
                .replace(/<\/p>/g, '</p>');
        },
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/placeholder/news/bg-news.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

#news-detail {
    #news-detail-content {
        .section-content {
            padding: 125px 35px 150px;
            display: flex;
            justify-content: center;

            .scrollable-content {
                max-width: 889px;
                width: 100%;
                margin-right: 35px;

                .event-title {
                    font-size: 60px;
                    font-weight: 700;
                    text-transform: uppercase;

                    @media (max-width: 768px) {
                        font-size: 60px;
                    }
                    @media (max-width: 480px) {
                        font-size: 42px;
                    }
                }

                .event-date {
                    color: $red;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                    margin: 15px 0 5px 0;
                }
                .event-article-content {
                    background-color: #252525;
                    padding: 50px;

                    .event-video {
                        position: relative;
                        overflow: hidden;
                        width: 100%;
                        padding-top: 56.25%;
                        margin-top: 50px;

                        iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .event-update-content {
                        margin-top: 30px;
                        /deep/ .content-item {
                            line-height: 25px;

                            .content-item {
                                margin-left: 20px;
                            }

                            p {
                                margin: 0;
                                display: inline;
                            }
                        }
                    }

                    .event-update-content {
                        .content-update {
                            line-height: 25px;

                            p {
                                margin: 0;
                                display: inline;
                            }
                        }

                        /deep/ .content-update::before {
                            content: '\2022';
                            color: $red;
                            font-weight: bold;
                            display: inline-block;
                            width: 1em;
                        }
                    }
                }

                @media (max-width: 1200px) {
                    width: 90vw;
                    margin-right: 0;
                }
                @media (max-width: 480px) {
                    width: 100vw;
                }
            }

            .event-changelog-button {
                margin: 20px 0;
                display: block;
                text-align: center;
            }

            .back {
                display: block;
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
            }

            @media (max-width: 1200px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.global-style /deep/ .basic-page,
.global-style /deep/ .basic-content {
    width: 100vw;
    max-width: 1920px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: auto;
    cursor: default;
    word-break: break-word;
    display: block;
    position: relative;
    min-height: 100vh;

    .title-image,
    .basic-image {
        max-width: 889px;
        width: 100%;
        height: auto;
    }

    .basic-image-description {
        text-align: center;
        font-style: normal;
        color: $red;
        font-size: 18px;
        font-weight: 400;
    }

    .postcard {
        width: 450px;
        max-width: 100%;
    }

    h2 {
        color: $light;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 20px 0;
        padding-top: 20px;
    }

    p {
        color: $light;
        font-size: 18px;
        font-weight: 400;
    }
    .strong-title {
        display: block;
        font-weight: 600;
    }

    .basic-page-section {
        margin-bottom: 40px;
    }
}

.basic-page /deep/ .grid-vertical {
    display: block;
    width: calc(100% + 28px);

    .grid-item {
        display: inline-flex;
    }
}
.basic-page /deep/ .grid-horizontal {
    display: inline-flex;
    width: 100%;

    .grid-item {
        display: block;
        margin-right: 28px;
        margin-bottom: 28px;
    }

    &:last-child {
        width: calc(100% + 28px);
    }

    @media (max-width: 950px) {
        display: block;
    }
}
</style>
