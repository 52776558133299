import App from './App';
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from './init';
import webp from './lib/webp';
import browser from './lib/browser';
import router from './routes';
import store from './store';
import VueAnalytics from 'vue-analytics';
import VideoBackground from 'vue-responsive-video-background-player';

Vue.component('video-background', VideoBackground);

Vue.use(VueAnalytics, {
    id: 'UA-2014915-34',
    checkDuplicatedScript: true,
});

Vue.config.productionTip = false;

const init = async function() {
    let webpSupport = await webp.isSupported();
    if (webpSupport) {
        store.commit('settings/webpSupport', webpSupport);
        document.body.classList.add('webp');
    } else {
        document.body.classList.add('no-webp');
    }

    let isApple = await browser.isApple();
    if (isApple) {
        store.commit('settings/apple', isApple);
    }

    let isEdge = await browser.isEdge();
    if (isEdge) {
        store.commit('settings/edge', isEdge);
    }

    /* eslint-disable no-new */
    new Vue({
        el: '#app',
        store,
        router,
        render: h => h(App),
    });
};
init();
