<template>
    <div id="contact" class="basic-page">
        <Loading :show="!imgsLoaded" />
        <div v-show="imgsLoaded">
            <div
                class="static-bg"
                :class="{ hidden: settings && settings.apple }"
            >
                <ImageOptimized
                    code="bg-contact"
                    thumb-formats="webp,jpg"
                    thumb-sizes="480,768,992,1200"
                    type="static-bg"
                    @loaded="loaded.staticBg = true"
                    @failed="loaded.staticBg = true"
                />
            </div>
            <div id="contact-content" class="section">
                <div class="section-content">
                    <div class="upper-title">
                        Contact <span style="display: none;">*</span>
                    </div>

                    <div class="page-title">Send us a message</div>
                    <div class="content">
                        <div class="contact-line-block">
                            <div class="contact-half-block">
                                <div class="basic-page-section">
                                    <h2 class="capitals">Press contact</h2>
                                    <p>
                                        Contact for PR & Marketing Inquiries<br />
                                        <a
                                            class="a-text"
                                            href="mailto:marketing@bohemia.net"
                                            target="_top"
                                            rel="noopener noreferrer"
                                            >press.vigor@bistudio.com</a
                                        >
                                    </p>
                                </div>

                                <div class="basic-page-section">
                                    <h2 class="capitals">
                                        Youtubers and Streamers
                                    </h2>
                                    <p>
                                        Info for streaming outlanders<br />
                                        <a
                                            class="a-text"
                                            href="mailto:marketing@bohemia.net"
                                            target="_top"
                                            rel="noopener noreferrer"
                                            >partnership.vigor@bistudio.com</a
                                        >
                                    </p>
                                </div>
                            </div>

                            <div class="contact-half-block">
                                <div class="basic-page-section">
                                    <h2 class="capitals">Support</h2>
                                    <p>
                                        Info about problems in Outlands<br />
                                        <a
                                            class="a-text"
                                            href="mailto:support@bohemia.net"
                                            target="_top"
                                            rel="noopener noreferrer"
                                            >support@bohemia.net</a
                                        >
                                    </p>
                                </div>

                                <div class="basic-page-section">
                                    <h2 class="capitals">Career</h2>
                                    <p>
                                        Help us out<br />
                                        <a
                                            class="a-text"
                                            href="https://www.bohemia.net/company/careers"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >bohemia.net/company/careers</a
                                        >
                                    </p>
                                </div>
                            </div>
                            <div class="">
                                <div>
                                    <h2 class="capitals">Report bugs here</h2>
                                    <div>
                                        <FeedbackTracker
                                            icon-style="bigger medium-margin"
                                            :light="true"
                                        />
                                        <!-- <div>
                                            <a
                                                class="a-text"
                                                href="https://feedback.bistudio.com/project/view/1/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                >feedback.bistudio.com</a
                                            >
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="contact-line-block">
                            <div class="contact-half-block">
                                <div class="basic-page-section">
                                    <h2 class="capitals">Follow Vigor</h2>
                                    <SocialLinks
                                        icon-style="bigger medium-margin"
                                        :light="true"
                                    />
                                </div>
                                <div class="basic-page-section">
                                    <h2 class="capitals">Newsletter</h2>
                                    <NewsletterButton
                                        text="Sign Up For Updates"
                                        type="light thin centered red"
                                    />
                                </div>
                            </div>

                            <div class="contact-half-block">
                                <div class="basic-page-section">
                                    <h2 class="capitals light">
                                        Bohemia Interactive
                                    </h2>
                                    <p>
                                        Check out other games from Bohemia<br />
                                        <a
                                            class="a-text"
                                            href="https://www.bohemia.net/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >bohemia.net</a
                                        >
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from '@/components/partials/Loading';
import SocialLinks from '@/components/partials/SocialLinks';
import ImageOptimized from '@/components/partials/ImageOptimized';
import FeedbackTracker from '../partials/FeedbackTracker.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'Contact',
    components: {
        Loading,
        SocialLinks,
        NewsletterButton: () =>
            import('@/components/partials/NewsletterButton'),
        ImageOptimized,
        FeedbackTracker,
    },
    data() {
        return {
            loaded: {
                staticBg: false,
            },
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        imgsLoaded() {
            return this.loaded.staticBg;
        },
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/bg-contact.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import './../../styles/colors.scss';
@import './../../styles/global.scss';

#contact {
    #contact-content {
        margin-bottom: 50px;

        .section-content {
            padding-top: 192px;

            .content {
                margin-top: 50px;
                margin-right: -190px;

                .contact-line-block {
                    margin-bottom: 100px;

                    .contact-half-block {
                        display: inline-block;
                        width: 590px;
                        max-width: 80vw;
                        vertical-align: top;
                        margin-right: 190px;
                    }
                }
            }
        }
    }
}
</style>
