<template>
    <div class="minicard">
        <h2 class="capitals">{{ partner.channelName }}</h2>
        <div class="social-links">
            <a
                :key="partner.channelUrl"
                class="icon-link no-underline"
                :href="partnerUrl"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img :src="imgPath + 'channel-w.svg'" />
            </a>
            <a
                v-for="link in links"
                v-if="partnerLinks[link.param]"
                :key="partner.name + link.name"
                class="icon-link no-underline"
                :href="partnerLinks[link.param]"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img :src="imgPath + link.img" />
            </a>
        </div>
    </div>
</template>

<script>
import SocialLinks from '@/components/partials/SocialLinks';

const imgPath = '/static/images/social/';

const links = [
    {
        img: 'tw-w.svg',
        name: 'Twitter',
        param: 'twitter',
        url: 'https://twitter.com/',
    },
];

export default {
    name: 'Minicard',
    components: {
        SocialLinks,
    },
    props: {
        partner: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            links,
            imgPath,
            partnerLinks: [],
            partnerUrl: null,
        };
    },
    mounted() {
        let results = {};
        links.forEach(link => {
            if (this.partner[link.param]) {
                let urlParts = this.partner[link.param].split('/');
                results[link.param] = link.url + urlParts[urlParts.length - 1];
            }
        });
        this.partnerLinks = results;

        this.partnerUrl =
            this.partner.channelUrl.indexOf('://') === -1
                ? 'https://' + this.partner.channelUrl
                : this.partner.channelUrl;
    },
};
</script>

<style scoped lang="scss">
@import './../../styles/colors.scss';
@import './../../styles/global.scss';
@import './../../styles/promo.scss';

.minicard {
    background-color: rgba(0, 0, 0, 0.8);
    width: 330px;
    min-height: 90px;
    margin: 0 10px 10px 0;
    padding: 5px 20px 15px 20px;
    display: inline-block;
    vertical-align: top;
}
</style>
