<template>
    <div
        id="popup-layer"
        class="basic-content"
        :class="{ show: show, hide: !show }"
        @click="closeMethod"
        @mousemove="block"
    >
        <div
            id="popup-window"
            :class="{ webp: settings.webpSupport }"
            @click="stopPropagation"
        >
            <div class="popup-content">
                <div class="popup-title">
                    {{ result.code ? 'Your code is here!' : 'Sorry, but...' }}
                </div>
                <div class="popup-text">
                    <template v-if="result.code">
                        The code is:<br />
                        {{ result.code }}<br />
                        Check your e-mail if you need it later. Enjoy!
                    </template>
                    <template v-else>
                        <div v-html="error" />
                    </template>
                </div>
                <div class="popup-close" @click="closeMethod"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import LinkButton from '@/components/partials/LinkButton';
import { mapGetters } from 'vuex';

export default {
    name: 'Popup',
    components: { LinkButton },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        result: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            pages,
        };
    },
    methods: {
        closeMethod() {
            this.$emit('closeMethod');
        },
        block(event) {
            event.preventDefault();
            event.stopPropagation();
        },
        stopPropagation(event) {
            event.stopPropagation();
        },
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        error() {
            switch (this.result.result) {
                case 'no-codes-left':
                    return 'There are no codes from this outlander left.';
                case 'already-claimed':
                    return 'You already claimed this item.<br />Try checking your e-mail to find it!';
                default:
                    return 'We cannot send you the code, because there have been some technical difficulties.';
            }
        },
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';
@import '../../../styles/popups.scss';
</style>
