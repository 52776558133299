import axios from 'axios';
// import { addErrorNotification } from '../utils/notifications';

const baseService = axios.create({
    baseURL: '/api/',
});
export const getCaptcha = () =>
    // eslint-disable-next-line no-undef
    base.get('/auth/recaptcha').then(res => res.data);
export default baseService;
