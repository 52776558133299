<template>
    <div id="eula-purchases" class="basic-page">
        <div class="static-bg" :class="{ hidden: settings && settings.apple }">
            <ImageOptimized
                code="bg-eula"
                thumb-formats="webp,jpg"
                thumb-sizes="768,992,1200"
                type="static-bg"
            />
        </div>
        <div id="eula-purchases-content" class="section">
            <div class="section-content">
                <div class="upper-title">END-USER LICENSE AGREEMENT</div>
                <div class="page-title">VIGOR In-Game Purchases Terms</div>
                <div class="text">
                    <ol>
                        <strong><li>General provisions</li></strong>
                        <div class="text">
                            These VIGOR In-Game Purchases Terms (hereinafter
                            referred to as the “<strong>Terms</strong>”)
                            together with
                            <strong
                                >VIGOR EULA, Privacy Policy, Bohemia Terms and
                                Conditions and other applicable
                                documents</strong
                            >
                            (especially documents regulating the in-game
                            purchases for respective platform) set forth the
                            rules for acquiring and use of virtual currency
                            (hereinafter referred to as the “<strong
                                >Virtual Currency</strong
                            >”) and virtual goods such as customization
                            cosmetics, premium battle pass and social boosters
                            (hereinafter referred to as the “<strong
                                >Virtual Goods</strong
                            >”) contained in VIGOR game and associated services
                            (hereinafter referred to as the
                            “<strong>Game</strong>”). Virtual Currency and
                            Virtual Goods are collectively also referred to as
                            “<strong>Virtual Items</strong>”.
                        </div>
                        <strong><li>Virtual Items</li></strong>
                        <div class="text">
                            Within the Game You may acquire (purchase or
                            otherwise receive) Virtual Goods and Virtual
                            Currency which You can then exchange for Virtual
                            Goods. By acquiring and using these Virtual Items
                            You declare that:
                            <ul>
                                <li>
                                    You are familiar with the above mentioned
                                    documents regulating the relationship
                                    between You and Us.
                                </li>
                                <li>
                                    You have fully understood these Terms and
                                    You agree with them. If You are not capable
                                    of understanding these Terms You are not
                                    allowed to acquire and use Virtual Items.
                                </li>
                                <li>
                                    You have fully understood the terms
                                    regulating the purchase of Virtual Items
                                    within the respective platform.
                                </li>
                                <li>
                                    You meet all the conditions applicable in
                                    your country for acquiring Virtual Items.
                                </li>
                            </ul>
                        </div>
                        <strong><li>Ownership</li></strong>
                        <div class="text">
                            All use of Virtual Items is primarily regulated by
                            these Terms and the VIGOR EULA. Additional
                            conditions may be set forth by the documents
                            regulating the in-game purchases for each respective
                            platform. You do not own Virtual Items. We just
                            grant You a non-exclusive, revocable,
                            non-transferable right and license to use them in a
                            manner provided by these Terms and the VIGOR EULA.
                            You are only allowed to acquire Virtual Items from
                            Us or persons granted permission by Us (platform
                            providers).
                        </div>
                        <strong><li>Purchasing of Virtual Items</li></strong>
                        <div class="text">
                            Within the Game You may purchase Virtual Items and
                            pay for them by the means given by the subject which
                            provides for Us such payments. All purchases are
                            payable in advance and are not refundable in whole
                            or in part, regardless of the payment method, except
                            as expressly set forth in these Terms. We may, at
                            our sole discretion, choose to refund Your purchase
                            even in the cases, where You are not entitled to a
                            refund by law. If You feel qualified for a refund,
                            please contact Us via e-mail to
                            support@bistudio.com. We may revise the pricing for
                            Virtual Items offered through the Game at any time
                            in compliance with the rules of service providers.
                        </div>
                        <strong><li>Value of Virtual Items</li></strong>
                        <div class="text">
                            You acknowledge that Virtual Items have no monetary
                            value and can never be exchanged for real money,
                            real goods or real services from Us or anyone else.
                            You also acknowledge that Virtual Items are not
                            transferrable to anyone else. We reserve the right
                            to control, regulate, change or remove any Virtual
                            Item without any liability to You.
                        </div>
                        <strong><li>Use of Virtual Items</li></strong>
                        <div class="text">
                            You can use the acquired Virtual Items in a way
                            enabled within the Game. Please note that once You
                            spend the Virtual Currency or in any way consume the
                            Virtual Items You lose the right to the spent
                            Virtual Currency or consumed Virtual Item. In
                            exceptional cases We may, at our sole discretion,
                            decide to renew the spent Virtual Currency or the
                            consumed Virtual Items (for example due to an error
                            within the Game).
                        </div>
                        <strong><li>Termination</li></strong>
                        <div class="text">
                            If Your license to use the Game is terminated for
                            whatever reason You acknowledge that We are not in
                            any way liable for any loss or damage caused to You
                            and You lose all Your rights to any Virtual Item You
                            have acquired within the Game. In this case We also
                            do not reimburse or refund any Virtual Item You have
                            acquired.
                        </div>
                        <strong><li>Limitation of Liability</li></strong>
                        <div class="text">
                            Neither Us, our parent, subsidiaries, affiliates or
                            licensors shall be liable in any way for loss or
                            damage of any kind resulting from acquiring or using
                            Virtual Items, including but not limited to loss of
                            goodwill, work stoppage, or any and all other
                            commercial damage or losses.
                        </div>
                        <strong><li>Final provisions</li></strong>
                        <div class="text">
                            We reserve the right to make changes to these Terms
                            at any time. You will be subject to the updated
                            Terms as of the day You purchase or use the Virtual
                            Item for the first time after the update of these
                            Terms. All parts of these Terms are valid to the
                            maximum extent permitted by law and nothing in them
                            limits any of Your rights set by the applicable law.
                            These Terms are governed by and construed in
                            accordance with the laws of the Czech Republic and
                            both You and Us hereby submit to the exclusive
                            jurisdiction of the courts of the Czech Republic.
                        </div>
                    </ol>
                    Go
                    <router-link
                        class="a-text"
                        exact
                        :to="{ name: pages.EULA_PAGE_NAME }"
                        >back to EULA & Terms</router-link
                    >.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import ImageOptimized from '@/components/partials/ImageOptimized';
import { mapGetters } from 'vuex';

export default {
    name: 'EulaPurchases',
    components: { ImageOptimized },
    data() {
        return {
            pages,
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            this.handleBg();
        }, 500);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/bg-eula.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

#eula-purchases {
    #eula-purchases-content {
        padding-bottom: 100px;
        .section-content {
            padding-top: 192px;
        }
    }
}
</style>
