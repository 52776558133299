import { render, staticRenderFns } from "./PopupUniversal.vue?vue&type=template&id=2c9d7340&scoped=true"
import script from "./PopupUniversal.vue?vue&type=script&lang=js"
export * from "./PopupUniversal.vue?vue&type=script&lang=js"
import style0 from "./PopupUniversal.vue?vue&type=style&index=0&id=2c9d7340&prod&scoped=true&lang=scss&media=print"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9d7340",
  null
  
)

export default component.exports