'use strict';
import Vue from 'vue';
import * as types from '../mutation-types';

import { UserResource } from '@/services/user';

const state = {
    data: {},
    isLogged: false,
    loading: false,
};

const getters = {
    getUser: state => {
        return state.data;
    },
    accessToken: state => {
        return state.data && state.data.accessToken;
    },
    getUserId: state => {
        return state.data ? state.data.id : null;
    },
    isLogged: state => {
        return state.isLogged;
    },
    loading: state => {
        return state.loading;
    },
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    loadUser({ commit, state }) {
        commit(types.LOADING, true);
        return UserResource.get()
            .then(response => {
                commit(types.RECEIVE_USER, response.data.data);
                commit(types.IS_LOGGED_IN, true);
                commit(types.LOADING, false);
            })
            .catch(err => {
                commit(types.RECEIVE_USER, null);
                commit(types.IS_LOGGED_IN, false);
                commit(types.LOADING, false);
                console.log(err);
            });
    },
};

const mutations = {
    [types.RECEIVE_USER](state, val) {
        Vue.set(state, 'data', val);
    },
    [types.IS_LOGGED_IN](state, val) {
        Vue.set(state, 'isLogged', val);
    },
    [types.LOADING](state, boolean) {
        Vue.set(state, 'loading', boolean);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
