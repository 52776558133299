var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"global-style"},[_c('div',{staticClass:"basic-page",attrs:{"id":"promo-detail"}},[_c('Loading',{attrs:{"show":!_vm.dataLoaded || _vm.codeIsLoading}}),(_vm.dataLoaded)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoaded),expression:"dataLoaded"}]},[_c('div',{staticClass:"static-bg",class:{ hidden: _vm.settings && _vm.settings.apple }},[_c('img',{staticClass:"bg-image",attrs:{"src":_vm.promotionOther.bgImage,"draggable":"false"}})]),_c('div',{staticClass:"section promo-detail-content dark"},[_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"upper-title"},[_vm._v(" "+_vm._s(_vm.promotionOther.upperTitle)+" ")]),_c('div',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.promotionOther.mainTitle)+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"promo-line-block"},[_c('div',{staticClass:"title-image"},[_c('img',{attrs:{"src":_vm.promotionOther.titleImage,"draggable":"false"}})]),_c('div',{staticClass:"promo-half-block"},[_c('div',{staticClass:"basic-page-section"},[_c('h2',{staticClass:"capitals"},[_vm._v(" "+_vm._s(_vm.promotionOther.textLeftUpperTitle)+" ")]),_c('p',[_c('v-runtime-template',{attrs:{"template":`<div>${_vm.processText(
                                                    _vm.promotionOther.textLeftUpper
                                                )}</div>`}})],1)]),_c('div',{staticClass:"basic-page-section"},[_c('h2',{staticClass:"capitals"},[_vm._v(" "+_vm._s(_vm.promotionOther.textLeftLowerTitle)+" ")]),_c('p',[_c('v-runtime-template',{attrs:{"template":`<div>${_vm.processText(
                                                    _vm.promotionOther.textLeftLower
                                                )}</div>`}})],1)]),_c('div',{staticClass:"basic-page-section"},[_c('h2',{staticClass:"capitals"},[_vm._v(" "+_vm._s(_vm.promotionOther.cardTitle)+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.promotionOther.cardText)}}),_c('Card',{attrs:{"button":_vm.promotionOther.buttonText
                                                ? _vm.promotionOther.buttonText
                                                : 'Claim',"img":_vm.promotionOther.previewImage,"padding":false,"event":true,"disabled-text":_vm.codeAvailable &&
                                            !_vm.promotionOther.manualSoldOut
                                                ? null
                                                : 'Out of stock'},on:{"eventMethod":_vm.requestCode}})],1)]),_c('div',{staticClass:"promo-half-block"},[_c('div',{staticClass:"basic-page-section"},[_c('h2',{staticClass:"capitals"},[_vm._v(" "+_vm._s(_vm.promotionOther.textRightUpperTitle)+" ")]),_c('p',[_c('v-runtime-template',{attrs:{"template":`<div>${_vm.processText(
                                                    _vm.promotionOther.textRightUpper
                                                )}</div>`}})],1)]),_c('div',{staticClass:"basic-page-section"},[_c('h2',{staticClass:"capitals"},[_vm._v(" "+_vm._s(_vm.promotionOther.textRightLowerTitle)+" ")]),_c('p',[_c('v-runtime-template',{attrs:{"template":`<div>${_vm.processText(
                                                    _vm.promotionOther.textRightLower
                                                )}</div>`}})],1)]),(
                                        _vm.promotionOther.mainTitle &&
                                            _vm.linksPresent
                                    )?_c('div',{staticClass:"basic-page-section social-links"},[_c('h2',{staticClass:"capitals"},[_vm._v(" Follow "+_vm._s(_vm.promotionOther.mainTitle)+" ")]),_vm._l((_vm.links),function(link){return (_vm.promotionOther[link.param])?_c('a',{key:link.name,staticClass:"icon-link no-underline",attrs:{"href":_vm.promotionOther[link.param],"target":"_blank","rel":"noopener noreferrer"}},[_c('img',{attrs:{"src":_vm.imgPath + link.img}})]):_vm._e()}),_c('br'),(_vm.promotionOther.web)?_c('a',{staticClass:"a-text dark",attrs:{"href":_vm.promotionOther.web}},[_vm._v(_vm._s(_vm.promotionOther.web))]):_vm._e()],2):_vm._e()])])])]),_c('Popup',{attrs:{"show":_vm.popupLogin,"title":"Code","text":"You must log into your Bohemia account or create it to get the code!","button-text":"Log in"},on:{"closeMethod":_vm.loginClose,"submitMethod":_vm.loginSubmit}}),_c('PopupCode',{attrs:{"show":_vm.popupCode,"user":_vm.user,"promotion":_vm.promotionOther,"other":true},on:{"closeMethod":_vm.codeClose,"submitMethod":_vm.codeSubmit,"codeLoadingMethod":_vm.codeLoading}}),(_vm.claimResult)?_c('PopupCodeResult',{attrs:{"show":_vm.popupClaimed,"result":_vm.claimResult},on:{"closeMethod":_vm.signedUpClose}}):_vm._e()],1)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }