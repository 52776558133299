var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"basic-page",attrs:{"id":"press"}},[_c('Loading',{attrs:{"show":!_vm.dataLoaded}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoaded),expression:"dataLoaded"}]},[_c('div',{staticClass:"static-bg",class:{ hidden: _vm.settings && _vm.settings.apple }},[_c('ImageOptimized',{attrs:{"code":"presskit/bg-presskit","thumb-formats":"webp,jpg","thumb-sizes":"768,992,1200","type":"static-bg"},on:{"loaded":function($event){_vm.loaded.staticBg = true},"failed":function($event){_vm.loaded.staticBg = true}}})],1),_c('div',{staticClass:"section",attrs:{"id":"press-content"}},[_c('div',{staticClass:"section-content"},_vm._l((_vm.presskits.rows),function(presskit){return _c('div',{key:presskit.id,staticClass:"content"},[_c('div',{staticClass:"page-title"},[_vm._v(_vm._s(presskit.name))]),(presskit.tags)?_c('div',{staticClass:"event-tags"},_vm._l((_vm.shownTags),function(tag,id){return _c('div',{key:id},[_c('img',{staticClass:"entry-tag",attrs:{"src":tag}})])}),0):_vm._e(),_c('div',{staticClass:"press-line-block"},[_c('div',{staticClass:"press-half-block"},[(presskit.generalInfo)?_c('div',{staticClass:"basic-page-section"},[_c('h2',{staticClass:"capitals"},[_vm._v("General Info")]),_c('p',{domProps:{"innerHTML":_vm._s(
                                        _vm.processText(presskit.generalInfo)
                                    )}})]):_vm._e(),_c('div',{staticClass:"basic-page-section genInfo"},[_c('h2',{staticClass:"capitals"},[_vm._v("Description")]),_c('p',{domProps:{"innerHTML":_vm._s(
                                        _vm.processText(presskit.description)
                                    )}})])]),_c('div',{staticClass:"press-half-block"},[_c('div',{staticClass:"basic-page-section"},[_c('h2',{staticClass:"capitals"},[_vm._v("Key Features")]),_c('v-runtime-template',{attrs:{"template":`<div>${_vm.processList(
                                            presskit.keyFeatures
                                        )}</div>`}})],1),_vm._m(0,true),_c('div',{staticClass:"basic-page-section"},[_c('h2',{staticClass:"capitals"},[_vm._v("Social")]),_c('p',[_c('SocialLinks',{attrs:{"simple":true}})],1)]),_c('div',{staticClass:"basic-page-section"},[_c('h2',{staticClass:"capitals"},[_vm._v("Newsletter")]),_c('NewsletterButton',{attrs:{"text":"Sign Up For Updates","type":"light thin centered red"}})],1)])]),(presskit.video)?_c('div',{staticClass:"embed-responsive embed-responsive-16by9 press-line-block video"},[_c('iframe',{staticClass:"embed-responsive-item behind",attrs:{"src":_vm.videoUrl,"frameborder":"0","height":"100%","width":"100%","allowfullscreen":""}},[_c('video',{staticStyle:{"width":"640px","height":"360px"},attrs:{"src":_vm.videoUrl,"width":"640","height":"360","autoplay":"0"}})])]):_vm._e(),(presskit.screenshots.includes('app-picture'))?_c('div',{staticClass:"press-line-block"},[_c('div',{staticClass:"basic-page-section"},[_c('h2',{staticClass:"capitals"},[_vm._v("Screenshots")]),_c('v-runtime-template',{attrs:{"template":`<div class='screenshots'>${_vm.processScreenshots(
                                        presskit.screenshots
                                    )}</div>`}})],1)]):_vm._e(),(presskit.videoPackage || presskit.artPackage)?_c('h2',{staticClass:"capitals"},[_vm._v(" Downloads ")]):_vm._e(),_c('div',{staticClass:"press-line-block"},[(presskit.artPackage)?_c('Card',{staticClass:"downloadImgClass",attrs:{"title":"Artwork & Screenshots","button":"Download","img":presskit.previewArtImage,"download":true,"link-name":presskit.artPackage}}):_vm._e(),(presskit.videoPackage)?_c('Card',{staticClass:"downloadImgClass",attrs:{"title":"Videos","button":"Download","img":presskit.previewVideoImage,"download":true,"link-name":presskit.videoPackage}}):_vm._e()],1)])}),0)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"basic-page-section"},[_c('h2',{staticClass:"capitals"},[_vm._v("Press Contact")]),_c('p',[_c('a',{staticClass:"a-text",attrs:{"href":"mailto:marketing@bohemia.net","target":"_top","rel":"noopener noreferrer"}},[_vm._v("press.vigor@bistudio.com")])])])
}]

export { render, staticRenderFns }