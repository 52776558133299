import Vue from './../init';

const uri = 'v1.0/mailtrain';

var customActions = {
    subscribe: { method: 'PUT', url: uri + '/subscribe' },
    unsubscribe: { method: 'PUT', url: uri + '/unsubscribe' },
    test: { method: 'PUT', url: uri + '/test' },
    subscribeNewsletter: { method: 'PUT', url: uri + '/subscribeNewsletter' },
    unsubscribeNewsletter: {
        method: 'PUT',
        url: uri + '/unsubscribeNewsletter',
    },
    testNewsletter: { method: 'PUT', url: uri + '/testNewsletter' },
};

export const MailtrainResource = Vue.resource(uri, {}, customActions);
