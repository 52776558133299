<!-- this file has a duplicate in vigor-admin-nodejs-vuejs -->
<template>
    <ImageOptimized
        type="basic-image"
        :url="url"
        :code="code"
        :thumb-sizes="thumbSizes"
        :thumb-formats="thumbFormats"
        :alt="alt"
        :title="title"
        :description="description"
        :link-to-max-image="linkToMaxImage == 'true'"
    />
</template>
<script>
import ImageOptimized from '@/components/partials/ImageOptimized';

export default {
    name: 'EditorPicture',
    components: { ImageOptimized },
    props: {
        url: {
            type: String,
            default: null,
        },
        code: {
            type: String,
            default: null,
        },
        thumbSizes: {
            type: String,
            default: null,
        },
        thumbFormats: {
            type: String,
            default: null,
        },
        alt: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        linkToMaxImage: {
            type: String,
            default: null,
        },
    },
};
</script>
