'use strict';
import { FaqResource } from '@/services/faq';
import Vue from 'vue';
import * as types from '../mutation-types';

const state = {
    data: null,
};

const getters = {
    getFaqs: state => {
        return state.data;
    },
};

const actions = {
    loadFaqs({ commit }) {
        return FaqResource.get()
            .then(response => {
                commit(types.RECEIVE_FAQS, response.data);
            })
            .catch(err => {
                commit(types.RECEIVE_FAQS, {});
                console.log(err);
            });
    },
};

const mutations = {
    [types.RECEIVE_FAQS](state, val) {
        Vue.set(state, 'data', val);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
