<template>
    <div class="global-style">
        <div id="news-detail" class="basic-page">
            <Loading :show="!dataLoaded" />
            <div v-show="dataLoaded">
                <div
                    class="static-bg"
                    :class="{ hidden: settings && settings.apple }"
                >
                    <ImageOptimized
                        code="placeholder/news/bg-news"
                        thumb-formats="webp,jpg"
                        thumb-sizes="480,768,992,1200"
                        type="static-bg"
                        @loaded="loaded.staticBg = true"
                        @failed="loaded.staticBg = true"
                    />
                </div>
                <div
                    v-if="article"
                    id="news-detail-content"
                    ref="content"
                    class="section"
                >
                    <div class="section-content">
                        <div class="scrollable-content">
                            <router-link
                                class="a-text no-underline"
                                exact
                                :to="{ name: pages.NEWS_PAGE_NAME }"
                            >
                                <div class="back"><< BACK TO NEWS</div>
                            </router-link>
                            <div class="event-title">{{ article.name }}</div>
                            <div class="event-date">
                                {{ dateInArticleFormat(article.published_at) }}
                            </div>
                            <div class="event-tags">
                                <div v-for="(tag, id) in shownTags" :key="id">
                                    <img :src="tag" class="entry-tag" />
                                </div>
                            </div>
                            <img
                                class="title-image"
                                :src="article.titleImage"
                                draggable="false"
                            />
                            <div class="event-article-content">
                                <div class="event-text">
                                    <v-runtime-template
                                        :template="
                                            `<div>${processText(
                                                article.content
                                            )}</div>`
                                        "
                                    />
                                </div>
                                <img
                                    v-if="article.partingImage"
                                    class="postcard"
                                    :src="article.partingImage"
                                    draggable="false"
                                />
                                <ImageOptimized
                                    v-else
                                    code="news/postcard"
                                    thumb-formats="webp,png"
                                    type="postcard"
                                />
                            </div>
                            <br />
                            <router-link
                                class="a-text no-underline"
                                exact
                                :to="{ name: pages.NEWS_PAGE_NAME }"
                            >
                                <div class="back"><< BACK TO NEWS</div>
                            </router-link>
                        </div>
                        <Sidebar
                            v-if="articles.rows"
                            :header-text="'RECENT POSTS'"
                            :entries="articles.rows"
                            :link-name="pages.NEWS_DETAIL_PAGE_NAME"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import router from '@/routes';
import { baseTitle } from '@/routes/index';
import Loading from '@/components/partials/Loading';
import { mapGetters } from 'vuex';
import formats from '@/formats';
import { format, parseISO } from 'date-fns';
import ImageOptimized from '@/components/partials/ImageOptimized';

import VRuntimeTemplate from 'v-runtime-template';
import AppYoutube from '@/components/common/widgets/AppYoutube';
import AppTweet from '@/components/common/widgets/AppTweet';
import AppPicture from '@/components/common/widgets/AppPicture';

import Sidebar from '@/components/partials/Sidebar';

//SVGs
import XboxTagIcon from 'Images/tags/xbox_tag.svg';
import SwitchTagIcon from 'Images/tags/switch_tag.svg';
import PlaystationTagIcon from 'Images/tags/playstation_tag.svg';

export default {
    name: 'NewsDetail',
    components: {
        Loading,
        Sidebar,
        ImageOptimized,
        AppYoutube,
        AppTweet,
        AppPicture,
        VRuntimeTemplate,
    },
    data() {
        return {
            pages,
            article: null,
            shownEntries: 6,
            loaded: {
                staticBg: false,
            },
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    computed: {
        xboxTagIcon() {
            return XboxTagIcon;
        },
        switchTagIcon() {
            return SwitchTagIcon;
        },
        shownTags() {
            let shownTags = [];
            let tags = [];

            if (this.article.tags) {
                tags = JSON.parse(this.article.tags);
            }

            if (tags.length > 0 && this.article.tags) {
                tags.forEach(element => {
                    if (element === 'Xbox') {
                        shownTags.push(XboxTagIcon);
                    } else if (element === 'Switch') {
                        shownTags.push(SwitchTagIcon);
                    } else {
                        shownTags.push(PlaystationTagIcon);
                    }
                });
                return shownTags;
            } else {
                return '';
            }
        },
        dataLoaded() {
            return this.loaded.staticBg && this.article;
        },
        slug() {
            if (this.$route.fullPath == '/firstLook') return 'first-look';
            //this is here just so that the deprecated routing method works for an already aired old url
            return this.$route.params.slug;
        },
        ...mapGetters('articles', {
            articles: 'getArticles',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
    created() {
        window.addEventListener('resize', this.windowResize);
    },
    mounted() {
        setTimeout(() => {
            if (!this.article) this.findArticle();
            this.handleBg();
        }, 500);
    },
    updated() {
        if (this.articles && !this.article) this.findArticle();

        if (!this.article) return;

        if (this.article.name.length <= 20) {
            document.title = this.article.name + ' | News | ' + baseTitle;
        } else {
            document.title =
                this.article.name.substring(0, 16) +
                '... | News | ' +
                baseTitle;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        dateInArticleFormat(date) {
            return format(parseISO(date), formats.ARTICLE_FORMAT);
        },
        findArticle() {
            let slug = this.slug;
            this.article = this.articles.rows.find(function(article) {
                return article.slug === slug;
            });
            if (!this.article) {
                router.push({ name: pages.NOT_FOUND_PAGE_NAME });
            }
        },
        processText(text) {
            return text.replace(/<a /g, '<a class="a-text" ');
        },
        handleBg() {
            if (this.settings && this.settings.apple) {
                document.getElementById('content').removeAttribute('style');
                document.getElementById('content').setAttribute(
                    'style',
                    `
                        background-image: url('/static/images/placeholder/news/bg-news.jpg');
                        background-position: ` +
                        (this.window.w >= 1920
                            ? (this.window.w - 1920) / 2
                            : 0) +
                        `px 0px;
                        background-size: 1910px auto;
                    `
                );
            }
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
            this.handleBg();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

#news-detail {
    #news-detail-content {
        .section-content {
            padding: 125px 35px 150px;
            display: flex;
            justify-content: center;

            .scrollable-content {
                max-width: 889px;
                width: 100%;
                margin-right: 35px;

                .event-title {
                    font-size: 60px;
                    font-weight: 700;
                    text-transform: uppercase;
                    @media (max-width: 768px) {
                        font-size: 60px;
                    }
                    @media (max-width: 480px) {
                        font-size: 42px;
                    }
                }

                .event-date {
                    color: $red;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                    margin: 15px 0 5px 0;
                }
                .event-tags {
                    display: flex;
                    align-items: center;
                    margin: 10px 0 35px 0;

                    .entry-tag {
                        max-width: 26px;
                        width: 100%;
                        height: auto;
                        padding-right: 20px;
                        fill: #ffffff;
                    }
                }

                .event-article-content {
                    background-color: #252525;
                    padding: 50px;
                }

                @media (max-width: 1200px) {
                    width: 90vw;
                    margin-right: 0;
                }
                @media (max-width: 480px) {
                    width: 100vw;
                }
            }

            .back {
                display: block;
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
            }

            @media (max-width: 1200px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.global-style /deep/ a {
    &.a-text {
        color: $light;
        font-weight: 600;
        display: inline-block;
        border-bottom: 1px solid $light;
        margin: 0;

        &:hover {
            color: $red-lighter;
        }

        &.no-underline {
            border: none;
        }

        &.dark {
            color: $dark;
            border-bottom: 1px solid $dark;

            &:hover {
                color: $red-lighter;
            }
        }
    }

    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
        span,
        span:hover {
            border: inherit;
        }
    }
}

.global-style /deep/ .basic-page,
.global-style /deep/ .basic-content {
    width: 100vw;
    max-width: 1920px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: auto;
    cursor: default;
    word-break: break-word;
    display: block;
    position: relative;
    min-height: 100vh;

    .title-image,
    .basic-image {
        max-width: 889px;
        width: 100%;
        height: auto;
    }

    .basic-image-description {
        text-align: center;
        font-style: normal;
        color: $red;
        font-size: 18px;
        font-weight: 400;
    }

    .postcard {
        width: 450px;
        max-width: 100%;
    }

    h2 {
        color: $light;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 20px 0;
        padding-top: 20px;
    }

    p {
        color: $light;
        font-size: 18px;
        font-weight: 400;
    }
    .strong-title {
        display: block;
        font-weight: 600;
    }

    .basic-page-section {
        margin-bottom: 40px;
    }
}
</style>
