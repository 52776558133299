<template>
    <div ref="list" class="news-list" :class="{ webp: settings.webpSupport }">
        <h2 class="capitals">{{ headerText }}</h2>

        <div v-if="entries" class="list">
            <template v-for="(entry, index) in entries">
                <CardV2
                    v-if="index < shownEntries"
                    :key="entry.name"
                    class="list-item"
                    :title="entry.name"
                    :date="dateInArticleFormat(entry.published_at)"
                    :link-name="linkName"
                    :link-params="{ slug: entry.slug }"
                    :sidebar="true"
                    :img="entry.titleImage"
                    :tags="showTags ? entry.tags : null"
                />
            </template>
        </div>

        <div v-if="shownEntries < entries.length" class="more-button">
            <LinkButton
                text="LOAD MORE"
                type="red"
                :event="true"
                @eventMethod="moreEntries"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formats from '@/formats';
import { format, parseISO } from 'date-fns';

import * as pages from '@/routes/pageCodes';
import LinkButton from '@/components/partials/LinkButton';

import CardV2 from '@/components/partials/CardV2';

export default {
    name: 'Sidebar',
    components: { CardV2, LinkButton },
    props: {
        headerText: { type: String, default: 'Recent' },
        entries: { type: Array, default: () => [] },
        linkName: {
            type: String,
            default: null,
        },
        showTags: { type: Boolean, default: true },
    },
    data() {
        return { shownEntries: 8, pages };
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
    methods: {
        dateInArticleFormat(date) {
            return format(parseISO(date), formats.ARTICLE_FORMAT);
        },
        moreEntries() {
            this.shownEntries += 3;
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

.news-list {
    position: relative;
    max-width: 439px;
    width: 100%;
    height: auto;
    top: 15vh;

    h2 {
        margin: 40px 0 10px 0;
    }

    .list {
        .list-item {
            margin-bottom: 15px;
            background-color: #252525;
        }
    }

    .more-button {
        text-align: center;
        margin: 25px 0;
    }
}
</style>
