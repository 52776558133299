<template>
    <div id="mc_embed_signup">
        <form
            id="mc-embedded-subscribe-form"
            action="https://vigorgame.us13.list-manage.com/subscribe/post?u=002ddcf49421d23278cc3bef4&amp;id=188dfc17e6"
            method="post"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            novalidate
        >
            <div id="mc_embed_signup_scroll">
                <div class="indicates-required">
                    <span class="asterisk">*</span> indicates required
                </div>
                <div class="mc-field-group">
                    <label for="mce-EMAIL"
                        >Email Address <span class="asterisk">*</span>
                    </label>
                    <input
                        id="mce-EMAIL"
                        type="email"
                        value=""
                        name="EMAIL"
                        class="required email"
                    />
                </div>
                <div class="mc-field-group input-group">
                    <h2 class="capitals">Platforms</h2>
                    <ul>
                        <li>
                            <input
                                id="mce-group[17317]-17317-0"
                                type="checkbox"
                                value="1"
                                name="group[17317][1]"
                            /><label for="mce-group[17317]-17317-0"
                                >Nintendo Switch</label
                            >
                        </li>
                        <li>
                            <input
                                id="mce-group[17317]-17317-1"
                                type="checkbox"
                                value="2"
                                name="group[17317][2]"
                            /><label for="mce-group[17317]-17317-1">Xbox</label>
                        </li>
                    </ul>
                </div>
                <div
                    id="mergeRow-gdpr"
                    class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
                >
                    <div class="content__gdpr">
                        <h2 class="capitals">Marketing Permissions</h2>
                        <p class="text">
                            Please select all the ways you would like to hear
                            from Bohemia Interactive a.s. and Vigor Team:
                        </p>
                        <fieldset
                            class="mc_fieldset gdprRequired mc-field-group"
                            name="interestgroup_field"
                        >
                            <label class="checkbox subfield" for="gdpr_28773"
                                ><input
                                    id="gdpr_28773"
                                    type="checkbox"
                                    name="gdpr[28773]"
                                    value="Y"
                                    class="av-checkbox gdpr"
                                /><span class="checkbox-label"
                                    >Update News, Dev News, Community Spotlight,
                                    Sales, Events etc.</span
                                >
                            </label>
                        </fieldset>
                        <p class="text">
                            You can unsubscribe at any time by clicking the link
                            in the footer of our emails. For information about
                            our privacy practices, please visit our website.
                        </p>
                    </div>
                    <div class="content__gdprLegal">
                        <p class="text">
                            We use Mailchimp as our marketing platform. By
                            clicking below to subscribe, you acknowledge that
                            your information will be transferred to Mailchimp
                            for processing. <br />
                            <br />
                            <a
                                class="a-text"
                                href="https://mailchimp.com/legal/"
                                target="_blank"
                                >Learn more about Mailchimp's privacy practices
                                here.</a
                            >
                        </p>
                    </div>
                </div>
                <div id="mce-responses" class="clear">
                    <div
                        id="mce-error-response"
                        class="response"
                        style="display:none"
                    ></div>
                    <div
                        id="mce-success-response"
                        class="response"
                        style="display:none"
                    ></div>
                </div>
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div
                    style="position: absolute; left: -5000px;"
                    aria-hidden="true"
                >
                    <input
                        type="text"
                        name="b_002ddcf49421d23278cc3bef4_188dfc17e6"
                        tabindex="-1"
                        value=""
                    />
                </div>

                <div class="clear form-button">
                    <LinkButton
                        text="Subscribe"
                        type="red minWidth"
                        :form="true"
                        value="Subscribe"
                        name="subscribe"
                    />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import LinkButton from '@/components/partials/LinkButton';

export default {
    components: { LinkButton },
    mounted() {
        let validateMailchimp = document.createElement('script');
        validateMailchimp.setAttribute(
            'src',
            'https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'
        );
        validateMailchimp.async = true;
        document.head.appendChild(validateMailchimp);
    },
};
</script>
<style scoped>
#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
    display: inline-block;
    margin: 2px 0 1em 0;
    padding: 5px 10px;
    background-color: #ed1c24;
    opacity: 0.85;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: normal;
    z-index: 1;
    color: #e85c41;
}
#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {
    border: 2px solid #e85c41;
}
#mc_embed_signup div#mce-responses {
    float: left;
    top: -1.4em;
    padding: 0em 0.5em 0em 0.5em;
    overflow: hidden;
    width: 90%;
    margin: 0 5%;
    clear: both;
}

#mc_embed_signup #mce-error-response {
    color: #ed1c24;
    display: none;
}
#mc_embed_signup #mce-success-response {
    color: #529214;
    display: none;
}
</style>

<style scoped lang="scss">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';
#mc_embed_signup {
    @media (max-width: 650px) {
        margin: 0 20px;
    }
}
form {
    max-width: 650px;
    clear: left;
    margin: 0 auto;
    text-align: left;

    .response {
        margin: 1em auto;
        padding: 1em 0.5em 0.5em 0;
        font-weight: bold;
        top: -1.5em;
        z-index: 1;
        width: 80%;
        text-align: center;
    }
    .indicates-required {
        text-align: right;
        font-size: 12px;
    }
    .asterisk {
        color: #e85c41 !important;
        font-size: 150%;
        font-weight: normal;
        position: relative;
        top: 5px;
    }
    .clear {
        clear: both;
    }

    .mc_fieldset {
        border: none;
        min-height: 0px;
        padding-bottom: 0px;
    }
    ul {
        list-style: none;
        padding: 0;
    }
    input {
        background-color: transparent;
        color: $light !important;
        font-size: 20px;
        font-family: inherit;
        max-height: 150px;
        display: block;
        border: 1px solid rgba(255, 255, 255, 0.5);
        resize: none;
        opacity: 1;
        text-align: left;
        line-height: 50px;
    }

    .gdprRequired {
        padding: 10px 0;
        @media (max-width: $screen-xs) {
            margin: 20px 0;
        }
    }
    input[type='checkbox'] {
        display: inline;
        width: auto;
        margin-right: 10px;
    }

    input[type='email'] {
        width: 98%;
    }
    .checkbox-label {
        display: inline-block;
    }
    .form-button {
        display: flex;
        justify-content: center;
        margin: 30px 0;
    }

    //AUTOFIL COLORS
    @-webkit-keyframes autofill {
        0%,
        100% {
            color: $light;
            background: transparent;
        }
    }

    input:-webkit-autofill {
        -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
        -webkit-animation-name: autofill;
        -webkit-animation-fill-mode: both;
    }
}
</style>
