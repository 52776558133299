<template>
    <div
        id="popup-layer"
        class="basic-content"
        :class="{ show: show, hide: !show }"
        @click="closeMethod"
        @mousemove="block"
    >
        <div
            id="popup-window"
            :class="{ webp: settings.webpSupport }"
            @click="stopPropagation"
        >
            <div class="popup-content">
                <form @submit.prevent="handleSubmit">
                    <div class="popup-title">Code</div>
                    <div class="popup-text">
                        Yes, please send me the code!<br />
                        <input
                            ref="checkbox"
                            v-model="terms"
                            class="checkbox"
                            type="checkbox"
                        />
                        I have read and agree to
                        <a
                            class="a-text"
                            href="https://vigorgame.com/eula"
                            target="_blank"
                            rel="noopener noreferrer"
                            >the terms of use</a
                        >.
                    </div>
                    <div class="popup-button" :class="{ showAlert: !terms }">
                        <LinkButton
                            v-if="terms"
                            text="Claim"
                            type="red minWidth"
                            :form="true"
                        />
                        <LinkButton
                            v-else
                            text="Claim"
                            type="red minWidth"
                            :form="true"
                            :disabled="true"
                        />
                        <div class="alert">
                            You need to agree to the terms of use.
                        </div>
                    </div>
                    <div class="popup-close" @click="closeMethod"></div>
                    <div class="captcha">
                        <vue-recaptcha
                            v-if="recaptchaSettings"
                            ref="recaptcha"
                            size="invisible"
                            badge="inline"
                            :sitekey="recaptchaSettings.key"
                            @verify="onVerify"
                            @expired="onExpired"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import LinkButton from '@/components/partials/LinkButton';
import { mapGetters } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import { getCaptcha } from '@/services/captcha';
import { CodeResource } from '@/services/code';
import { CodeOtherResource } from '@/services/codeOther';

export default {
    name: 'Popup',
    components: { LinkButton, VueRecaptcha },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
        },
        promotion: {
            type: Object,
        },
        other: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            pages,
            terms: false,
            captchaResponse: null,
            recaptchaSettings: null,
        };
    },
    methods: {
        closeMethod() {
            this.$emit('closeMethod');
        },
        submitMethod() {
            this.$emit('submitMethod');
        },
        block(event) {
            event.preventDefault();
            event.stopPropagation();
        },
        stopPropagation(event) {
            event.stopPropagation();
        },
        onVerify(value) {
            this.captchaResponse = value;
            this.$emit('codeLoadingMethod');
            this.sendForm();
        },
        onExpired() {
            this.$refs.recaptcha.reset();
            this.captchaResponse = null;
        },
        onExecute() {
            if (!this.$refs.checkbox.checked) {
                return;
            }
            this.$refs.recaptcha.execute();
        },
        handleSubmit() {
            this.onExecute();
        },
        async sendForm() {
            if (!this.other) {
                await CodeResource.request({
                    user: this.user,
                    promotion: { id: this.promotion.id },
                    'g-recaptcha-response': this.captchaResponse,
                })
                    .then(response => {
                        this.$emit('submitMethod', response.body);
                    })
                    .catch(err => {
                        console.log(err);
                        //this.EventBus.$emit(this.events.CAPTCHA_RESET);
                    });
            } else {
                await CodeOtherResource.request({
                    user: this.user,
                    promotionOther: { id: this.promotion.id },
                    'g-recaptcha-response': this.captchaResponse,
                })
                    .then(response => {
                        this.$emit('submitMethod', response.body);
                    })
                    .catch(err => {
                        console.log(err);
                        //this.EventBus.$emit(this.events.CAPTCHA_RESET);
                    });
            }
        },
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
    created() {
        getCaptcha().then(response => {
            this.recaptchaSettings = response;
        });
    },
};
</script>

<style scoped lang="scss" media="print">
@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';
@import '../../../styles/popups.scss';

#popup-layer {
    #popup-window {
        height: 380px;
        width: 600px;
        max-width: 100vw;
        top: calc(50% - 190px);
        left: calc(50% - 300px);
        background-image: url('/static/images/popup-higher.png');

        &.webp {
            background-image: url('/static/images/popup-higher.webp');
        }

        .captcha {
            margin-left: calc(50% - 128px);
            margin-top: 40px;
        }

        @media (max-width: 600px), (max-height: 400px) {
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            .alert {
                height: 25px;
            }
        }
    }
}
</style>
