import { render, staticRenderFns } from "./PopupNewsletter.vue?vue&type=template&id=2af51f1c&scoped=true"
import script from "./PopupNewsletter.vue?vue&type=script&lang=js"
export * from "./PopupNewsletter.vue?vue&type=script&lang=js"
import style0 from "./PopupNewsletter.vue?vue&type=style&index=0&id=2af51f1c&prod&scoped=true&lang=scss&media=print"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2af51f1c",
  null
  
)

export default component.exports