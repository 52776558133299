'use strict';
import { ChangelogResource } from '@/services/changelog';
import Vue from 'vue';
import * as types from '../mutation-types';

const state = {
    data: null,
};

const getters = {
    getChangelogs: state => {
        return state.data;
    },
};

const actions = {
    loadChangelogs({ commit }) {
        return ChangelogResource.get()
            .then(response => {
                commit(types.RECEIVE_CHANGELOGS, response.data);
            })
            .catch(err => {
                commit(types.RECEIVE_CHANGELOGS, {});
                console.log(err);
            });
    },
};

const mutations = {
    [types.RECEIVE_CHANGELOGS](state, val) {
        Vue.set(state, 'data', val);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
