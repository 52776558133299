var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"basic-page",attrs:{"id":"eula-purchases"}},[_c('div',{staticClass:"static-bg",class:{ hidden: _vm.settings && _vm.settings.apple }},[_c('ImageOptimized',{attrs:{"code":"bg-eula","thumb-formats":"webp,jpg","thumb-sizes":"768,992,1200","type":"static-bg"}})],1),_c('div',{staticClass:"section",attrs:{"id":"eula-purchases-content"}},[_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"upper-title"},[_vm._v("END-USER LICENSE AGREEMENT")]),_c('div',{staticClass:"page-title"},[_vm._v("Specifications for PlayStation®4")]),_c('div',{staticClass:"text"},[_c('h1',[_vm._v("English")]),_vm._m(0),_c('h1',[_vm._v("Brazillian Portuguese")]),_vm._m(1),_c('h1',[_vm._v("French")]),_vm._m(2),_c('h1',[_vm._v("Spanish")]),_vm._m(3),_c('h1',[_vm._v("Italian")]),_vm._m(4),_c('h1',[_vm._v("German")]),_vm._m(5),_c('h1',[_vm._v("Russian")]),_vm._m(6),_c('h1',[_vm._v("Dutch")]),_vm._m(7),_c('h1',[_vm._v("Portuguese")]),_vm._m(8),_c('h1',[_vm._v("Turkish")]),_vm._m(9),_c('h1',[_vm._v("Polish")]),_vm._m(10),_c('h1',[_vm._v("Finnish")]),_vm._m(11),_c('h1',[_vm._v("Danish")]),_vm._m(12),_c('h1',[_vm._v("Norwegian")]),_vm._m(13),_c('h1',[_vm._v("Swedish")]),_vm._m(14),_c('h1',[_vm._v("Arabic")]),_vm._m(15),_vm._v(" Go "),_c('router-link',{staticClass:"a-text",attrs:{"exact":"","to":{ name: _vm.pages.EULA_PAGE_NAME }}},[_vm._v("back to EULA & Terms")]),_vm._v(". ")],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Purchase and use of items are subject to the Network Terms of Service andUser Agreement. This online service has been sublicensed to you by Sony Interactive Entertainment America. ")]),_c('li',[_vm._v(" Any content purchased in an in-game store will be purchased from Sony Interactive Entertainment Network Europe Limited (“SIENE”) and be subject to PlayStation™Network Terms of Service and User Agreement which is available on the PlayStation™Store. Please check usage rights for each purchase as these may differ from item to item. Unless otherwise shown, content available in any in-game store has the same age rating as the game. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" A compra e o uso de itens estão sujeitos aos Termos de serviço e Contrato do usuário da Network. Este serviço online foi sublicenciado a você pela Sony Interactive Entertainment America. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" L'achat et l'utilisation des articles sont soumis aux Conditions d'utilisation et à l'Accord utilisateur de Network. Une sous-licence d'utilisation de ce service en ligne vous a été concédé par Sony Interactive Entertainment America. ")]),_c('li',[_vm._v(" Tout contenu acheté dans la boutique d'un jeu est acheté à Sony Interactive Entertainment Network Europe Limited (\"SIENE\") et est soumis aux Conditions d'utilisation et Accord utilisateur de PlayStation™Network disponibles sur PlayStation™Store. Vérifiez les droits d’utilisation lors de chaque achat car ceux-ci peuvent varier d’un article à l’autre. Sauf indication contraire, la classification du contenu disponible dans la boutique d'un jeu est identique à celle du jeu lui-même. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" La compra y uso de artículos están sujetos a los Términos de Servicio y Acuerdo de Usuario de la Network. Una sublicencia de este servicio online le ha sido otorgada por Sony Interactive Entertainment America. ")]),_c('li',[_vm._v(" Cualquier contenido adquirido en una tienda del juego se le comprará a Sony Interactive Entertainment Network Europe Limited (“SIENE”) y estará sujeto a los Términos de servicio / Acuerdo de usuario de PlayStation™Network que está disponible en PlayStation™Store. Le rogamos que compruebe los derechos de uso en cada compra, ya que pueden variar según el producto. A menos que se indique lo contrario, el contenido disponible en una tienda del juego tiene la misma clasificación por edades que el juego. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Tutti i contenuti acquistati in un negozio in-game saranno acquistati da Sony Interactive Entertainment Network Europe Limited (\"SIENE\") e saranno soggetti ai Termini di servizio e alle Condizioni d'uso di PlayStation™Network disponibili su PlayStation™Store. Consulta i diritti d'uso per ogni acquisto effettuato, poiché possono differire in base al contenuto. Se non diversamente specificato, i contenuti disponibili nei negozi in-game sono rivolti ad utenti con la stessa età consigliata per il gioco. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Alle Inhalte, die in In-Game-Shops käuflich erworben werden, werden von Sony Interactive Entertainment Network Europe Limited („SIENE“) erworben und unterliegen den Nutzungsbedingungen und der Endbenutzervereinbarung von PlayStation™Network, die über PlayStation™Store eingesehen werden können. Wir empfehlen, die Nutzungsrechte für jedes erworbene Produkt zu überprüfen, da sie sich vonFall zu Fall unterscheiden können. Sofern nicht anders angegeben, gilt für sämtliche Inhalte, die in In-Game-Shops erhältlich sind, dieselbe Altersfreigabe wie für das entsprechende Spiel. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Любой товар, приобретенный во внутриигровом магазине, будет являться покупкой у компании Sony Interactive Entertainment Network Europe Limited (“SIENE”) и подчиняться Условиям предоставления услуг PlayStation™Network, с которыми можно ознакомиться в PlayStation™Store. Пожалуйста, ознакомьтесь с правами использования - они могут быть различными для разных продуктов. Если не указано иное, товар в любом внутриигровом магазине имеет ту же возрастную категорию, что и игра. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Elke content die in een winkel in het spel is gekocht, wordt gekocht van Sony Interactive Entertainment Network Europe Limited (“SIENE”) en is onderworpen aan de servicevoorwaarden van PlayStation™Network die bij PlayStation™Store verkrijgbaar zijn. U dient de gebruiksrechten te controleren omdat deze per item kunnen verschillen. Tenzij anderszins vermeld, gelden dezelfde leeftijdsbeperkingen voor content die in een winkel in het spel beschikbaar is als voor het spel zelf. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Qualquer conteúdo comprado numa loja de jogo é comprado à Sony Interactive Entertainment Network Europe Limited (“SIENE”) e está sujeito aos Termos de Serviço e Acordo de Utilizador da PlayStation™Network, disponíveis na PlayStation™Store. Verifique os direitos de utilização relativos a cada compra, uma vez que estes podem diferir de um artigo para outro. Salvo indicação em contrário, o conteúdo disponível em qualquer loja de jogo possui a mesma classificação etária que o próprio jogo. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Oyun içi mağaza içinden satın alınan tüm içerik Sony Interactive Entertainment Network Europe Limited (\"SIENE\") şirketinden satın alınacakolup, PlayStation™Store'da bulunan PlayStation™Network Hizmet Şartları'na tabidir. Öğeden öğeye farklılık gösterebileceği için lütfen her satın almaya ilişkin kullanım haklarını kontrol edin. Aksi belirtilmediği müddetçe, oyun içi mağazadan satın alınan içerik, oyunla aynı yaş sınıflandırmasına sahiptir. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Zawartość zakupiona za pośrednictwem sklepu udostępnionego w grze oznacza, że została ona zakupiona od firmy Sony Interactive EntertainmentNetwork Europe Limited („SIENE”) i podlega Warunkom świadczenia usług obowiązującym w sieci PlayStation™Network, z którymi można zapoznać się w sklepie PlayStation™Store. Prawa użytkowania należy sprawdzać przy każdym zakupie, ponieważ mogą one być różne w zależności od pozycji. Jeśli nie zaznaczono inaczej, zawartość dostępna w dowolnym sklepie udostępnionym w grze jest objęta tą samą klasyfikacją co gra. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Kaikki pelin sisäisestä kaupasta ostettu sisältö ostetaan Sony Interactive Entertainment Network Europe Limited (”SIENE”), ja se on PlayStation™Network –palvelun käyttöehtojen alaista. Nämä käyttöehdot saa PlayStation™Store-kaupasta. Tarkista kunkin ostoksen käyttöoikeudet, sillä ne voivat vaihdella kohteesta toiseen. Ellei toisin ilmoiteta, pelin sisäisessä kaupassa myytävien sisältöjen ikäluokitus on sama kuin pelin. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Alt indhold, der købes fra en butik i et spil, købes gennem Sony Interactive Entertainment Network Europe Limited (“SIENE”) og er underlagt PlayStation™Network Betingelser for tjenesteydelse, som findes på PlayStation™Store. Kontroller rettighederne til brug for hvert enkelt køb, da de kan være forskellige fra vare til vare. Medmindre andet er vist, har indhold, som er tilgængeligt i en butik i et spil, den samme aldersklassifikation som spillet. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Alt innhold som blir kjøpt i en butikk i spillet, kjøpes fra Sony Interactive Entertainment Network Europe Limited (“SIENE”) og er underlagt tjenestevilkårene til PlayStation™Network som kan leses på PlayStation™Store. Kontroller bruksrettighetene for hvert kjøp, da de kan variere for ulike gjenstander. Innholdet som er tilgjengelig i butikken har samme aldersgrense som spillet, med mindre noe annet er angitt. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Innehåll som köps inuti spel köps från Sony Interactive Entertainment Network Europe Limited (\"SIENE\") och är föremål för tjänstevillkoren och användaravtalet för PlayStation™Network som finns tillgängligt i PlayStation™Store. Var vänlig kontrollera användningsrättigheter för varje köp, eftersom dessa kan variera från föremål till föremål. Innehåll som tillhandahålls inuti spel har samma åldersgräns som spelet, om inte annat anges. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticStyle:{"direction":"rtl"}},[_c('li',[_vm._v(" سيتم شراء أي محتوى تم شراؤه من متجر داخل اللعبة منSony Interactive Entertainment Network Europe Limited (\"SIENE\") وسيخضع لشروط الخدمة واتفاقية المستخدم الخاصة بـPlayStation™Network والمتوفرة علىPlayStation™Store. رجى التحقق من حقوق الاستخدام لكل عملية شراء لأنها قد تختلف ُيمن عنصر لآخر. يكون المحتوى المتوفر في أي متجر داخل اللعبة بنفس تقييم السن الخاص باللعبة، إلا إذا تم عرض غير ذلك. ")])])
}]

export { render, staticRenderFns }